import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-crear-categoria',
  templateUrl: './crear-categoria.component.html',
  styleUrls: ['./crear-categoria.component.scss']
})
export class CrearCategoriaComponent implements OnInit {

  formCategoria: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private matDialogRef: MatDialogRef<CrearCategoriaComponent>,
  ) { }

  ngOnInit(): void {
  
    this.formCategoria = this.fb.group({
      nombre: ['', Validators.required],
      fechaCreacion: Timestamp.now()
    });

  }

  onSubmit() {
    if (this.formCategoria.valid) {
      this.loading = true;
      this.crearCategoria()
    } else {
      this.validateAllFormFields(this.formCategoria)
    }
  }

  crearCategoria() {
    this.afs.collection('categoriasEmpresas').add(this.formCategoria.value).then( () => {
      console.log('Categoría creada');
      this.cancelar();
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  cancelar() {
    this.matDialogRef.close();
  }

  

}
