<div class="container">


  <app-header-section [seccion]="seccion"></app-header-section>


  <div class="col-md-6">
    <form [formGroup]="formCamaraRegional" (submit)="onSubmit()" novalidate class="row">

      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Nombre</mat-label>
        <input matInput class="text" formControlName="nombre">
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Presidente</mat-label>
        <input matInput class="text" formControlName="presidente">
      </mat-form-field>

      <!-- <mat-form-field appearance="fill" class="col-12">
        <mat-label>Cargo</mat-label>
        <input matInput class="text" formControlName="cargo">
      </mat-form-field> -->

      <p class="fs-5">Teléfonos</p>
      <div formArrayName="telefonos">
        <mat-form-field appearance="fill" class="col-12" *ngFor="let telefono of formCamaraRegional.controls['telefonos']['controls']; let i = index">
          <mat-label>Teléfono {{i+1}}</mat-label>
          <input matInput class="text" [formControlName]="i">
          <button mat-icon-button matSuffix (click)="eliminarTelefono(i)" *ngIf="i != 0">
            <i-tabler class="d-flex text-primary" name="x"></i-tabler>
          </button>
        </mat-form-field>
        <div class="text-end">
          <button class="btn btn-link p-0" type="button" (click)="agregarTelefono()">Agregar</button>
        </div>
      </div>

      <p class="fs-5">Correos electrónicos</p>
      <div formArrayName="emails">
        <mat-form-field appearance="fill" class="col-12" *ngFor="let email of formCamaraRegional.controls['emails']['controls']; let i = index">
          <mat-label>Email {{i+1}}</mat-label>
          <input matInput class="text" [formControlName]="i">
          <button mat-icon-button matSuffix (click)="eliminarEmail(i)" *ngIf="i != 0">
            <i-tabler class="d-flex text-primary" name="x"></i-tabler>
          </button>
        </mat-form-field>
        <div class="text-end">
          <button class="btn btn-link p-0" type="button" (click)="agregarEmail()">Agregar</button>
        </div>
      </div>

      <div class="mt-4 mt-md-5">
        <button class="btn btn-primary" style="width: 122px;">
          <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
            <mat-spinner class="" [diameter]="24"></mat-spinner>
          </span>
          <span *ngIf="!loading">AGREGAR</span>
        </button>
      </div>
    </form>
  </div>

  <!-- <pre>{{formCamaraRegional.value | json}}</pre> -->

</div>