<div class="container">


  <app-header-section [seccion]="seccion"></app-header-section>


  <div class="col-md-6">
    <form [formGroup]="formEmpresa" (submit)="onSubmit()" novalidate class="row">

      <!-- <mat-form-field appearance="outline" class="col-12">
        <mat-label>Categoría</mat-label>
        <mat-select formControlName="categoria">
          <mat-option *ngFor="let categoria of categoriasAsociados" [value]="categoria">{{categoria}}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <div class="d-flex gap-2">

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Categoría</mat-label>
          <mat-select formControlName="categoria">
            <mat-option *ngFor="let categoria of categoriasEmpresas" [value]="categoria.nombre">{{categoria.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div style="padding-top: 4px;">
          <button type="button" class="btn btn-outline-primary" style="height: 59px;" (click)="openModalAgregarCategoria()">
            <i-tabler name="plus"></i-tabler>
          </button>
        </div>

      </div>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Nombre</mat-label>
        <input matInput class="text" formControlName="nombre">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Dirección</mat-label>
        <input matInput class="text" formControlName="direccion">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Web</mat-label>
        <input matInput class="text" formControlName="web">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Teléfono</mat-label>
        <input matInput class="text" formControlName="telefono">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Imagen logo</mat-label>
        <ngx-mat-file-input #removableInput formControlName="imageLogo" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
        <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
          <mat-icon>clear</mat-icon>
        </button>
        <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
        <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
        <mat-hint>Recomendado: 160 x 160</mat-hint>
        <mat-hint align="end">Peso máximo: 5 MB</mat-hint>
      </mat-form-field>

      <div class="buttonSubmit mt-4 mt-md-5">
        <button class="btn btn-primary w-100">
          <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
            <mat-spinner class="" [diameter]="24"></mat-spinner>
          </span>
          <span *ngIf="!loading">Crear empresa</span>
        </button>
      </div>
    </form>
  </div>

</div>













