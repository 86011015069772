import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { IdValidatorService } from 'src/app/services/id-validator.service';

@Component({
  selector: 'app-crear-noticia-hel',
  templateUrl: './crear-noticia-hel.component.html',
  styleUrls: ['./crear-noticia-hel.component.scss']
})
export class CrearNoticiaHelComponent implements OnInit {

  formNoticia!: UntypedFormGroup;
  loading = false;
  noticiaId: string;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  today = new Date();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  
  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private idValidator: IdValidatorService
  ) {
      // this.noticia = this.afs.collection('noticiasHel').doc(this.noticiaId);
      // console.log(this.noticia.id);
  }

  ngOnInit(): void {

    this.formNoticia = this.fb.group({
      titulo: ['', Validators.required],
      id: ['', [Validators.required], [this.idValidator]],
      subtitulo: ['', Validators.required],
      fecha: [this.today, Validators.required],
      body: ['', Validators.required],
      image: ['', [FileValidator.maxContentSize(this.maxSize)]],
      imageName: [''],
      publicado: [false],
      fechaCreacion: [Timestamp.now()]
    });

    this.formNoticia.get('titulo').valueChanges.subscribe( res => {
      const tituloSinSaltoLinea = res.replace(/\n/g, ' ');
      const noticiaHelPre = res.replace(/ /g, '-').replace(/\n/g, '-');
      this.noticiaId = noticiaHelPre.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
      this.formNoticia.get('id').setValue(this.noticiaId);
      this.formNoticia.get('titulo').patchValue(tituloSinSaltoLinea, {emitEvent: false});
    });

    this.formNoticia.get('subtitulo').valueChanges.subscribe( res => {
      const subtituloSinSaltosLinea = res.replace(/\n/g, '-');
      this.formNoticia.get('subtitulo').patchValue(subtituloSinSaltosLinea, {emitEvent: false});
    });

  }

  onSubmit() {
    if (this.formNoticia.valid) {
      this.loading = true;
      if (this.formNoticia.get('image')?.value === '') {
        this.crearNoticia();
      } else {
        this.uploadImageAndCreateNoticia();
      }
    } else {
      this.validateAllFormFields(this.formNoticia);
    }
  }

  crearNoticia() {
    this.afs.doc(`noticiasHel/${this.noticiaId}`).set(this.formNoticia.value)
    .then(() => {
      this.router.navigate(['/admin/helvetas/noticias']);
      console.log('Noticia Helvetas creada');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  

  uploadImageAndCreateNoticia() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `imagesNoticiasHel/${this.noticiaId}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`noticiasHel/${this.noticiaId}`).set({
          ...this.formNoticia.value,
          image: url,
          imageName: `${this.noticiaId}.${nombreImage[1]}`
        });
        this.router.navigate(['/admin/helvetas/noticias']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  errorImagen() {
    return this.formNoticia.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

  errorBody() {
    return this.formNoticia.controls.body.touched && this.formNoticia.controls.body.hasError('required') ? 'Incluye una descripción de la noticia' : '';
  }

}
