import { Component, OnInit } from '@angular/core';
import { VideoHel } from 'src/app/classes/video-hel';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-bloque-videos-hel',
  templateUrl: './bloque-videos-hel.component.html',
  styleUrls: ['./bloque-videos-hel.component.scss']
})
export class BloqueVideosHelComponent implements OnInit {

  videosHel: VideoHel[] = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getVideosHel().subscribe( res => {
      this.videosHel = res;
    });
  }

}
