import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.scss']
})
export class SuscripcionComponent implements OnInit {

  formSuscripcion: UntypedFormGroup;
  @ViewChild('f') form;

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.formSuscripcion = this.fb.group({
      email: ['', Validators.required],
      fechaCreacion: [ Timestamp.now() ],
    });
  }

  onSubmit() {
    if (this.formSuscripcion.valid) {
      this.guardarSuscriptor();
    } else {
      this.validateAllFormFields(this.formSuscripcion);
    }
  }

  guardarSuscriptor() {
    this.fs.addSuscripcion(this.formSuscripcion.value)
    .then(() => {
      console.log(this.formSuscripcion.value);
      this.form.resetForm({
        fechaCreacion: Timestamp.now(),
      });
      this.snackBar.open('Gracias por suscribirte', 'CERRAR', {
        duration: 8000,
      });
    }, error => console.error('error al enviar el formulario:', error));
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorMensaje() {
    return this.formSuscripcion.controls.mensaje.hasError('required') ? 'Escriba un mensaje' : '';
  }
  errorNombre() {
    return this.formSuscripcion.controls.nombre.hasError('required') ? 'Ingrese su nombre' : '';
  }
  errorEmail() {
    return this.formSuscripcion.controls.email.hasError('required') ? 'Ingrese su email' :
    this.formSuscripcion.controls.email.hasError('email') ? 'No es un email válido' : '';
  }

}
