import { Component, OnInit } from '@angular/core';
import { VideoHel } from 'src/app/classes/video-hel';
import { FirebaseService } from 'src/app/services/firebase.service';

import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-bloque-carousel',
  templateUrl: './bloque-carousel.component.html',
  styleUrls: ['./bloque-carousel.component.scss']
})
export class BloqueCarouselComponent implements OnInit {

  videosHelDestacados: VideoHel[] = [];

  config: SwiperOptions = {
    spaceBetween: 20,
    pagination: { clickable: true },
  };

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getVideosHelDestacados().subscribe( res => {
      this.videosHelDestacados = res;
    });
  }
}
