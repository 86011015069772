import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataFiles } from 'src/app/classes/data-files';

@Component({
  selector: 'app-crear-alianza',
  templateUrl: './crear-alianza.component.html',
  styleUrls: ['./crear-alianza.component.scss']
})
export class CrearAlianzaComponent implements OnInit {

  formAlianza: FormGroup;
  disabled = true;
  loading = false;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Alianzas',
        url: '/admin/alianzas'
      },
    ],
    back: '/admin/alianzas',
    titulo: 'Agregar Alianza',
    urlButton: ''
  };

  itemId: any;
  data: DataFiles;

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    this.itemId = this.activatedRoute.snapshot.params.id;
    
    this.data = {
      id: this.itemId,
      route: `alianzas/${this.itemId}`,
      maxNumFiles: 1,
      maxFileSize: 1048576 * 50,
      typeFiles: [
        'image/*',
        '.pdf'
      ],
      condiciones: [
        'Peso máximo por archivo: 50 MB',
      ]
    }
  }

  ngOnInit(): void {

    this.formAlianza = this.fb.group({
      nombre: ['', Validators.required],
      descripcion: [''],
      suscripcion: [''],
      publicado: false,
      lastModified: new Date(),
      // files: this.fb.array([])
    })

  }

  onSubmit() {
    if (this.formAlianza.valid) {
      this.disabled = false;
      this.loading = true;
      this.crearAlianza();
    }
  }

  crearAlianza() {
    this.afs.doc(`alianzas/${this.itemId}`).set(this.formAlianza.value, {merge: true})
    .then( (res) => {
      this.router.navigate(['/admin/alianzas']);
    });
  }

}
