import { Component, OnInit } from '@angular/core';
import { Empresa } from 'src/app/classes/empresa';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-empresas-asociadas',
  templateUrl: './empresas-asociadas.component.html',
  styleUrls: ['./empresas-asociadas.component.scss']
})
export class EmpresasAsociadasComponent implements OnInit {

  empresasAsociadasAgenciasViaje;
  empresasAsociadasCasinos;
  empresasAsociadasHoteles;
  empresasAsociadasInstitutosUniversidades;
  empresasAsociadasProveedores;
  empresasAsociadasRestaurantes;
  empresasAsociadasTransporte;
  empresasAsociadasTrenes;


  empresas: Empresa[] = [];
  agenciasViaje: Empresa[] = [];
  casinos: Empresa[] = [];
  hoteles: Empresa[] = [];
  institutosUniversidades: Empresa[] = [];
  proveedores: Empresa[] = [];
  restaurantes: Empresa[] = [];
  transporte: Empresa[] = [];
  trenes: Empresa[] = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {

    this.fs.getEmpresas().subscribe( (data: Empresa[]) => {
      this.empresas = data;
      console.log(this.empresas);
      this.agenciasViaje = this.empresas.filter( fil => fil.categoria === 'Agencia de viajes');
      console.log(this.agenciasViaje);
    });



    this.fs.getEmpresasAsociadasAgenciasViaje().subscribe( res => {
      this.empresasAsociadasAgenciasViaje = res;
    });
    this.fs.getEmpresasAsociadasCasinos().subscribe( res => {
      this.empresasAsociadasCasinos = res;
    });
    this.fs.getEmpresasAsociadasHoteles().subscribe( res => {
      this.empresasAsociadasHoteles = res;
    });
    this.fs.getEmpresasAsociadasInstitutosUniversidades().subscribe( res => {
      this.empresasAsociadasInstitutosUniversidades = res;
    });
    this.fs.getEmpresasAsociadasProveedores().subscribe( res => {
      this.empresasAsociadasProveedores = res;
    });
    this.fs.getEmpresasAsociadasRestaurantes().subscribe( res => {
      this.empresasAsociadasRestaurantes = res;
    });
    this.fs.getEmpresasAsociadasTransporte().subscribe( res => {
      this.empresasAsociadasTransporte = res;
    });
    this.fs.getEmpresasAsociadasTrenes().subscribe( res => {
      this.empresasAsociadasTrenes = res;
    });
  }

}
