<div class="container pt-2 pt-md-3">

    <nav class="small mb-3 mb-md-4">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
            <li class="breadcrumb-item active text-muted">Suscripciones Noticias</li>
        </ol>
    </nav>

    

    <div class="d-flex justify-content-between align-content-center mb-2 mb-md-3">
        <p class="h4 mb-0">Solicitud de suscripciones a noticias</p>
        <!-- <div>
            <button class="btn btn-primary" (click)="openModalCrear()">
                <mat-icon>add</mat-icon> Crear</button>
        </div> -->
    </div>

    <!-- <div class="">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ingresa una palabra">
        </mat-form-field>
    </div> -->
    



    <div class="table-responsive">

        <table mat-table [dataSource]="suscripcionesData" matSort class="example-table" [trackBy]="trackByPublicado">

            <ng-container matColumnDef="fechaCreacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
                <td mat-cell *matCellDef="let item"> {{ item.fechaCreacion.toDate() | date: 'short' }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let item"> {{item.email}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
    </mat-paginator>





</div>