<div class="p-1 p-md-3">
  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p class="h4 mt-2 mt-md-0">Eliminar banner</p>
  <div class="my-3 my-md-4">
    <p class="mb-1">Esta acción no se puede deshacer.</p>
    <p class="extraSmall text-muted font-monospace lh-sm">Código: {{data.id}}</p>
  </div>
  <div class="d-grid">
    <button type="submit" class="btn btn-outline-danger py-2 px-3" (click)="eliminarNoticiaHel()">
      <span class="myColorSpinnerWhite d-flex justify-content-center" *ngIf="loading">
        <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
      </span>
      <div *ngIf="!loading">
        <span>Eliminar banner</span>
      </div>
    </button>
  </div>
</div>