import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Noticia } from 'src/app/classes/noticia';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-eliminar-noticia',
  templateUrl: './eliminar-noticia.component.html',
  styleUrls: ['./eliminar-noticia.component.scss']
})
export class EliminarNoticiaComponent implements OnInit {

  deshabilitado: boolean;

  constructor(
    private fs: FirebaseService,
    private dialogRef: MatDialogRef<EliminarNoticiaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Noticia,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
  }

  
  // eliminarNoticia(idNoticia, imageName) {
  //   this.deshabilitado = true;

  //   this.fs.deleteNoticia(idNoticia)
  //   .then(() => {
  //     this.dialogRef.close();
  //     this.snackBar.open('Producto eliminado', 'CERRAR', {
  //       duration: 3000,
  //     });
  //     console.log('Noticia eliminada');

  //   });

  //   this.fs.deleteNoticiaStorage(idNoticia, imageName);
  // }

  eliminarNoticia() {

    // Eliminar imagen de storage
    if (this.data.image === '' ) {
      console.log('no hay imagen'); 
    } else {
      this.storage.ref(`imagesNoticias/${this.data.imageName}`).delete();
      console.log('Imagen noticia eliminada de Storage');
    }

    // Eliminar noticia de Firestore
    this.afs.collection('noticias').doc(this.data.id).delete()
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Noticia eliminada', 'CERRAR', {
        duration: 3000,
      });
      console.log('Noticia eliminada de Firestore');
    });

  }



  cancelar() {
    this.dialogRef.close();
  }

}
