import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Noticia } from 'src/app/classes/noticia';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-eliminar-asociado',
  templateUrl: './eliminar-asociado.component.html',
  styleUrls: ['./eliminar-asociado.component.scss']
})
export class EliminarAsociadoComponent implements OnInit {

  deshabilitado: boolean;

  constructor(
    private fs: FirebaseService,
    private dialogRef: MatDialogRef<EliminarAsociadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Noticia,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  eliminarNoticia(idNoticia, imageName) {
    this.deshabilitado = true;

    this.fs.deleteNoticia(idNoticia)
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Producto eliminado', 'CERRAR', {
        duration: 3000,
      });
    });

    this.fs.deleteNoticiaStorage(idNoticia, imageName);
  }

  cancelar() {
    this.dialogRef.close();
  }

}
