<div class="container mb-3 mb-md-6">

  <nav class="small mt-2 mt-md-3 mb-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/noticias">Noticias</a></li>
      <li class="breadcrumb-item active text-muted">Crear noticia</li>
    </ol>
  </nav>

  
  <div class="col-md-6">
    <p class="h2">Crear Noticia</p>

    <form [formGroup]="formNoticia" (submit)="onSubmit()" novalidate>

      <div class="row gx-2 gx-md-4">
        <mat-form-field appearance="outline" class="col-6">
          <mat-label>Categoría</mat-label>
          <mat-select formControlName="categoria">
            <mat-option *ngFor="let categoria of categorias" [value]="categoria">{{categoria}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-6">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Título</mat-label>
        <input matInput formControlName="titulo">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Subtítulo</mat-label>
        <textarea matInput rows="3" formControlName="subtitulo"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Link noticia</mat-label>
        <input matInput formControlName="url">
      </mat-form-field>

      <div class="col-12 bg-light rounded-3 p-3 p-md-4">
        <p class="h4 mb-0">Imagen</p>
        <div class="my-3">
          <mat-radio-group formControlName="tipoImagen">
            <mat-radio-button class="me-3" *ngFor="let tipo of tiposImagen" [value]="tipo">{{tipo}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="link">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Link imagen</mat-label>
            <input matInput formControlName="urlImage">
          </mat-form-field>
        </div>

        <div *ngIf="subirImagen">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Imagen</mat-label>
            <ngx-mat-file-input #removableInput formControlName="image" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
            <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
            <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
            <mat-hint>Peso máximo: 5 MB</mat-hint>
          </mat-form-field>
        </div>



      </div>


      <div class="buttonSubmit mt-4">
        <div class="d-grid">
          <button class="btn btn-primary" style="height: 60px;">
            <span class="d-flex justify-content-center" *ngIf="deshabilitado">
              <mat-spinner [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!deshabilitado">Crear noticia</span>
          </button>
        </div>

      </div>

    </form>

  </div>

  <!-- <div class="col-md-6">
    <pre>{{formNoticia.value | json}}</pre>
  </div> -->

</div>