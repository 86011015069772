<nav class="container small mt-3">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
		<li class="breadcrumb-item active text-muted">Marketplace</li>
	</ol>
</nav>

<div class="container py-3 py-md-5">
	<p class="h1 text-center mb-3 mb-md-4">Canatur Marketplace</p>


	<div class="ratio ratio-16x9">
		<iframe class="rounded" width="100%" height="auto" src="https://www.youtube.com/embed/iXKM11O5d1k" allowfullscreen></iframe>
	</div>
</div>

<div class="container">
	<div class="text-center col-md-9 mx-auto py-2 py-md-4">

		<span class=""><img src="assets/images/comillasFirst.svg"></span><br>
		<img class="mt-3 mt-md-4" src="assets/images/logoMarketplace.svg" alt="Logo Marketplace" width="280px">
		<p class="lead my-3 my-md-4">CANATUR (Cámara Nacional de Turismo del Perú) ha implementado la plataforma tecnológica
			denominada <strong>CANATUR MARKETPLACE</strong> la cual ayudará en la inclusión y democratización de las empresas
			turísticas, haciendo énfasis en las mypes y pymes turísticas.
		</p>
		<span class=""><img src="assets/images/comillasEnd.svg"></span>
	</div>
</div>

<div class="bgMarketplace py-4 py-md-6">
	<div class="container">
		<p class="my-3 my-md-5 text-white h2">CANATUR MARKETPLACE es una herramienta tecnológica que permite a diferentes
			empresas y prestadores de servicios turísticos afiliarse a su plataforma para presentar y comercializar sus
			diferentes ofertas.</p>
	</div>

</div>

<!-- <div class="container pt-4 pt-md-6">
    <p class="h3">Únete a CANATUR MARKETPLACE</p>
    <form #f="ngForm" [formGroup]="formMarketplace" (ngSubmit)="onSubmit()" novalidate>
        <div class="row">
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Nombres</mat-label>
                <input matInput formControlName="nombres">
                <mat-error *ngIf="errorNombres()">{{errorNombres()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Apellidos</mat-label>
                <input matInput formControlName="apellidos">
                <mat-error *ngIf="errorApellidos()">{{errorApellidos()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>RUC</mat-label>
                <input matInput formControlName="ruc">
                <mat-error *ngIf="errorRuc()">{{errorRuc()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Razón Social</mat-label>
                <input matInput formControlName="razonSocial">
                <mat-error *ngIf="errorRazonSocial()">{{errorRazonSocial()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Teléfono</mat-label>
                <input matInput formControlName="telefono">
                <mat-error *ngIf="errorTelefono()">{{errorTelefono()}}</mat-error>
            </mat-form-field>
            <div class="col-12 mt-3 text-right">
                <button type="submit" class="btn btn-primary">Enviar solicitud</button>
            </div>
        </div>
    </form>
</div> -->

<div class="container pt-4 pt-md-6">
	<p class="h3">Únete a CANATUR MARKETPLACE</p>
	<a class="btn btn-primary btn-lg" href="http://bit.ly/FGMKTPYTQP" target="blank">Afíliate ahora</a>
</div>

<div class="container py-4 py-md-6">
	<p class="lead font-weight-bold">Mayor información</p>
	<a class="btn btn-link px-0" href="mailto:marketplace@canaturperu.org" target="blank">marketplace@canaturperu.org</a>
</div>