import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DataFiles } from 'src/app/classes/data-files';
import { Empresa } from 'src/app/classes/empresa';
import { DataService } from 'src/app/services/data.service';
import { CrearCategoriaComponent } from '../crear-categoria/crear-categoria.component';

@Component({
  selector: 'app-crear-empresa',
  templateUrl: './crear-empresa.component.html',
  styleUrls: ['./crear-empresa.component.scss']
})
export class CrearEmpresaComponent implements OnInit {

  formEmpresa: FormGroup;
  loading = false;
  loadingLogo = true;
  idEmpresa: string;
  empresa: Empresa;
  pathEmpresa: any;
  empresaAsociada: any;

  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  categoriasAsociados = [];

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
      {
        nombre: 'Empresas Asociadas',
        url: '/admin/asociados/empresasAsociadas'
      },
    ],
    back: '/admin/asociados/empresasAsociadas',
    titulo: 'Crear Empresa',
    urlButton: ''
  };

  itemId: any;
  data: DataFiles;
  categoriasEmpresas = [];


  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ds: DataService,
    private matDialog: MatDialog
  ) {

    this.empresaAsociada = this.afs.collection('empresas').ref.doc();

    this.itemId = this.activatedRoute.snapshot.params.id;
    
    this.data = {
      id: this.itemId,
      route: `empresas/${this.itemId}`,
      maxNumFiles: 1,
      maxFileSize: 1048576 * 5,
      title: 'Imágenes',
      typeFiles: [
        '.jpg',
        '.jpeg',
        '.png'
      ],
      condiciones: [
        'Tamaño recomendado: 800 x 450',
        'Peso máximo por imagen 5 MB',
        `Máximo 1 imagen`,
      ]
    }
  }

  ngOnInit(): void {

    this.afs.collection('categoriasEmpresas').valueChanges().subscribe( res => {
      this.categoriasEmpresas = res;
    });

    this.formEmpresa = this.fb.group({

      // nombre: ['', Validators.required],
      // subtitulo: ['', Validators.required],
      // descripcion: ['', Validators.required],
      // nameButton: [''],
      // urlButton: [''],
      // publicado: false,
      // lastModified: new Date(),


      categoria: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: [''],
      web: [''],
      telefono: [''],
      imageLogo: ['', [FileValidator.maxContentSize(this.maxSize)]],
      imageLogoName: [''],
      publicado: false,
      fechaCreacion: [Timestamp.now()]

    });

  }

  onSubmit() {
    if (this.formEmpresa.valid) {
      this.loading = true;
      if (this.formEmpresa.get('imageLogo').value === '') {
        this.crearEmpresa();
      } else {
        this.uploadLogoAndCreateEmpresa();
      }
    } else {
      this.validateAllFormFields(this.formEmpresa)
    }
  }

  crearEmpresa() {
    this.afs.doc(`${this.data.route}`).set(this.formEmpresa.value, {merge: true})
    .then( (res) => {
      this.router.navigate([`${this.seccion.back}`]);
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadLogoAndCreateEmpresa() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `logosEmpresas/${this.idEmpresa}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe( (url) => {

          // const objectEmpresa = this.formEmpresa.value;
          // const imageLogo = url;
          // const imageLogoName = `${this.idEmpresa}.${nombreImage[1]}`;
          // const newEmpresa = { ...objectEmpresa, imageLogo, imageLogoName };
          // this.pathEmpresa.update(newEmpresa,);

          // this.router.navigate(['/admin/asociados/empresas'])
          // console.log(url);


          const objectEmpresa = this.formEmpresa.value;
          delete objectEmpresa.imageLogo;
          this.empresaAsociada.set(objectEmpresa);
          this.empresaAsociada.set({
            imageLogo: url,
            imageLogoName: `${this.empresaAsociada.id}.${nombreImage[1]}`,
          }, {merge: true});
          this.router.navigate(['/admin/asociados/empresasAsociadas']);
          console.log(url);






        })
      })
    )
    .subscribe();
  }


  deletePreviewImage() {
    if (this.empresa.imageLogoName === '' ) {
      console.log('no hay logo'); 
    } else {
      this.storage.ref(`logosEmpresas/${this.empresa.imageLogoName}`).delete();
    }
  }

  detectarCargado() {
    this.loadingLogo = false;
  }

  openModalAgregarCategoria() {
    this.matDialog.open(CrearCategoriaComponent, {
      autoFocus: false
    })
  }

  errorImagen() {
    return this.formEmpresa.controls.imageLogo.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

}
