import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentoHel } from 'src/app/classes/documento-hel';
import { VideoHel } from 'src/app/classes/video-hel';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarVideoComponent } from '../eliminar-video/eliminar-video.component';

@Component({
  selector: 'app-lista-videos',
  templateUrl: './lista-videos.component.html',
  styleUrls: ['./lista-videos.component.scss']
})
export class ListaVideosComponent implements OnInit {

  loading!: boolean;
  displayedColumns = [ 'miniatura', 'link', 'destacado', 'publicado', 'editar', 'mas' ];
  videosHelData = new MatTableDataSource();
  value: any;
  iconItem: any;
  iconUrl: string;
  urlEmbedVideo: string;

  opciones = [
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (videoHel: VideoHel) => this.eliminarItem(videoHel)
    }
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.videosHelData.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
    private ds: DataService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.fs.getAllVideosHel().subscribe((data: VideoHel[]) => {
      this.videosHelData.data = data;
      this.loading = false;
    });
    this.videosHelData.paginator = this.paginator;
    this.videosHelData.sort = this.sort;
  }

  clearFilters() {
    this.videosHelData.filter = '';
    this.value = '';
  }

  ngAfterViewInit() {
    this.videosHelData.paginator = this.paginator;
  }

  readTypeFile(typeFile) {
    this.iconItem = this.ds.iconsTypeFiles.find( find => typeFile === find.nombre);
    this.iconUrl = this.iconItem.icon;
    return this.iconUrl
  }

  actualizarPublicado(idVideoHel: string, change: MatSlideToggleChange) {
    this.afs.collection('videosHel').doc(idVideoHel).update({
      publicado: change.checked
    });
  }

  actualizarDestacado(idVideoHel: string, event) {
    this.afs.collection('videosHel').doc(idVideoHel).update({destacado: event});
  }

  // updateDestacado(idNoticia, destacado) {
  //   this.afs.doc('noticias/' + idNoticia).update({ destacado });
  // }

  trackByPublicado(item: any) {
    return item.publicado;
  }

  editarItem(videoHel: VideoHel) {
    this.router.navigate([`admin/helvetas/videos/editarVideo/${videoHel.id}`]);
  }

  eliminarItem(videoHel: VideoHel) {
    this.matDialog.open(EliminarVideoComponent, {
      panelClass: 'dialogSmall',
      data: videoHel
    });
  }

}
