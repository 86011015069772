<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/asociados">Asociados</a></li>
      <li class="breadcrumb-item active text-muted">Empresas</li>
    </ol>
  </nav>



    <p class="h1 mb-3 text-center">Empresas</p>

    <!-- <mat-accordion [multi]="true">
      <mat-expansion-panel *ngFor="let group of itemsGroup; let i = index" expanded="i">
        <mat-expansion-panel-header>
          <p class="h4 fw-light mb-0">{{group.categoria}}</p>
        </mat-expansion-panel-header>
        <div class="grillaEmpresas ps-3">
          <app-card-empresa *ngFor="let item of group.items" [empresa]="item" [item]="item"></app-card-empresa>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->

    <!-- <p class="h3 text-primary">Agencias de viajes</p>
    <div class="row g-2 g-md-5 mb-5 mb-md-6">
      <div class="col-md-6" *ngFor="let empresa of agenciasViajes">
        <app-card-empresa [empresa]="empresa"></app-card-empresa>
      </div>
    </div>

    <p class="h3 text-primary">Hoteles</p>
    <div class="row g-2 g-md-5 mb-5 mb-md-6">
      <div class="col-md-6" *ngFor="let empresa of hoteles">
        <app-card-empresa [empresa]="empresa"></app-card-empresa>
      </div>
    </div>

    <p class="h3 text-primary">Institutos y Universidades</p>
    <div class="row g-2 g-md-5 mb-5 mb-md-6">
      <div class="col-md-6" *ngFor="let empresa of institutosUniversidades">
        <app-card-empresa [empresa]="empresa"></app-card-empresa>
      </div>
    </div>

    <p class="h3 text-primary">Proveedores</p>
    <div class="row g-2 g-md-5 mb-5 mb-md-6">
      <div class="col-md-6" *ngFor="let empresa of proveedores">
        <app-card-empresa [empresa]="empresa"></app-card-empresa>
      </div>
    </div>

    <p class="h3 text-primary">Restaurantes</p>
    <div class="row g-2 g-md-5 mb-5 mb-md-6">
      <div class="col-md-6" *ngFor="let empresa of restaurantes">
        <app-card-empresa [empresa]="empresa"></app-card-empresa>
      </div>
    </div>

    <p class="h3 text-primary">Transportes</p>
    <div class="row g-2 g-md-5 mb-5 mb-md-6">
      <div class="col-md-6" *ngFor="let empresa of transportes">
        <app-card-empresa [empresa]="empresa"></app-card-empresa>
      </div>
    </div>

    <p class="h3 text-primary">Trenes</p>
    <div class="row g-2 g-md-5 mb-5 mb-md-6">
      <div class="col-md-6" *ngFor="let empresa of trenes">
        <app-card-empresa [empresa]="empresa"></app-card-empresa>
      </div>
    </div> -->




    <div class="row gy-4 gy-md-6">

      <div class="col-12" *ngFor="let grupo of itemsGroupOrder">

        <p class="h3 text-primary mb-3">{{grupo.categoria}}</p>

        <div class="row gy-2 gy-md-3">

          <div class="col-md-6" *ngFor="let empresa of grupo.items">
            <app-card-empresa [empresa]="empresa"></app-card-empresa>
          </div>

        </div>


      </div>

    </div>



</div>