<div class="container py-3 py-md-4">



  <div class="d-flex justify-content-between align-content-center mb-2 mb-md-3">
    <p class="h3 mb-0">Noticias</p>
    <div>
      <a class="btn btn-primary" routerLink="/admin/noticias/crear">
        <mat-icon>add</mat-icon> Crear
      </a>
    </div>
  </div>

  <div class="">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ingresa una palabra">
    </mat-form-field>
  </div>




  <div class="table-responsive">

    <table mat-table [dataSource]="noticiasData" matSort class="example-table" [trackBy]="trackByPublicado">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let noticia"> {{ noticia.id }} </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
        <td mat-cell *matCellDef="let noticia"> {{ noticia.fecha.toDate() | date: 'mediumDate' }} </td>
      </ng-container>

      <ng-container matColumnDef="autorNombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Autor Nombre</th>
        <td mat-cell *matCellDef="let noticia"> {{ noticia.autorNombre }} </td>
      </ng-container>

      <ng-container matColumnDef="departamento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicación</th>
        <td mat-cell *matCellDef="let noticia"> {{ noticia.departamento }} </td>
      </ng-container>

      <ng-container matColumnDef="categoria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
        <td mat-cell *matCellDef="let noticia"> {{ noticia.categoria}} </td>
      </ng-container>

      <ng-container matColumnDef="imagen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</th>
        <td mat-cell *matCellDef="let noticia"> <img class="imageProductAdmin border my-1 rounded" [src]="noticia.image || noticia.urlImage"></td>
      </ng-container>

      <ng-container matColumnDef="titulo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
        <td mat-cell *matCellDef="let noticia"> {{ noticia.titulo }} </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
        <td mat-cell *matCellDef="let noticia"> {{noticia.descripcion | slice: 0:64}}...</td>
      </ng-container>

      <ng-container matColumnDef="publicado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Publicado</th>
        <td mat-cell *matCellDef="let noticia">
          <mat-slide-toggle color="primary" #toggle [ngModel]="noticia.publicado" (ngModelChange)="actualizarPublicado(noticia.id, noticia, $event)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="destacado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Destacado</th>
        <td mat-cell *matCellDef="let noticia">
          <!-- <mat-slide-toggle color="primary" #toggle [ngModel]="noticia.publicado" (ngModelChange)="actualizarPublicado(noticia.id, noticia, $event)">
                  </mat-slide-toggle> -->
          <mat-checkbox #toggle2 [ngModel]="noticia.destacado" (ngModelChange)="actualizarDestacado(noticia.id, noticia, $event)"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="detalles">
        <th mat-header-cell *matHeaderCellDef>Detalles</th>
        <td mat-cell *matCellDef="let noticia">
          <button mat-icon-button (click)="openModalDetalle(noticia)">
            <mat-icon class="material-icons-outlined text-muted">remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="editar">
        <th mat-header-cell *matHeaderCellDef>Editar</th>
        <td mat-cell *matCellDef="let noticia">
          <button mat-icon-button (click)="openModalEdit(noticia)">
            <mat-icon class="material-icons-outlined text-muted">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="eliminar">
        <th mat-header-cell *matHeaderCellDef>Eliminar</th>
        <td mat-cell *matCellDef="let noticia">
          <button mat-icon-button (click)="openModalDelete(noticia)">
            <mat-icon class="material-icons-outlined text-muted">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="mas">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" class="text-end">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">

            <button mat-menu-item [routerLink]=" '/admin/noticias/' + item.id + '/editar' ">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
              <span>Editar</span>
            </button>

            <button mat-menu-item [routerLink]=" '/admin/noticias/' + item.id ">
              <mat-icon class="material-icons-outlined">sticky_note_2</mat-icon>
              <span>Detalles</span>
            </button>

            <mat-divider></mat-divider>

            <button mat-menu-item (click)="openModalDelete(item)">
              <mat-icon class="material-icons-outlined text-danger">delete</mat-icon>
              <span class="text-danger">Eliminar</span>
            </button>

          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  
  <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
  </mat-paginator>





</div>