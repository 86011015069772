<div class="container">

  <app-header-section [seccion]="seccion"></app-header-section>


  <div class="row justify-content-between">

    <div class="col-md-6">

      <form [formGroup]="formAlianza" (submit)="onSubmit()" novalidate class="row">

        <p class="lead">Información</p>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Nombre</mat-label>
          <input matInput class="text" formControlName="nombre">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Descripción</mat-label>
          <textarea matInput  rows="10" formControlName="descripcion"></textarea>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Suscripción</mat-label>
          <input matInput class="text" formControlName="suscripcion">
        </mat-form-field>
  
        <div class="mt-4 mt-md-5">
          <button class="btn btn-primary" style="width: 122px;" type="submit">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">AGREGAR</span>
          </button>
        </div>
      </form>
    </div>
  

    <div class="col-md-4">
      <app-upload-file [data]="data"></app-upload-file>
    </div>

  </div>


  <!-- <pre>{{formCamaraRegional.value | json}}</pre> -->

</div>