<div class="container">
			<div class="row gy-4">
				<div class="col-md-4">
					<p class="h2">Noticias</p>
					<p>Sigue todas las noticias de este proyecto</p>
					<a class="btn btn-outline-primary" routerLink="/helvetas/noticias">
            Ver todas las noticias
            <i-tabler name="arrow-right" class="ms-2"></i-tabler>
          </a>
				</div>
				<div class="col-md-8">
					<div class="row gy-3 gy-md-4">
						<div class="col-12" *ngFor="let noticia of noticiasHel">

							<div class="d-flex h-100 bg-light position-relative">
								<div class="contentNoticiaHel p-3 p-md-4">
									<p class="titulo">{{noticia.titulo}}</p>
									<p class="subtitulo d-none d-md-block">{{noticia.subtitulo}}</p>
									<div class="d-flex align-items-center">
										<span class="me-1">
											<i-tabler class="text-primary" name="calendar-event"
												style="width: 20px; height: auto; stroke-width: 1.5;"></i-tabler>
										</span>
										<span class="extraSmall text-primary" style="padding-top: 3px;">{{noticia.fecha.toDate() |
											date:'mediumDate'}}</span>
									</div>
								</div>
								<div class="ms-2 ms-md-3">
									<img class="imageNoticiaHelCard" [src]="noticia.image" alt="">
								</div>
								<a class="stretched-link" [routerLink]=" '/helvetas/' + noticia.id "></a>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>