<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/boletines/">Boletines</a></li>
      <!-- <li class="breadcrumb-item"><a routerLink="/admin/helvetas/documentos">Documentos</a></li> -->
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/admin/boletines">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Añadir Boletín</p>
  </div>


  <div class="row justify-content-between">


    <div class="col-md-6">
      <form [formGroup]="formBoletin" (submit)="onSubmit()" novalidate class="row">

        
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Título</mat-label>
          <input matInput type="text" formControlName="titulo">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Archivo</mat-label>
          <ngx-mat-file-input #removableInput formControlName="archivo" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png, .pdf, .xls" (change)="detectFiles($event)"></ngx-mat-file-input>
          <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
            <mat-icon>clear</mat-icon>
          </button>
          <!-- <mat-icon matSuffix class="material-symbols-outlined">description</mat-icon> -->
          <!-- <i-tabler matSuffix name="upload" *ngIf="removableInput.empty"></i-tabler> -->
          <mat-error *ngIf="errorArchivo()">{{errorArchivo()}}</mat-error>
          <mat-hint>PDF, XLS, JPG, PNG, JPEG</mat-hint>
          <mat-hint align="end">Peso máximo: 50 MB</mat-hint>
        </mat-form-field>


        <div class="buttonSubmit mt-4 mt-md-5">
          <button class="btn btn-primary w-100">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">Añadir boletín</span>
          </button>
        </div>
      </form>
    </div>



    <!-- <div class="col-md-5">
      <pre>{{formNoticia.value | json}}</pre>
    </div> -->

    

  </div>

</div>