import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// import { FileValidator } from 'ngx-material-file-input/lib/validator/file-validator';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-crear-noticia',
  templateUrl: './crear-noticia.component.html',
  styleUrls: ['./crear-noticia.component.scss']
})
export class CrearNoticiaComponent implements OnInit {

  formNoticia: FormGroup;
  noticia: any;
  noticiaId: string;
  categorias;
  visible = false;
  deshabilitado = false;
  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number> | unknown;
  downloadURL: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;

  tiposImagen = [
    'Link',
    'Subir imagen'
  ];

  link = true;
  subirImagen: boolean;

  constructor(
    private fs: FirebaseService,
    private ds: DataService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CrearNoticiaComponent>,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
  ) {
    this.noticia = this.afs.collection('noticias').ref.doc();
    console.log(this.noticia.id);
  }

  ngOnInit(): void {
    this.formNoticia = this.fb.group({
      categoria: ['', Validators.required],
      fecha: ['', Validators.required],
      titulo: ['', Validators.required],
      subtitulo: ['', Validators.required],
      url: ['', Validators.required],
      tipoImagen: [ 'Link'],
      urlImage: ['', Validators.required],
      image: [''],
      publicado: [false],
      destacado: [false],
      fechaCreacion: [Timestamp.now()],
    });

    // this.formNoticia.get('titulo').valueChanges.subscribe( res => {
    //   const negocioIdSpace = res.replace(/ /g, '-');
    //   this.noticiaId = negocioIdSpace.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
    //   this.formNoticia.get('id').setValue(this.noticiaId);
    // });

    this.categorias = this.ds.categorias;

    this.formNoticia.get('tipoImagen').valueChanges.subscribe( res => {
      console.log(res);
      if (res === 'Link') {
        this.link = true;
        this.subirImagen = false;
        this.formNoticia.get('urlImage').setValidators(Validators.required);
        this.formNoticia.get('image').clearValidators();
        this.formNoticia.get('image').setValue('');
      }
      if (res === 'Subir imagen') {
        this.subirImagen = true;
        this.link = false;
        this.formNoticia.get('image').setValidators([Validators.required, FileValidator.maxContentSize(this.maxSize)]);
        this.formNoticia.get('urlImage').clearValidators();
        this.formNoticia.get('urlImage').setValue('');
      }
    });
  }

  onSubmit() {
    // if (this.formNoticia.valid) {
    //   this.uploadFileAndcrearNoticia();
    //   this.deshabilitado = true;
    // } else {
    //   this.validateAllFormFields(this.formNoticia);
    //   this.deshabilitado = false;
    // }

    if (this.formNoticia.get('tipoImagen').value === 'Link' && this.formNoticia.valid) {
      this.deshabilitado = true;
      this.crearNoticia();
    }
    if (this.formNoticia.get('tipoImagen').value === 'Subir imagen' && this.formNoticia.valid) {
      this.deshabilitado = true;
      this.uploadFileAndcrearNoticia();

    } else {
      this.validateAllFormFields(this.formNoticia);
      this.deshabilitado = false;
    }

  }

  crearNoticia() {
    this.deshabilitado = true;
    this.fs.addNoticia(this.formNoticia.value)
      .then(() => {
        // this.dialogRef.close();
        this.router.navigate(['/admin/noticias']);
      });
  }

  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  uploadFileAndcrearNoticia() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    // const filePath = `imagesProducts/${myTest.id}/name1`;
    const filePath = `imagesNoticias/${this.noticia.id}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {
          this.noticia.set({
            ...this.formNoticia.value,
            image: url,
            imageName: `${this.noticia.id}.${nombreImage[1]}`
          });
          this.router.navigate(['/admin/noticias']);
          console.log(url);
        });
      })
    )
    .subscribe();
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  agregarPresentacion() {
    // tslint:disable-next-line:no-string-literal
    (this.formNoticia.controls['presentaciones'] as UntypedFormArray).push(
      this.fb.group({
        presentacion: [''],
        medida: [''],
        precio: ['']
      })
    );
  }

  eliminarPresentacion(index: number): void {
    // tslint:disable-next-line:no-string-literal
    // (this.formNoticia.controls['presentaciones']as FormArray).removeAt(index);
    const control = this.formNoticia.controls.presentaciones as UntypedFormArray;
    control.removeAt(index);
  }

  cancelar() {
    this.dialogRef.close();
  }

  errorImagen() {
    return this.formNoticia.controls.image.hasError('required') ? 'La imagen es necesaria' :
    this.formNoticia.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

}
