import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  links;
  redesSociales;
  gremiosDark;

  constructor(
    private ds: DataService,
  ) { }

  ngOnInit(): void {
    this.links = this.ds.links;
    this.redesSociales = this.ds.redesSociales;
    this.gremiosDark = this.ds.gremiosDark;
  }

}
