<div class="container">

	<nav class="small mt-2 mt-md-3 mb-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/asociados">Asociados</a></li>
			<li class="breadcrumb-item"><a routerLink="/admin/asociados/gremios">Gremios</a></li>
      <!-- <li class="breadcrumb-item active text-muted">Crear noticia</li> -->
    </ol>
  </nav>


	<div class="col-md-6">
		<div class="d-flex align-items-center">
			<button mat-icon-button>
				<i-tabler name="arrow-left"></i-tabler>
			</button>
			<p class="h3 mb-0">Crear gremio</p>
		</div>
	
		
		<form #f="ngForm" [formGroup]="formEmpresaAsociada" (ngSubmit)="onSubmit()" novalidate>
			<div class="row">
				<mat-form-field class="col-12" appearance="outline">
					<mat-label>Categoría</mat-label>
					<mat-select formControlName="categoria">
						<mat-option *ngFor="let categoria of categorias" [value]="categoria">{{categoria}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field class="col-12" appearance="outline">
					<mat-label>Nombre</mat-label>
					<input matInput formControlName="nombre">
				</mat-form-field>
				<mat-form-field class="col-12" appearance="outline">
					<mat-label>Dirección</mat-label>
					<input matInput formControlName="direccion">
				</mat-form-field>
				<div class="col-12" formArrayName="telefonos"
					*ngFor="let telefono of formEmpresaAsociada.controls['telefonos']['controls']; let i = index;">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Teléfono {{i+1}}</mat-label>
						<input matInput [formControlName]="i">
						<!-- <mat-error *ngIf="errorTelefono">{{errorTelefono(i)}}</mat-error> -->
						<button matSuffix mat-icon-button (click)="eliminarTelefono(i)">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
				</div>

				<div class="col-12">
					<button class="mb-3 btn btn-outline-primary btn-sm px-2" type="button" (click)="agregarTelefono()">Agregar
						teléfono</button>
				</div>
				<mat-form-field class="col-12">
					<mat-label>Web</mat-label>
					<input matInput formControlName="web">
				</mat-form-field>
				<mat-form-field class="col-12">
					<mat-label>Imagen Url</mat-label>
					<input matInput formControlName="image">
				</mat-form-field>
				<div class="col-12 d-flex justify-content-end mt-3 mt-md-4">
					<button class="btn btn-primary">Crear Empresa Asociada</button>
				</div>
			</div>
		</form>
	</div>


	

</div>