import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-asociados',
  templateUrl: './asociados.component.html',
  styleUrls: ['./asociados.component.scss']
})
export class AsociadosComponent implements OnInit {

  asociados;

  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.asociados = this.ds.asociados;
  }

}
