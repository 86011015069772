import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarItemComponent } from '../../../eliminar-item/eliminar-item.component';
import { EliminarEmpresaComponent } from '../eliminar-empresa/eliminar-empresa.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-categorias-empresas',
  templateUrl: './categorias-empresas.component.html',
  styleUrls: ['./categorias-empresas.component.scss']
})
export class CategoriasEmpresasComponent implements OnInit {

  // categoria: string;
  empresas = [];
  displayedColumns = [ 'imagen', 'nombre', 'categoria', 'publicado', 'editar', 'eliminar' ];
  empresasData = new MatTableDataSource();
  itemId = this.afs.collection('empresas').ref.doc().id;

  categoria = this.activatedRoute.snapshot.params.categoria;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
      {
        nombre: 'Empresas asociadas',
        url: '/admin/asociados/empresasAsociadas'
      },
    ],
    back: '/admin/asociados/empresasAsociadas',
    titulo: this.categoria,
    urlButton: `/admin/asociados/empresasAsociadas/crearEmpresa`
  };

  constructor(
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private fs: FirebaseService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    // this.categoria = this.activatedRoute.snapshot.params.categoria;
    // console.log(this.categoria);

    this.fs.getEmpresasByCategoria(this.categoria).subscribe( res => {
      this.empresas = res;
      console.log(this.empresas);
      this.empresasData.data = res;
    });
    
  }

  actualizarPublicado(id, change: MatSlideToggleChange) {
    this.afs.collection('empresas').doc(id).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item) {
    return item.publicado;
  }

  eliminarItem(empresa) {
    this.matDialog.open(EliminarEmpresaComponent, {
      data: empresa
    });
  }

  // eliminarItem(item) {
  //   this.matDialog.open(EliminarEmpresaComponent, {
  //     data: {
  //       doc: item,
  //       route: `empresas/${item.id}`,
  //     }
  //   });
  // }

  

}
