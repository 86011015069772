<div class="container">

  <app-header-section [seccion]="seccion"></app-header-section>


  <div class="row justify-content-between">

    <div class="col-md-6">

      <!-- <app-upload-images [data]="data"></app-upload-images> -->

      <form [formGroup]="formEvento" (submit)="onSubmit()" novalidate class="row" *ngIf="evento">

        <p class="lead">Información</p>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Nombre</mat-label>
          <input matInput class="text" formControlName="nombre">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Subtítulo</mat-label>
          <textarea matInput  rows="2" formControlName="subtitulo"></textarea>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Descripción</mat-label>
          <textarea matInput  rows="5" formControlName="descripcion"></textarea>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Nombre del botón</mat-label>
          <input matInput class="text" formControlName="nameButton">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Link del botón</mat-label>
          <input matInput class="text" formControlName="urlButton">
        </mat-form-field>
  
        <div class="mt-4 mt-md-5">
          <button class="btn btn-primary" style="width: 122px;" type="submit">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">GUARDAR</span>
          </button>
        </div>
      </form>
    </div>
  

    <div class="col-md-4">
      <app-upload-file [data]="data"></app-upload-file>
    </div>


  </div>


  <!-- <pre>{{formCamaraRegional.value | json}}</pre> -->

</div>