import { Component, OnInit } from '@angular/core';
import { Boletin } from 'src/app/classes/boletin';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-boletines',
  templateUrl: './boletines.component.html',
  styleUrls: ['./boletines.component.scss']
})
export class BoletinesComponent implements OnInit {

  boletines: Boletin[] = [];
  iconItem: any;
  iconUrl: string;

  constructor(
    private fs: FirebaseService,
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.fs.getBoletines().subscribe( res => {
      this.boletines = res;
    });
  }

  readTypeFile(typeFile) {
    this.iconItem = this.ds.iconsTypeFiles.find( find => typeFile === find.nombre);
    this.iconUrl = this.iconItem.icon;
    return this.iconUrl
  }
}
