import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentData, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { merge, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Empresa } from 'src/app/classes/empresa';
import { DataService } from 'src/app/services/data.service';
import { CrearCategoriaComponent } from '../crear-categoria/crear-categoria.component';

@Component({
  selector: 'app-editar-empresa',
  templateUrl: './editar-empresa.component.html',
  styleUrls: ['./editar-empresa.component.scss']
})
export class EditarEmpresaComponent implements OnInit {

  formEmpresa: UntypedFormGroup;
  loading = false;
  loadingLogo = true;
  idEmpresa: string;
  empresa: Empresa;
  pathEmpresa: any;

  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  categoriasEmpresas = [];

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ds: DataService,
    private matDialog: MatDialog
  ) {
      
  }


  ngOnInit(): void {

    // this.categoriasAsociados = this.ds.categoriasAsociados;

    this.afs.collection('categoriasEmpresas').valueChanges().subscribe( res => {
      this.categoriasEmpresas = res;
    });

    this.idEmpresa = this.activatedRoute.snapshot.params.id;
    console.log(this.idEmpresa);

    this.pathEmpresa = this.afs.collection('empresas').doc(this.idEmpresa);
    
    this.pathEmpresa.valueChanges().subscribe( (res: Empresa) => {
      this.empresa = res;

      this.formEmpresa = this.fb.group({
        categoria: [this.empresa.categoria, Validators.required],
        nombre: [this.empresa.nombre, Validators.required],
        direccion: [this.empresa.direccion],
        web: [this.empresa.web],
        telefono: [this.empresa.telefono],
        imageLogo: ['', [FileValidator.maxContentSize(this.maxSize)]],
        imageLogoName: [''],
        publicado: [this.empresa.publicado],
        fechaCreacion: [Timestamp.now()]
      });

    });

    
 
  }

  onSubmit() {
    if (this.formEmpresa.valid) {
      this.loading = true;
      if (this.formEmpresa.get('imageLogo').value === '') {
        this.guardarCambios();
      } else {
        this.uploadLogoAndCreateEmpresa();
      }
    } else {
      this.validateAllFormFields(this.formEmpresa)
    }
  }

  guardarCambios() {
    const empresa = this.formEmpresa.value;
    ['imageLogo', 'imageLogoName'].forEach(e => delete empresa[e]);
    this.pathEmpresa.update(this.formEmpresa.value)
    .then(() => {
      this.router.navigate(['/admin/asociados/empresasAsociadas']);
      console.log('Empresa actualizada');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadLogoAndCreateEmpresa() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `logosEmpresas/${this.idEmpresa}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe( (url) => {

          this.deletePreviewImage();
          const objectEmpresa = this.formEmpresa.value;
          const imageLogo = url;
          const imageLogoName = `${this.idEmpresa}.${nombreImage[1]}`;
          const newEmpresa = { ...objectEmpresa, imageLogo, imageLogoName };
          this.pathEmpresa.update(newEmpresa,);

          this.router.navigate(['/admin/asociados/empresas'])
          console.log(url);
        })
      })
    )
    .subscribe();
  }

  openModalAgregarCategoria() {
    this.matDialog.open(CrearCategoriaComponent, {
      autoFocus: false
    })
  }


  deletePreviewImage() {
    if (this.empresa.imageLogoName === '' ) {
      console.log('no hay logo'); 
    } else {
      this.storage.ref(`logosEmpresas/${this.empresa.imageLogoName}`).delete();
    }
  }

  detectarCargado() {
    this.loadingLogo = false;
  }

  errorImagen() {
    return this.formEmpresa.controls.imageLogo.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

}
