import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Timestamp } from '@angular/fire/firestore';
import { VideoHel } from 'src/app/classes/video-hel';

@Component({
  selector: 'app-editar-video',
  templateUrl: './editar-video.component.html',
  styleUrls: ['./editar-video.component.scss']
})
export class EditarVideoComponent implements OnInit {

  formVideoHel!: UntypedFormGroup;
  loading = false;
  videoHelId: string;
  videoHel: VideoHel;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
      this.videoHelId = this.afs.collection('videosHel').ref.doc().id;
      console.log(this.videoHelId);  
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {
      this.videoHelId = res.id;

      this.afs.doc(`videosHel/${this.videoHelId}`).valueChanges().subscribe( (data: VideoHel) => {

        this.videoHel = data;

        this.formVideoHel = this.fb.group({
          url: [this.videoHel.url, Validators.required],
          urlEmbed: [this.videoHel.urlEmbed],
          // id: [this.videoHelId],
          // publicado: [false],
          fechaEdicion: [Timestamp.now()]
        });

        this.formVideoHel.get('url').valueChanges.subscribe( res => {
          const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
          const idVideo = regex.exec(res)[3];
          this.formVideoHel.get('urlEmbed').setValue(`https://www.youtube.com/embed/${idVideo}`)
          console.log(idVideo);
        });

      })
    }); 

   

  }

  onSubmit() {
    if (this.formVideoHel.valid) {
      this.loading = true;
      this.guardarCambios();
    } else {
      this.validateAllFormFields(this.formVideoHel);
    }
  }

  guardarCambios() {
    this.afs.doc(`videosHel/${this.videoHelId}`).update(this.formVideoHel.value)
    .then(() => {
      this.router.navigate(['/admin/helvetas/videos']);
      console.log('Video Helvetas creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
