import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-empresa-asociada',
  templateUrl: './card-empresa-asociada.component.html',
  styleUrls: ['./card-empresa-asociada.component.scss']
})
export class CardEmpresaAsociadaComponent implements OnInit {

  @Input() empresa;

  constructor() { }

  ngOnInit(): void {
  }

}
