import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarAsociadoComponent } from '../eliminar-asociado/eliminar-asociado.component';

@Component({
  selector: 'app-lista-asociados',
  templateUrl: './lista-asociados.component.html',
  styleUrls: ['./lista-asociados.component.scss']
})
export class ListaAsociadosComponent implements OnInit {


  links = [
    {
      nombre: 'Gremios',
      url: 'gremios'
    },
    {
      nombre: 'Cámaras Regionales',
      url: 'camarasRegionales'
    },
    {
      nombre: 'Empresas Asociadas',
      url: 'empresasAsociadas'
    },

  ];


  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
  }


}
