<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/helvetas">Helvetas</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/helvetas/noticias">Noticias</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/admin/helvetas/noticias">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Crear noticia</p>
  </div>


  <div class="row justify-content-between">


    <div class="col-md-6">
      <form [formGroup]="formNoticia" (submit)="onSubmit()" novalidate class="row">

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Titulo</mat-label>
          <!-- <input matInput class="text" formControlName="titulo"> -->
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="titulo"></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Subtítulo</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" formControlName="subtitulo"></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Imagen</mat-label>
          <ngx-mat-file-input #removableInput formControlName="image" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
          <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
          <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
          <mat-hint>Tamaño mínimo: 160 x 160</mat-hint>
          <mat-hint align="end">Peso máximo: 5 MB</mat-hint>
        </mat-form-field>

        
        <div class="mt-3 mt-md-4">
          <p class="mb-1">Incluye una descripción</p>
        </div>

        <editor apiKey="wyfnbqv4yhjxcxp0qj5u1stdscvy4js5n63w8u4i3de5lif4" [init]="{ icons: 'material', plugins: 'wordcount', menubar: false, min_height: 150 }" formControlName="body"></editor>
        <mat-error class="extraSmall mt-1" *ngIf="errorBody()">{{errorBody()}}</mat-error>

        <div class="buttonSubmit mt-4 mt-md-5">
          <button class="btn btn-primary w-100">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">Crear noticia</span>
          </button>
        </div>
      </form>
    </div>



    <!-- <div class="col-md-5">
      <pre>{{formNoticia.value | json}}</pre>
    </div> -->

    

  </div>

</div>