import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarItemComponent } from '../../eliminar-item/eliminar-item.component';

@Component({
  selector: 'app-lista-alianzas',
  templateUrl: './lista-alianzas.component.html',
  styleUrls: ['./lista-alianzas.component.scss']
})
export class ListaAlianzasComponent implements OnInit {

  displayedColumns = [ 'nombre', 'publicado', 'editar', 'eliminar'];
  data = new MatTableDataSource();
  itemId = this.afs.collection('alianzas').ref.doc().id;

  seccion = {
    breadCrumb: [
    ],
    back: '/admin',
    titulo: 'Alianzas',
    urlButton: `crearAlianza/${this.itemId}`
  };

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {

    this.fs.getAllAlianzas().subscribe(data => {
      this.data.data = data;
    });
  }


  actualizarPublicado(id, change: MatSlideToggleChange) {
    this.afs.collection('alianzas').doc(id).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item) {
    return item.publicado;
  }

  eliminarItem(item) {
    this.matDialog.open(EliminarItemComponent, {
      data: {
        doc: item,
        route: `alianzas/${item.id}`,
      }
    });
  }

}
