import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QueHacemos } from 'src/app/classes/que-hacemos';

@Component({
  selector: 'app-eliminar-que-hacemos',
  templateUrl: './eliminar-que-hacemos.component.html',
  styleUrls: ['./eliminar-que-hacemos.component.scss']
})
export class EliminarQueHacemosComponent implements OnInit {

  loading: boolean;

  constructor(
    private dialogRef: MatDialogRef<EliminarQueHacemosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QueHacemos,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  
  eliminarQueHacemos() {

    this.loading = true;

    // Eliminar imagen de storage
    this.storage.ref(`imagesQueHacemos/${this.data.imageName}`).delete();
    console.log('Imagen eliminada de Storage');

    // Eliminar Que Hacemos de Firestore
    this.afs.collection('queHacemos').doc(this.data.id).delete()
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Ítem eliminado', 'CERRAR', {
        duration: 3000,
      });
      console.log('Ítem eliminado de Firestore');
    });

  }

  cancelar() {
    this.dialogRef.close();
  }

}
