import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { CamaraRegional } from 'src/app/classes/camara-regional';

@Component({
  selector: 'app-camaras-regionales',
  templateUrl: './camaras-regionales.component.html',
  styleUrls: ['./camaras-regionales.component.scss']
})
export class CamarasRegionalesComponent implements OnInit {

  camarasRegionales: CamaraRegional[] = [];

  constructor(
    private ds: DataService,
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getCamarasRegionales().subscribe( res => {
      this.camarasRegionales = res;
    });

  }

}
