<div class="p-1 p-md-3">
  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p class="h4 mt-2 mt-md-0">Eliminar asociado</p>
  <div class="my-3 my-md-4">
    <p class="">¿Seguro que desea eliminar a la empresa asociada? Esta acción no se puede deshacer.</p>
  </div>
  <div class="d-grid">
    <button type="submit" class="btn btn-danger py-2 px-3" (click)="eliminarNoticia(data.id, data.imageName)">
      <span class="myColorSpinnerWhite d-flex justify-content-center" *ngIf="deshabilitado">
        <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
      </span>
      <div *ngIf="!deshabilitado">
        <span>Eliminar asociado</span>
      </div>
    </button>
  </div>
</div>