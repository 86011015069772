<div class="container py-4 py-md-6">

  <!-- <div>
    <p class="h1">Estatutos</p>

    <p class="lead">Estatuto de la Cámara Nacional de Turismo.</p>
    <a href="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/estatutos%2FestatutoCanatur2022.pdf?alt=media&token=1ac82365-c4f1-4fb8-a3fe-84e145197928"
      class="btn btn-primary py-2 px-3" target="blank">Ver Estatuto (PDF)
    </a>
  </div> -->

  <p class="h1 mb-4 mb-md-5">Estatutos</p>

  <div class="" *ngFor="let estatuto of estatutos">
    <p>{{estatuto.nombre}}</p>
    <a class="btn-sm btn btn-primary" [href]="file.urlFile" *ngFor="let file of estatuto.files" target="_blank">Ver documento</a>
  </div>

</div>