import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editar-banner-ftp',
  templateUrl: './editar-banner-ftp.component.html',
  styleUrls: ['./editar-banner-ftp.component.scss']
})
export class EditarBannerFtpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
