<div class="container pt-2 pt-md-3">

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
            <!-- <li class="breadcrumb-item"><a routerLink="/cotizar">Cotizar</a></li> -->
            <li class="breadcrumb-item active">Únete</li>
        </ol>
    </nav>

    <div class="mt-4 mt-md-5 row" *ngIf="afiliacion">
        <div class="col-md-10">
            <p class="h2 font-weight-light">Gracias {{afiliacion.nombres}},</p>
            <p>Hemos registrado tu solicitud para ser parte de CANATUR, revisaremos los datos ingresados y en breve nos pondremos en contacto.</p>
        </div>
    </div>
</div>