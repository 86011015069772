import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-eliminar-item',
  templateUrl: './eliminar-item.component.html',
  styleUrls: ['./eliminar-item.component.scss']
})
export class EliminarItemComponent implements OnInit {

  loading = false;

  constructor(
    private dialogRef: MatDialogRef<EliminarItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    // private fs: FirebaseService
  ) { }

  ngOnInit(): void {
  }

  eliminarItem() {

    this.loading = true;

    // // Eliminar imagen de storage
    // if (this.data.rutaStorage) {
    //   this.storage.ref(`${this.data.rutaStorage}`).delete();
    //   console.log('Imagen eliminada de Storage');
    // } else {
    //   console.log('No hay imagen');
    // }

    // // Eliminar doc de Firestore
    // this.afs.doc(`${this.data.rutaFirestore}`).delete()
    // .then(() => {
    //   this.dialogRef.close();
    //   this.snackBar.open('Item eliminado', 'CERRAR', {
    //     duration: 3000,
    //   });
    // });




    // Eliminar archivos de storage

    if(this.data.doc.files) {

      if (this.data.doc.files.length > 0) {

        this.data.doc.files.forEach( element => {
          this.storage.ref(`${this.data.route}/${element.id}`).delete().subscribe( () => {
  
            console.log('Eliminado de storage');
            this.deleteDocFirestore();
  
          });
        });
  
      }
      if(this.data.doc.files.length == 0) {
        this.deleteDocFirestore();
      }

    } else {
      this.deleteDocFirestore();
    }

  }

  deleteDocFirestore() {
    this.afs.doc(`${this.data.route}`).delete()
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Item eliminado', 'CERRAR', {
        duration: 3000,
      });
    });
  }

  cancelar() {
    this.dialogRef.close();
  }

}
