import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Evento } from 'src/app/classes/evento';

@Component({
  selector: 'app-editar-evento',
  templateUrl: './editar-evento.component.html',
  styleUrls: ['./editar-evento.component.scss']
})
export class EditarEventoComponent implements OnInit {

  formEvento: FormGroup;
  disabled = true;
  loading = false;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Eventos',
        url: '/admin/eventos'
      },
    ],
    back: '/admin/eventos',
    titulo: 'Editar Evento',
    urlButton: ''
  };

  itemId: any;
  data: any;
  maxNumFotos = 6;

  evento: Evento;
  id: string;

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    this.itemId = this.activatedRoute.snapshot.params.id;
    
    this.data = {
      id: this.itemId,
      route: `eventos/${this.itemId}`,
      maxNumFiles: 6,
      maxFileSize: 1048576 * 5,
      title: 'Imágenes',
      typeFiles: [
        '.jpg',
        '.jpeg',
        '.png'
      ],
      condiciones: [
        'Tamaño recomendado: 800 x 450',
        'Peso máximo por imagen 5 MB',
        `Máximo 6 imágenes`,
      ]
    }

  }

  
  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.params.id;
    console.log(this.id);

    this.afs.doc(`eventos/${this.id}`).valueChanges().subscribe( (res: Evento) => {

      this.evento = res;

      this.formEvento = this.fb.group({
        nombre: [this.evento.nombre, Validators.required],
        subtitulo: [this.evento.subtitulo, Validators.required],
        descripcion: [this.evento.descripcion, Validators.required],
        nameButton: [this.evento.nameButton],
        urlButton: [this.evento.urlButton],
        // publicado: false,
        lastModified: new Date(),
      })


    });

   

  }

  onSubmit() {
    if (this.formEvento.valid) {
      this.disabled = false;
      this.loading = true;
      this.crearEvento();
    }
  }

  crearEvento() {
    this.afs.doc(`eventos/${this.itemId}`).update(this.formEvento.value)
    .then( (res) => {
      this.router.navigate(['/admin/eventos']);
    });
  }

}
