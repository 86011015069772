<!-- <div class="bg-light py-3 py-md-4">
    <div class="container">
        <div class="d-flex justify-content-between">
            <a class="h2 mb-0 text-decoration-none text-dark" routerLink="/admin">Admin Canatur Web</a>
            <div>
                <button class="btn btn-outline-primary px-3" (click)="logout()">Cerrar sesión</button>
            </div>

        </div>
    </div>
</div> -->




<div class="bg-light">
    <div class="container">
        <div class="py-3 py-md-3 d-flex justify-content-between align-items-center">
            <a class="h5 mb-0 text-decoration-none text-dark" routerLink="/admin">Admin Canatur</a>
            <div class="d-flex align-items-center" *ngIf="userId">
                <div class="text-right me-2">
                    <p class="mb-0 d-none d-md-block">{{email}}</p>
                </div>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <div class="d-block d-md-none">
                        <button mat-menu-item>{{email}}</button>
                        <mat-divider></mat-divider>
                    </div>
                    <button mat-menu-item (click)="logout()">
                        <span>Cerrar sesión</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>
