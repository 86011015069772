<!-- <div>
	<div class="d-flex justify-content-between align-items-center mb-2 mb-md-3">
		<p class="tituloModal text-danger">Eliminar Producto</p>
		<button mat-icon-button (click)="cancelar()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="">
		<p class="lead">¿Está seguro que desea eliminar la siguiente noticia?</p>
		<div class="alert alert-danger">
			<p class="mb-0"><strong>Código:</strong> {{data.id}}</p>
			<p class="mb-0"><strong>Nombre:</strong> {{data.titulo}}</p>
		</div>
		<p class="small text-danger mb-3 mb-md-4">
			<mat-icon [inline]="false">report_problem</mat-icon> Esta acción no se puede deshacer.
		</p>
	</div>
	<div class="text-right">
		<button class="btn btn-link" (click)="cancelar()">Cancelar</button>
		<button class="btn btn-danger ml-3" (click)="eliminarProducto(data.id, data.imageName)">
			<mat-icon class="align-middle" *ngIf="!deshabilitado">delete</mat-icon>
			<mat-spinner *ngIf="deshabilitado" [diameter]="24" class="float-left mr-2"></mat-spinner>
			Eliminar Noticia
		</button>
	</div>
</div> -->



<div class="p-1 p-md-3">
  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p class="h4 mt-2 mt-md-0">Eliminar noticia</p>
  <div class="my-3 my-md-4">
    <p class="">¿Seguro que desea eliminar esta noticia? Esta acción no se puede deshacer.</p>
		<p class="extraSmall text-muted font-monospace lh-sm">Código: {{data.id}}</p>
  </div>
  <div class="d-grid">
    <button type="submit" class="btn btn-danger py-2 px-3" (click)="eliminarNoticia()">
      <span class="myColorSpinnerWhite d-flex justify-content-center" *ngIf="deshabilitado">
        <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
      </span>
      <div *ngIf="!deshabilitado">
        <span>Eliminar noticia</span>
      </div>
    </button>
  </div>
</div>