import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataFiles } from 'src/app/classes/data-files';
import { Estatuto } from 'src/app/classes/estatuto';

@Component({
  selector: 'app-editar-estatuto',
  templateUrl: './editar-estatuto.component.html',
  styleUrls: ['./editar-estatuto.component.scss']
})
export class EditarEstatutoComponent implements OnInit {

  formEstatuto: FormGroup;
  disabled = true;
  loading = false;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Estatutos',
        url: '/admin/estatutos'
      },
    ],
    back: '/admin/estatutos',
    titulo: 'Editar Estatuto',
    urlButton: ''
  };

  itemId: any;
  data: DataFiles;

  estatuto: Estatuto;
  id: string;

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    this.itemId = this.activatedRoute.snapshot.params.id;
    
    this.data = {
      id: this.itemId,
      route: `estatutos/${this.itemId}`,
      maxNumFiles: 1,
      maxFileSize: 1048576 * 5,
      title: 'Documento',
      typeFiles: [
        'image/*',
        '.pdf'
      ],
      condiciones: [
        'Formato recomendado: PDF',
        'Peso máximo: 5 MB',
      ]
    }
  }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.params.id;
    console.log(this.id);

    this.afs.doc(`estatutos/${this.id}`).valueChanges().subscribe( (res: Estatuto) => {
      this.estatuto = res;
      this.formEstatuto = this.fb.group({
        nombre: [this.estatuto.nombre, Validators.required],
        lastModified: new Date(),
      })
    });

  }

  onSubmit() {
    if (this.formEstatuto.valid) {
      this.disabled = false;
      this.loading = true;
      this.actualizarEstatuto();
    }
  }

  actualizarEstatuto() {
    this.afs.doc(`estatutos/${this.itemId}`).set(this.formEstatuto.value, {merge: true})
    .then( (res) => {
      this.router.navigate(['/admin/estatutos']);
    });
  }
}
