<div class="border p-3 p-md-4 rounded position-relative mb-2 mb-md-3" >
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex">
      <p class="mb-0" style="padding-top: 2px;">{{item.nombre}}</p>
    </div>
    <div>
      <i-tabler class="text-primary" name="arrow-right"></i-tabler>
    </div>
  </div>
  <a class="stretched-link" [routerLink]="item.url"></a>
</div>
