<div class="bg-dark mt-6 mt-md-8 pt-4 pt-md-6">
	<div class="container d-flex justify-content-between flex-column flex-md-row ps-4 ps-md-0">
		<div class="text-white">
			<img src="assets/images/logoCanaturWhite.svg" alt="Logo Canatur Blanco" width="280px">
			<div class="d-flex flex-column mt-3 mt-md-4">
				<a class="text-reset" href="https://goo.gl/maps/Bg8YWZuPtDevCQgK6">Av. Paseo de la República 6348, Miraflores -
					Lima</a>
				<a class="text-reset" href="mailto:asistente@canaturperu.org"><strong>E.</strong> asistente@canaturperu.org</a>
				<a class="text-reset" href="tel:5117155983"><strong>T.</strong> (511) 715 5983</a>
				<div class="my-2 my-md-3">
					<!-- <a class="btn btn-primary myButtonCircleRedSocial me-2" *ngFor="let redSocial of redesSociales"
						[href]="redSocial.url" target="blank">
						<div class="d-flex align-items-center justify-content-center h-100">
							<i [class]="redSocial.icon"></i>
						</div>
					</a> -->
					<a class="btn btn-primary myButtonCircleRedSocial p-0 me-2" *ngFor="let redSocial of redesSociales"	[href]="redSocial.url" target="blank">
						<i-tabler [name]="redSocial.icon" style="width: 20px; height: 20px; margin-top: 2px;"></i-tabler>
					</a>
				</div>
				<br>
			</div>
		</div>

		<div class="d-flex flex-column">
			<div *ngFor="let link of links" class="text-white py-1">
				<a class="linkMenuFooter py-1 text-reset" [routerLink]="link.url">
					<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right me-1"
						width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#E77917" fill="none"
						stroke-linecap="round" stroke-linejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" />
						<line x1="5" y1="12" x2="19" y2="12" />
						<line x1="15" y1="16" x2="19" y2="12" />
						<line x1="15" y1="8" x2="19" y2="12" />
					</svg>{{link.nombre}}
				</a>
				<div *ngFor="let submenu of link.submenu" class="pt-2 ps-0">
					<a class="ps-3 text-reset small" [routerLink]="submenu.url">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-corner-down-right mr-1"
							width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#E77917" fill="none"
							stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" />
							<path d="M6 6v6a3 3 0 0 0 3 3h10l-5 -5m0 10l5 -5" />
						</svg>{{submenu.nombre}}
					</a>
				</div>
			</div>
		</div>
		<div class="text-white">
			<p class="op-50 small">Recibe las noticias en tu email</p>
			<div class="mb-3 mb-md-4">
				<app-suscripcion></app-suscripcion>
			</div>
			<a class="text-reset" routerLink="/politicaPrivacidad">Política de privacidad</a><br>
			<a class="text-reset" routerLink="/terminosCondiciones">Términos y condiciones</a><br>
			<img class="mt-3"
				src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logoPortalTurismo.png?alt=media&token=1a1de709-3cbe-43c1-bd91-25b934fd181e"
				alt="" width="240px">
		</div>
	</div>

	<div class="col-md-10 mx-auto py-3 py-md-5 px-2 px-md-0 text-white">
		<div class="grillaGremiosFooter">
			<div *ngFor="let gremio of gremiosDark">
				<img [src]="gremio" width="100%">
			</div>
		</div>
		<hr>
		<p class="text-center small">Todos los derechos reservados - Canatur Perú 2020</p>
	</div>

</div>