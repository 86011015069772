import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoticiaHel } from '../../classes/noticia-hel';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-detalle-noticia-hel',
  templateUrl: './detalle-noticia-hel.component.html',
  styleUrls: ['./detalle-noticia-hel.component.scss']
})
export class DetalleNoticiaHelComponent implements OnInit {

  noticiaHelId: string;

  noticiaHelDoc: AngularFirestoreDocument<NoticiaHel>;
  noticiaHel: NoticiaHel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( res => {
      this.noticiaHelId = res.id;

      // this.noticiaHelDoc = this.afs.doc(`noticiasHel/${this.noticiaHelId}`);
      // this.noticiaHel = this.noticiaHelDoc.valueChanges();
      // console.log(this.noticiaHel);

      this.afs.doc(`noticiasHel/${this.noticiaHelId}`).valueChanges().subscribe( data => {
        this.noticiaHel = data;
      });

    });
  }

}
