import { Component, OnInit } from '@angular/core';
import { BannerHel } from 'src/app/classes/banner-hel';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-bloque-banners',
  templateUrl: './bloque-banners.component.html',
  styleUrls: ['./bloque-banners.component.scss']
})
export class BloqueBannersComponent implements OnInit {

  bannersHel: BannerHel[] = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getBannersHel().subscribe( res => {
      this.bannersHel = res;
    });
  }


}
