<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/helvetas">seCompetitivo</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/helvetas">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Noticias SeCompetitivo</p>
  </div>

  <!-- <p class="h2 mb-3 mb-md-4">Noticias SeCompetitivo</p> -->
  

  <div class="row g-4 g-md-5">
    <div class="col-md-4" *ngFor="let noticia of noticiasHel">
      <div class="h-100 bg-light position-relative">
        <img class="imageNoticiaCardHel" [src]="noticia.image" alt="">
        <div class="p-3 p-md-4 rounded-bottom pt-2 pt-md-3">
          <div class="rounded-pill px-2 py-1 bg-primary d-inline-flex mb-2 extraSmall">{{noticia.fecha.toDate() | date: 'mediumDate' }}</div>
          <p class="h6">{{noticia.titulo}}</p>
          <p class="small text-muted mb-0">{{noticia.subtitulo}}</p>
        </div>
        <a class="stretched-link" [routerLink]=" '/helvetas/' + noticia.id "></a>
      </div>
    </div>
  </div>

</div>
