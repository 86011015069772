<div class="container py-3 py-md-5 pb-6 pb-md-8 small">
  <p class="h1 mb-4">Política de Privacidad del Aplicativo Móvil   NORTH AMAZONIAN BIRDS PERÚ</p>
  <p class="h5">Recogida y tratamiento de datos de carácter personal</p>
  <p>Los datos de carácter personal son los que pueden ser utilizados para identificar a una persona o ponerse en contacto con ella.</p>
  <p>CANATUR puede solicitar datos personales de usuarios al acceder a aplicaciones de la empresa o de otras empresas afiliadas, así como la posibilidad de que entre estas empresas puedan compartir esos datos para mejorar los productos y servicios ofrecidos. Si no se facilitan estos datos personales, en muchos casos no podremos ofrecer los productos o servicios solicitados.</p>
  <p class="h5">¿Qué datos de carácter personal se pueden recopilar?</p>
  <ul>
    <li>Al crear un ID, se recopilan diferentes datos, como nombre, dirección postal, o número de teléfono, o dirección de correo electrónico.</li>
    <li>Cuando se comparten contenidos con familiares y amigos o se invita a otras personas a participar en los servicios o foros, pueden recogerse los datos que facilitamos sobre esas personas, como su nombre, domicilio, correo electrónico y/o número de teléfono. Se utilizarán dichos datos para completar sus pedidos, mostrarle el producto o servicio correspondiente o para combatir el fraude.</li>
  </ul>
  <p class="h5">Propósito del tratamiento de datos de carácter personal</p>
  <p>CANATUR podrá utilizar los datos personales recabados para:</p>
  <ul>
    <li>Los datos de carácter personal recopilados permiten mantenerse informado acerca de las últimas NOTICIAS y EVENTOS en la región, las actualizaciones de software disponibles y los próximos acontecimientos.</li>
    <li>También se utilizan los datos de carácter personal como ayuda para   elaborar, perfeccionar, gestionar, proporcionar y mejorar los productos, servicios, contenidos y publicidad del aplicativo móvil, y con el propósito de evitar pérdidas y fraudes.</li>
    <li>Pueden utilizarse los datos de carácter personal para comprobar la identidad, colaborar en la identificación de usuarios y decidir los servicios apropiados.</li>
    <li>También   se utilizan   esos datos	de	carácter    personal con propósitos internos, incluyendo auditorías, análisis de datos y sondeos, para mejorar los productos, servicios y comunicaciones a clientes.</li>
    <li>Si participa en un sorteo, un concurso o una promoción, pueden usarse los datos proporcionados para administrar estos programas.</li>
  </ul>
  <p class="h3">Recopilación y tratamiento de datos de carácter no personal</p>
  <p>CANATUR también podrá recopilar datos de un modo que, por sí mismos, no pueden ser asociados directamente a una persona determinada. Estos datos de carácter no personal se pueden recopilar, tratar, transferir y publicar con cualquier intención. Estos son algunos ejemplos de las clases de datos de carácter no personal que CANATUR puede recopilar y los fines para los que se realiza su tratamiento:</p>
  <ul>
    <li>Datos tales como profesión, idioma, código postal, identificador único de dispositivo, etc. para comprender mejor la conducta de nuestros clientes y mejorar nuestros productos, servicios y anuncios publicitarios.</li>
    <li>Datos sobre cómo se usan determinados servicios, incluidas las consultas de búsqueda. Esta información se puede utilizar para incrementar la importancia de los resultados que aportan los servicios ofrecidos.</li>
    <li>Datos sobre cómo usa su dispositivo y las aplicaciones para facilitar a los desarrolladores la mejora de esas aplicaciones.</li>
    <li>Datos de imágenes o fotografías publicadas en el aplicativo, son 100% públicas, sin ningún tipo de restricción, debido a que este cumple una función informativa.</li>
    <li>Los usuarios que suban información no permitida sean explícitos o no que se encuentren fuera del objetivo educativo del aplicativo, podrán ser sancionados o eliminados del aplicativo.</li>
  </ul>
  <p>Nota: Si juntamos datos de carácter no personal con datos personales, los datos mezclados serán tratados como datos personales mientras sigan estando combinados.</p>
  <p class="h3">Divulgación a terceros</p>
  <p>Ocasionalmente, CANATUR puede facilitar determinados datos de carácter personal a socios estratégicos que trabajen con nosotros para proveer productos y servicios o nos ayudan en nuestras actividades de marketing. No se compartirán los datos con ningún tercero para sus propios fines de marketing.</p>
  <p class="h5">Otros terceros</p>
  <p>Es posible que CANATUR divulgue datos de carácter personal por mandato legal, en el marco de un proceso judicial o por petición de una autoridad pública, tanto dentro como fuera de su país de residencia. Igualmente se puede publicar información personal si es necesaria o conveniente por motivos de seguridad nacional, para acatar la legislación vigente o por otras razones relevantes de orden público.</p>
  <p class="h3">Protección de datos de carácter personal</p>
  <p>CANATUR garantizará la protección de los datos personales mediante cifrado durante el tránsito y, los alojados en instalaciones, con medidas de seguridad físicas.</p>
  <p>Al usar ciertos productos, servicios o aplicaciones o al publicar opiniones en foros, salas de chat o redes sociales, el contenido y los datos de carácter personal que se comparta será visible para otros usuarios, que tendrán la posibilidad de leerlos, compilarlos o usarlos. Usted será responsable de los datos de carácter personal que distribuya o proporcione en estos casos.</p>
  <p class="h5">Integridad y conservación de datos de carácter personal</p>
  <p>CANATUR garantizará la exactitud y la calidad de los datos personales, se conservarán durante el tiempo necesario para cumplir los fines para los que fueron recabados, salvo que la ley exija conservarlos durante más tiempo.</p>
  <p>Acceso a los datos de carácter personal</p>
  <p>Respecto a los datos de carácter personal que conservamos, CANATUR ofrece acceso a ellos para cualquier fin, incluyendo las solicitudes de rectificación en caso de que sean incorrectos o de eliminación en caso de no estar obligados a conservarlos por imperativo legal o por razones legítimas de negocio. Nos reservamos el derecho a no tramitar aquellas solicitudes que sean improcedentes o vejatorias, que pongan en riesgo la privacidad de terceros, que resulten inviables o para las que la legislación local no exija derecho de acceso. Las solicitudes de acceso, rectificación o eliminación podrán enviarse a nuestra dirección de correo institucional info@aveznoramazonicasperu.pe.</p>
  <p class="h5">Niños y educación</p>
  <p>CANATUR es consciente de la necesidad de establecer precauciones adicionales para preservar la privacidad y la seguridad de los menores que utilizan las aplicaciones y exigir consentimiento de sus progenitores en caso de que no tengan la edad mínima exigida por la legislación. Si se han recopilado datos personales de un menor de 18 años, sin el consentimiento   necesario, se debe eliminar esa información lo antes posible.</p>
  <p class="h5">Servicios de localización</p>
  <p>Para prestar servicios de localización CANATUR podrá reunir, utilizar y compartir datos exactos sobre ubicaciones, incluyendo la situación geográfica en tiempo real de su ordenador o de su dispositivo. Salvo que nos den su consentimiento, estos datos de localización se recogen de manera anónima de forma que no pueden utilizarse para identificarlo personalmente, y son usados para suministrar y mejorar sus productos y servicios de localización.</p>
  <p class="h5">Páginas web y servicios de terceros</p>
  <p class="mb-5 mb-md-6">El aplicativo NORTH AMAZONIAN BIRDS PERÚ puede contener enlaces a páginas web, productos y servicios de terceros. También pueden utilizar u ofrecer productos o servicios de terceros. La recogida de datos por parte de terceros, introduciendo datos sobre ubicaciones geográficas o datos de contacto, se guiará por sus respectivas políticas de privacidad. Le recomendamos consultar las políticas de privacidad de esos terceros.</p>
  <p class="h1">Políticas de Privacidad</p>
  <p class="h5">POLÍTICA DE ENLACES</p>
  <p>El sitio web puede contener enlaces a otros sitios de internet pertenecientes a terceros de los cuales no se hace responsable.</p>
  <p class="h5">POLÍTICA DE PROPIEDAD INTELECTUAL E INDUSTRIAL</p>
  <p>El titular manifiesta tener los derechos de propiedad intelectual e industrial del APLICATIVO MÓVIL NORTH AMAZONIAN BIRDS PERÚ incluyendo imágenes, archivos de audio o video, logotipos, marcas, colores, estructuras, tipografías, diseños y demás elementos que lo distinguen, protegidos por la legislación peruana e internacional en materia de propiedad intelectual e industrial.</p>
  <p>El usuario se compromete a respetar los derechos de propiedad industrial e intelectual del titular pudiendo visualizar los elementos del sitio web, almacenarlos, copiarlos e imprimirlos exclusivamente para uso personal.</p>
  <p class="h5">LEGISLACIÓN Y JURISDICCIÓN APLICABLE</p>
  <p>La relación entre el usuario y el titular se regirá por las legislaciones aplicables en Perú. CANATUR no se responsabiliza por la indebida utilización del contenido, productos y/o servicios del aplicativo móvil y del incumplimiento de los presentes términos y condiciones.</p>

</div>