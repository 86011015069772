import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { of } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-gremios',
  templateUrl: './gremios.component.html',
  styleUrls: ['./gremios.component.scss']
})
export class GremiosComponent implements OnInit {

  gremios;
  gremiosResult;

  constructor(
    private ds: DataService,
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    // this.gremios = this.ds.gremios;
    // this.gremiosResult = of(this.gremios.sort((a, b) => a.nombre < b.nombre ? -1 : a.nombre > b.nombre ? 1 : 0));
    this.gremios = this.fs.getGremios();
  }

}
