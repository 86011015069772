import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { FileValidator } from 'ngx-material-file-input';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-editar-noticia',
  templateUrl: './editar-noticia.component.html',
  styleUrls: ['./editar-noticia.component.scss']
})
export class EditarNoticiaComponent implements OnInit {

  formNoticia: UntypedFormGroup;
  categorias;
  visible = false;
  deshabilitado = false;
  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  readonly maxSize = 1048576 * 5;
  actualSize: any;

  tiposImagen = [
    'Link',
    'Subir imagen'
  ];

  link = true;
  subirImagen: boolean;

  idNoticia;
  noticia;
  loader = true;

  constructor(
    private fs: FirebaseService,
    private ds: DataService,
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.categorias = this.ds.categorias;

    this.activatedRoute.params.subscribe(res => {
      this.idNoticia = res.id;
      this.afs.doc('noticias/' + this.idNoticia).valueChanges().subscribe( data => {
        this.noticia = data;
        this.loader = false;

        this.formNoticia = this.fb.group({
          categoria: [this.noticia.categoria, Validators.required],
          fecha: [this.noticia.fecha.toDate(), Validators.required],
          titulo: [this.noticia.titulo, Validators.required],
          subtitulo: [this.noticia.subtitulo, Validators.required],
          url: [this.noticia.url, Validators.required],
          tipoImagen: ['Link'],
          urlImage: [this.noticia.urlImage, Validators.required],
          image: [''],
          publicado: [false],
          destacado: [false],
          fechaCreacion: [Timestamp.now()],
        });

        // tslint:disable-next-line:no-shadowed-variable
        this.formNoticia.get('tipoImagen').valueChanges.subscribe( res => {
          console.log(res);
          if (res === 'Link') {
            this.link = true;
            this.subirImagen = false;
            this.formNoticia.get('urlImage').setValidators(Validators.required);
            this.formNoticia.get('image').clearValidators();
            this.formNoticia.get('image').setValue('');
          }
          if (res === 'Subir imagen') {
            this.subirImagen = true;
            this.link = false;
            this.formNoticia.get('image').setValidators([Validators.required, FileValidator.maxContentSize(this.maxSize)]);
            this.formNoticia.get('urlImage').clearValidators();
            this.formNoticia.get('urlImage').setValue('');
          }
        });

      });
    });

  }

  onSubmit() {

    if (this.formNoticia.get('tipoImagen').value === 'Link' && this.formNoticia.valid) {
      this.deshabilitado = true;
      // this.crearNoticia();
      this.actualizarNoticia();
    }
    if (this.formNoticia.get('tipoImagen').value === 'Subir imagen' && this.formNoticia.valid) {
      this.deshabilitado = true;
      this.uploadFileAndcrearNoticia();

    } else {
      this.validateAllFormFields(this.formNoticia);
      this.deshabilitado = false;
    }

  }

  // crearNoticia() {
  //   this.deshabilitado = true;
  //   this.fs.addNoticia(this.formNoticia.value)
  //     .then(() => {
  //       this.router.navigate(['/admin/noticias']);
  //     });
  // }

  actualizarNoticia() {
    const noticia = this.formNoticia.value;
    ['image', 'imageName'].forEach(e => delete noticia[e]);
    this.afs.doc(`noticias/${this.idNoticia}`).update(this.formNoticia.value)
    .then(() => {
      this.router.navigate(['/admin/noticias']);
      console.log('Noticia actualizada');
    });
  }

  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  uploadFileAndcrearNoticia() {
    // const noticia = this.afs.collection('noticias').ref.doc();
    // console.log(noticia.id);

    const file = this.selectedFile;
    // const filePath = `imagesProducts/${noticia.id}/name1`;
    const filePath = `imagesNoticias/${this.idNoticia}/${this.nameItem}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();


    // task.snapshotChanges().pipe(
    //   finalize(() => {
    //     fileRef.getDownloadURL().toPromise().then( (url) => {
    //       this.downloadURL = url;
    //       myTest.set({
    //         categoria: this.formNoticia.value.categoria,
    //         titulo: this.formNoticia.value.titulo,
    //         fecha: this.formNoticia.value.fecha,
    //         subtitulo: this.formNoticia.value.subtitulo,
    //         urlImage: this.formNoticia.value.urlImage,
    //         image: this.downloadURL,
    //         imageName: this.nameItem,
    //         url: this.formNoticia.value.url,
    //         publicado: this.formNoticia.value.publicado,
    //         destacado: this.formNoticia.value.destacado,
    //         fechaCreacion: this.formNoticia.value.fechaCreacion,
    //       });
    //       // this.dialogRef.close();
    //       this.router.navigate(['/admin/noticias']);
    //       console.log( this.downloadURL );
    //     }).catch(err => { console.log(err); } );
    //   })
    // )


    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`noticias/${this.idNoticia}`).update({
          ...this.formNoticia.value,
          image: url,
          imageName: this.nameItem,
        });
        this.router.navigate(['/admin/noticias']);
        console.log(url);

        });
        }
        )).subscribe();

  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorImagen() {
    return this.formNoticia.controls.image.hasError('required') ? 'La imagen es necesaria' :
    this.formNoticia.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }


}
