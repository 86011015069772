<div class="container">
  <div class="row gy-4">
    <div class="col-md-4">
      <p class="h2">Documentos</p>
    </div>
    <div class="col-md-8">
      <div class="row">

        <div class="col-12 " *ngFor="let documento of documentosHel">

          <div mat-menu-item class="position-relative" style="height: initial;">
            <div class="d-flex justify-content-between align-items-center py-3">
              <div class="d-flex align-items-center">
                <div class="me-2 me-md-3">
                  <img [src]="readTypeFile(documento.typeFile)" alt="" width="36px" height="auto">
                </div>
                <p class="mb-0">{{documento.nombre}}</p>
              </div>
              <div>
                <i-tabler name="external-link" class="text-primary"></i-tabler>
              </div>
            </div>
            <a class="stretched-link" [href]="documento.archivo" target="_blank"></a>
          </div>

          <mat-divider></mat-divider>

         

          <!-- <div class="d-flex rounded h-100 bg-white position-relative">
            <div class="contentNoticiaHel">
              <p class="titulo">{{noticia.titulo}}</p>
              <p class="subtitulo d-none d-md-block">{{noticia.subtitulo}}</p>
              <div class="d-flex align-items-center">
                <span class="me-1">
                  <i-tabler class="text-muted" name="calendar-event"
                    style="width: 20px; height: auto; stroke-width: 1.2;"></i-tabler>
                </span>
                <span class="extraSmall text-muted" style="padding-top: 3px;">{{noticia.fecha.toDate() |
                  date:'mediumDate'}}</span>
              </div>
            </div>
            <div class="ms-2 ms-md-3">
              <img class="imageNoticiaHelCard" [src]="noticia.image" alt="">
            </div>
            <a class="stretched-link" [routerLink]=" '/helvetas/' + noticia.id "></a>
          </div> -->

        </div>

      </div>
    </div>
  </div>
</div>