import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { EliminarGremioComponent } from '../eliminar-gremio/eliminar-gremio.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-lista-gremios',
  templateUrl: './lista-gremios.component.html',
  styleUrls: ['./lista-gremios.component.scss']
})
export class ListaGremiosComponent implements OnInit {

  count: number;
  loading: boolean;
  displayedColumns = [ 'imagen', 'nombre', 'publicado', 'mas' ];
  gremiosData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.gremiosData.filter = filterValue;
  }

  opciones = [
    {
      nombre: 'Editar',
      icon: 'pencil',
      function: (gremio) => this.editarItem(gremio)
    },
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (gremio) => this.eliminarItem(gremio)
    }
  ];

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
    ],
    back: '/admin/asociados',
    titulo: 'Gremios',
    urlButton: '/admin/asociados/gremios/crearGremio'
  };

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.fs.getAllGremios().subscribe(data => {
      this.gremiosData.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.gremiosData.paginator = this.paginator;
    this.gremiosData.sort = this.sort;
  }

  ngAfterViewInit() {
    this.gremiosData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  // actualizarPublicado(key, obj, e) {
  //   this.fs.updatePublicadoGremio(key, e);
  // }

  actualizarPublicado(idGremio, change: MatSlideToggleChange) {
    this.afs.collection('gremios').doc(idGremio).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item) {
    return item.publicado;
  }

  editarItem(gremio) {
    this.router.navigate([`admin/asociados/gremios/editarGremio/${gremio.id}`])
  }

  eliminarItem(gremio) {
    this.matDialog.open(EliminarGremioComponent, {
      data: gremio
    });
  }

}
