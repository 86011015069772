import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FileItem } from 'src/app/classes/file-item';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UploadImagesService } from 'src/app/services/upload-images.service';

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent implements OnInit {

  @Input() data: any;

  fotos: FileItem[] = [];
  disabled = true;
  uploadPercent: Observable<number> | undefined;
  downloadURL!: Observable<string>;
  urlsImages: any[] = [];
  resultados: any[] = [];
  loading = false;
  fotoTipo!: string;
  avisoSuccess = false;
  negocioId: string | undefined;
  imagesPreview = [];
  maxFotos = false;
  maxButton = true;
  // maxNumFotos = 5;
  fotosFirestore: any[] = [];
  visiblePreviewImages = true;
  sizeCollection: number | undefined;
  showButtonAddFile!: boolean;
  showAlert!: boolean;
  totalFotos!: number;

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private fs: FirebaseService,
    private uploadImages: UploadImagesService
  ) {}

  ngOnInit() {

    // traer imágenes del item
    this.fs.getAllImagesItem(this.data).subscribe( res => {
      this.fotosFirestore = res;
      console.log(this.fotosFirestore);
      this.fotos = [];
      this.sizeCollection = res.length;
      this.checkNumFotos();
    });
    
    // console.log(this.data.id);
    this.checkNumFotos();

    

  }

  getInputFile() {
    document.getElementById('formFileMultiple')?.click();
  }


  getFileDetails (event: any) {

    const files = event.target.files;

    for (let i = 0; i < files?.length; i++) {
      const nuevoArchivo = new FileItem(files[i]);
      this.fotos.push(nuevoArchivo);
      nuevoArchivo.imgUrl = URL.createObjectURL(files[i]);
      this.totalFotos = this.fotosFirestore.length + this.fotos.length
      this.totalFotos > this.data.maxNumFotos ? nuevoArchivo.remove = true : nuevoArchivo.remove = false;
    }
    // console.log(this.fotos);
    this.checkNumFotos();
    this.uploadFiles();
  }

  uploadFiles() {
    
    if ( this.totalFotos <= this.data.maxNumFotos) {
      this.uploadImages.uploadFilesItem(this.fotos, this.data, this.sizeCollection!)
    }
  }

  checkNumFotos() {
    this.totalFotos = this.fotosFirestore.length + this.fotos.length
    this.totalFotos < this.data.maxNumFotos ? this.showButtonAddFile = true : this.showButtonAddFile = false;
    this.totalFotos > this.data.maxNumFotos ? this.showAlert = true : this.showAlert = false;
  }

  removeItem(i: any) {
    this.fotos.splice(i, 1);
    this.checkNumFotos();
    this.uploadFiles();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.fotosFirestore, event.previousIndex, event.currentIndex);

    this.fotosFirestore.map( (element: any, index: number )=> {
      this.afs.collection(`${this.data.route}/images`).doc(element.id).update({
        order: index + 1
      });
    });

    this.updateFirstImage();

  }


  deleteImageFromFirebase(item: { id: string | undefined; nameImage: any; }) {
    // Delete of Firestore
    this.afs.collection(`${this.data.route}/images`).doc(item.id).delete().then( () => {
      console.log('Eliminado de firestore');
      // Delete of Storage
      this.storage.ref(`${this.data.route}/${item.nameImage}`).delete().subscribe( res => {
        console.log('Eliminado de Storage');
      });
      // Update first image
      this.updateFirstImage();
    });
  }

  
  updateFirstImage() {
    this.afs.doc(`${this.data.route}`).update({
      image: this.fotosFirestore[0].urlImage
    });
  }

}
