<div class="container">

  <nav class="small mt-3">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active text-muted">Alianzas</li>
    </ol>
  </nav>
  <p class="h1 mb-3 mb-md-4">Alianzas</p>


  <div class="row">
    <div class="grillaAlianzas col-md-10">
      
      <div [id]="alianza.id" class="rounded p-3 p-md-4 shadow-sm border" *ngFor="let alianza of alianzas">
        <p class="h3 text-primary">{{alianza.nombre}}</p>
        <p class="font-weight-light">{{alianza.descripcion}} <span *ngIf="alianza.email">Consultas a <a href="mailto:{{alianza.email}}">{{alianza.email}}</a>.</span></p>

        <div class="d-flex mb-3" *ngIf="alianza.suscripcion">
          <span class="me-2">
            <i-tabler name="arrow-right" class="text-primary" style="width: 22px;"></i-tabler>
          </span>
          <span class="small" style="padding-top: 2px;">{{alianza.suscripcion}}</span>
        </div>

        <div>
          <a class="btn btn-primary" *ngFor="let file of alianza.files" [href]="file.urlFile" target="blank">Ver documento</a>
        </div>

      </div>

    </div>
  </div>
</div>