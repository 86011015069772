<!-- <nav class="container small mt-3">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
		<li class="breadcrumb-item active text-muted">Proyecto SeCompetitivo</li>
	</ol>
</nav>

<div class="container py-3">

	<div class="">

		<div class="text-center col-md-9 mx-auto mb-4 mb-md-6">
			<div class="h2 px-3 py-2 bg-success rounded text-white d-inline-block mb-3">Proyecto SeCompetitivo</div>
			<p class="h1">"Tarapoto y Alto Mayo: Turismo y Naturaleza en Valor”, facilitado por HELVETAS y ejecutado por
				CANATUR</p>
		</div>

		<div class="grillaLogosHelvetas my-3 my-md-4">
			<div class="" *ngFor="let logo of logosHelvetas">
				<img [src]="logo" alt="" width="100%">
			</div>
		</div>

		<div class="">
			<div class="ratio ratio-16x9 rounded mb-3 mb-md-4 ">
				<iframe class="rounded" width="100%" height="auto" src="https://www.youtube.com/embed/GLQrQHlVHCo"
					allowfullscreen></iframe>
			</div>
		</div>
		
		<div class="bgHelvetas py-5 py-md-9 px-4 px-md-8 text-white text-center mb-4 mb-md-5">
			<p class="h3">“Fortaleciendo la Competitividad de la Cadena de Valor del turismo de la Región San Martín”</p>
			<p>El proyecto Tarapoto y Alto Mayo: “Turismo y Naturaleza en Valor es implementado por la Cámara Nacional de
				Turismo del Perú” – CANATUR en el marco del Programa SeCompetitivo de la Cooperación Suiza - SECO</p>
		</div>

		<div class="mb-5">
			<a class="btn btn-outline-primary" href="https://turismodenaturaleza.pe/" target="_blank">
				Conoce más aquí
				<i-tabler name="arrow-right" class="ms-2"></i-tabler>
			</a>
		</div>




		<div class="row my-4 my-md-5">
		<div class="col-md-4">
			<a class="btn btn-primary btn-lg btn-block py-3 my-1"
				href="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FbrochureInstitucional.pdf?alt=media&token=a61b731a-a803-476d-b4da-0c80ddfd2b51"
				target="_blank">Brochure Institucional (PDF)</a>
		</div>
		<div class="col-md-4">
			<a class="btn btn-primary btn-lg btn-block py-3 my-1"
				href="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FbrochureRutas.pdf?alt=media&token=10783551-2a12-4f18-96a3-efcba1e6a42d"
				target="_blank">Brochure Rutas (PDF)</a>
		</div>
		<div class="col-md-4">
			<a class="btn btn-primary btn-lg btn-block py-3 my-1"
				href="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotaPrensaSanMartin.pdf?alt=media&token=af0d833c-90b5-4c5a-9e02-cab1d146c3ed"
				target="_blank">Nota de Prensa (PDF)</a>
		</div>
	</div>



		<div class="mb-5 mb-md-7">
			<p class="h2 mb-3">Documentos</p>
			<div class="row">
				<div class="col-md-6 d-grid">
					<a class="btn btn-primary btn-lg py-3 my-1"
						href="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FbrochureInstitucional.pdf?alt=media&token=a61b731a-a803-476d-b4da-0c80ddfd2b51"
						target="_blank">Brochure Institucional (PDF)</a>
				</div>
				<div class="col-md-6 d-grid">
					<a class="btn btn-primary btn-lg py-3 my-1"
						href="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FbrochureRutas.pdf?alt=media&token=10783551-2a12-4f18-96a3-efcba1e6a42d"
						target="_blank">Brochure Rutas (PDF)</a>
				</div>
			</div>
		</div>



		<div class="mb-5 mb-md-7">
			<p class="h2 mb-3">Notas de prensa</p>
			<div *ngFor="let nota of notasPrensa">
				<div class="d-flex justify-content-between">
					<p class="mb-0">{{nota.titulo}}</p>
					<div class="ms-2">
						<a class="btn btn-primary text-nowrap" [href]="nota.url" target="_blank">Ver nota
							<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-text" width="24"
								height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round"
								stroke-linejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M14 3v4a1 1 0 0 0 1 1h4" />
								<path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
								<line x1="9" y1="9" x2="10" y2="9" />
								<line x1="9" y1="13" x2="15" y2="13" />
								<line x1="9" y1="17" x2="15" y2="17" />
							</svg>
						</a>
					</div>
				</div>
				<mat-divider class="my-2 my-md-3"></mat-divider>
			</div>
		</div>









		<p class="h2 mb-3">Videos</p>

		<div class="row gx-md-3">

			<div class="col-md-4">
				<div class="ratio ratio-16x9 rounded mb-3 mb-md-4 ">
					<iframe class="rounded" width="100%" height="auto" src="https://www.youtube.com/embed/942LhFgzi2c"
						allowfullscreen></iframe>
				</div>
			</div>

			<div class="col-md-4">
				<div class="ratio ratio-16x9 rounded mb-3 mb-md-4">
					<iframe class="rounded" width="100%" height="auto" src="https://www.youtube.com/embed/98NmN5DiPIw"
						allowfullscreen></iframe>
				</div>
			</div>

			<div class="col-md-4">
				<div class="ratio ratio-16x9 rounded mb-3 mb-md-4">
					<iframe class="rounded" width="100%" height="auto" src="https://www.youtube.com/embed/A23UY6Ig4qE"
						allowfullscreen></iframe>
				</div>
			</div>

		</div>


	</div>

</div> -->







<div class="bgBosqueHel">
	<div class="blurBosqueHel">
		<div class="container pt-5 pt-md-6 pb-7 pb-md-9">
		
			<div class="text-white">
				<div class="display-4 fw-bold mb-3">Proyecto SeCompetitivo</div>
				<p class="h2 fw-light">"Tarapoto y Alto Mayo: Turismo y Naturaleza en Valor”, facilitado por HELVETAS y ejecutado por CANATUR</p>
			</div>
		
			<div class="grillaLogosHelvetas my-3 my-md-4">
				<div class="bg-white p-2 p-md-5" *ngFor="let logo of logosHelvetas">
					<img [src]="logo" alt="" width="100%">
				</div>
			</div>
		
		</div>
	</div>
</div>

<div class="marginNegative mb-5 mb-md-7">
	<app-bloque-carousel></app-bloque-carousel>
</div>


<div class="overflow-hidden">

	<div class="row gy-5 gy-md-7">
		<!-- <div class="col-12" style="margin-top: -3rem;">
			<app-bloque-carousel></app-bloque-carousel>
		</div> -->
		<div class="col-12">
			<app-bloque-banners></app-bloque-banners>
		</div>
		<div class="col-12">
			<app-bloque-videos-hel></app-bloque-videos-hel>
		</div>
		<div class="col-12">
			<app-bloque-noticias-hel></app-bloque-noticias-hel>
		</div>
		<div class="col-12">
			<app-bloque-documentos-hel></app-bloque-documentos-hel>
		</div>
	</div>

</div>