<nav class="container small mt-3">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
		<li class="breadcrumb-item active text-muted">Nosotros</li>
	</ol>
</nav>

<div class="container py-4 py-md-5">
	<p class="h1 mb-3 mb-md-4">Nosotros</p>
	<div class="row d-flex justify-content-between">
		<div class="col-md-12">
			<p class="h4 fw-normal mb-4">Fundada el 28 de enero de 1971. Es la asociación civil apolítica y sin fines
				de lucro,
				reconocida oficialmente como la institución representativa del sector empresarial turístico
				a nivel nacional (RM 366-82 ITI/TUR), que asocia a todas las personas naturales, personas
				jurídicas y gremios que desarrollan actividades de turismo en el Perú, así como también
				fomenta, consolida e incorpora a las Cámaras Regionales de Turismo y Cámaras de Destinos
				Turísticos de todo el país, según lo previsto en su estatuto institucional.</p>
		</div>
		<!-- <div class="col-md-4">
            <img src="assets/images/imageFaker.jpg" alt="" width="100%">
        </div> -->
	</div>

</div>


<div class="container mb-5 mb-md-8">
	<p class="h1 mb-4 mb-md-5">¿Qué hacemos?</p>
	<div class="row gy-5 gx-md-6">
		<div class="col-md-4" *ngFor="let item of queHacemos">
			
			<img class="imageFuncion mb-3" [src]="item.image">
			<div class="d-flex">
				<span class="me-2 text-primary">
					<i-tabler name="arrow-right"></i-tabler>
				</span>
				<div>
					<p class="h4 mb-3">{{item.titulo}}</p>
					<p class="">{{item.descripcion}}</p>
				</div>	
			</div>

		</div>
	</div>
</div>

<div class="container mb-5 mb-md-8">
	<div class="bgMision d-flex justify-content-between">
		<div class="row p-5 p-md-6 text-white">
			<div class="col-md-6">
				<p class="display-4 fw-bold">Misión</p>
			</div>
			<div class="col-md-6 pt-0 pt-md-7">
				<p class="lead mb-0">Promover el desarrollo y crecimiento sostenible del sector turismo, de forma dinámica, proactiva y representativa, por medio de su consolidación financiera, organizacional y de desarrollo humano.
				</p>
			</div>
		</div>
	</div>
</div>

<div class="container mb-5 mb-md-8">
	<div class="bgVision d-flex justify-content-between">
		<div class="row p-5 p-md-6 text-white">
			<div class="col-md-6">
				<p class="display-4 fw-bold">Visión</p>
			</div>
			<div class="col-md-6 pt-0 pt-md-7">
				<p class="lead mb-0">Ser el gremio líder del Perú, reconocido por su contribución al crecimiento socio económico del país y su gente, a través de la actividad turística como motor de desarrollo integral.</p>
			</div>
		</div>
	</div>
</div>

<div class="container mb-5 mb-md-8">
	<p class="h1 mb-4 mb-md-5">Plan de acción</p>
	<div class="grillaAcciones">
		<div class="d-flex" *ngFor="let plan of planesAccion">
			<div class="me-3">
				<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="36" height="36"
					viewBox="0 0 24 24" stroke-width="1" stroke="#E77917" fill="none" stroke-linecap="round"
					stroke-linejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" />
					<circle cx="12" cy="12" r="9" />
					<path d="M9 12l2 2l4 -4" />
				</svg>
			</div>
			<p class="mb-0">{{plan}}</p>
		</div>
	</div>
</div>



<div class="container mb-5 mb-md-8">

	<p class="h1 mb-3 mb-md-5">Junta Directiva</p>

	<div class="">

		<div class="text-center my-3 my-md-4">
			<div class="border border-primary border-2 px-3 py-2 d-inline-block mb-4 mb-md-6 text-primary text-uppercase h6" *ngIf="periodo">{{periodo.periodo}}</div>
		</div>

		

		<div class="row gy-5 gx-md-6">

			<div class="col-md-3" *ngFor="let miembro of juntaDirectiva">

				<div class="text-center">
					<div class="mb-2" *ngIf="miembro.image">
						<img class="imageMiembro" [src]="miembro.image || 'assets/images/imageFakerCamara.png' ">
					</div>
					<div class="px-4">
						<p class="h5 mb-1">{{miembro.nombre}}</p>
						<p class="small text-muted text-uppercase">{{miembro.cargo}}</p>
					</div>
				</div>

			</div>

		</div>

	</div>

</div>


<!-- <div class="container">
    <p class="h1 mb-3 mb-md-5">Estatuto</p>
    <a href="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/nosotros%2FestatutoCanaturPeru.pdf?alt=media&token=cc3cd390-6cdc-41eb-b65d-a60542bc9454" class="btn btn-lg btn-primary" target="blank">Ver Estatuto (PDF)
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-text ml-2" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z"/>
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        <line x1="9" y1="9" x2="10" y2="9" />
        <line x1="9" y1="13" x2="15" y2="13" />
        <line x1="9" y1="17" x2="15" y2="17" />
      </svg>
    </a>
</div> -->