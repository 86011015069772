import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-crear-video',
  templateUrl: './crear-video.component.html',
  styleUrls: ['./crear-video.component.scss']
})
export class CrearVideoComponent implements OnInit {

  formVideoHel!: UntypedFormGroup;
  loading = false;
  videoHelId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private router: Router,
  ) {
      this.videoHelId = this.afs.collection('videosHel').ref.doc().id;
      console.log(this.videoHelId);  
  }

  ngOnInit(): void {

    this.formVideoHel = this.fb.group({
      url: ['', Validators.required],
      urlEmbed: [''],
      id: [this.videoHelId],
      publicado: [false],
      fechaCreacion: [Timestamp.now()]
    });

    this.formVideoHel.get('url').valueChanges.subscribe( res => {
      const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
      const idVideo = regex.exec(res)[3];
      this.formVideoHel.get('urlEmbed').setValue(`https://www.youtube.com/embed/${idVideo}`)
      console.log(idVideo);
    });

  }

  onSubmit() {
    if (this.formVideoHel.valid) {
      this.loading = true;
      this.crearVideoHel();
    } else {
      this.validateAllFormFields(this.formVideoHel);
    }
  }

  crearVideoHel() {
    this.afs.doc(`videosHel/${this.videoHelId}`).set(this.formVideoHel.value)
    .then(() => {
      this.router.navigate(['/admin/helvetas/videos']);
      console.log('Video Helvetas creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorUrl() {
    return this.formVideoHel.controls.url.hasError('required') ? 'Ingrese el link video Youtube' : '';
  }

}
