<div class="container" *ngIf="noticiaHel">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/helvetas">seCompetitivo</a></li>
      <!-- <li class="breadcrumb-item"><a routerLink="/admin/helvetas/noticias">Noticias</a></li> -->
    </ol>
  </nav>

  <div class="row">
    <div class="col-md-8">
      <div class="">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <button mat-icon-button routerLink="/helvetas/noticias">
            <i-tabler name="arrow-left"></i-tabler>
          </button>
          <div class="d-flex align-items-center ">
            <span class="me-1">
              <i-tabler class="text-muted" name="calendar-event" style="width: 20px; height: auto; stroke-width: 1.2;"></i-tabler>
            </span>
            <span class="small text-muted" style="padding-top: 3px;">{{noticiaHel.fecha.toDate() | date:'mediumDate'}}</span>
          </div>
        </div>
        
        
        <p class="h2 fw-bold">{{noticiaHel.titulo}}</p>
        <p class="h5 fw-normal my-3">{{noticiaHel.subtitulo}}</p>
        <img class="imageDetalleNoticiaHel mb-3 mb-md-4" [src]="noticiaHel.image" alt="">
        <div class="bodyNoticiaHel" [innerHTML]="noticiaHel.body | safe: 'html' "></div>
        <!-- <p style="text-align: justify;">La Cámara Nacional de Turismo, CANATUR, a través del proyecto “Tarapoto y Alto Mayo, Turismo y Naturaleza en Valor”, desarrollado en el marco del Programa SeCompetitivo, iniciativa de la Cooperación Suiza SECO y facilitado por HELVETAS Perú, viene realizando un exhaustivo trabajo de georreferenciación de los atractivos turísticos de las rutas de naturaleza de Tarapoto y Alto Mayo con el objetivo de mejorar las señales de interpretación y orientación para la óptima información, orientación y promoción del destino turístico, en coordinación con la DIRCETUR, gobiernos locales, gestores y administradores de atractivos turísticos.</p> -->
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>

</div>
