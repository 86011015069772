<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/helvetas">Se Competitivo</a></li>
      <!-- <li class="breadcrumb-item"><a routerLink="/admin/helvetas/documentos">Gremios</a></li> -->
    </ol>
  </nav>


  <div class="d-flex align-items-center mb-2 mb-md-3">
    <button mat-icon-button routerLink="/admin/helvetas">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Videos</p>
  </div>


  <div class="d-flex gap-2 gap-md-3">
    <mat-form-field appearance="outline" class="w-100">
      <input matInput (keyup)="applyFilter($event)" [(ngModel)]="value" placeholder="Buscar">
      <mat-icon *ngIf="!value" matSuffix class="text-muted">search</mat-icon>
      <button mat-button *ngIf="value" matSuffix mat-icon-button (click)="clearFilters()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="flex-shrink-0">
      <a class="btn btn-primary d-inline-flex align-items-center" style="margin-top: 4px; height: 59.5px" routerLink="/admin/helvetas/videos/crearVideo">
        <div class="d-flex">
          <i-tabler name="plus"></i-tabler>
          <span class="ms-1 d-none d-md-inline-block">Añadir</span>
        </div>
      </a>
    </div>
  </div>



  <div class="table-responsive">

    <table mat-table [dataSource]="videosHelData" matSort [trackBy]="trackByPublicado">

      <ng-container matColumnDef="miniatura">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let item">
          <div class="ratio ratio-16x9 videoMiniaturaAdmin my-2 my-md-3">
            <iframe class="" width="100%" height="auto" [src]="item.urlEmbed | sanitizeUrl"
              allowfullscreen></iframe>
          </div>
        </td> 
      </ng-container>

      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Link</th>
        <td mat-cell *matCellDef="let item">
          <a [href]="item.url">{{item.url}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="destacado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Destacado</th>
        <td mat-cell *matCellDef="let noticia">
          <!-- <mat-slide-toggle color="primary" #toggle [ngModel]="noticia.publicado" (ngModelChange)="actualizarPublicado(noticia.id, noticia, $event)">
                  </mat-slide-toggle> -->
          <mat-checkbox #toggle2 [ngModel]="noticia.destacado" (ngModelChange)="actualizarDestacado(noticia.id, $event)"></mat-checkbox>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="categoria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría</th>
        <td mat-cell *matCellDef="let item"> {{item.categoria}} </td>
      </ng-container>  

      <ng-container matColumnDef="publicado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Publicado</th>
        <td mat-cell *matCellDef="let item">
          <mat-slide-toggle color="primary" #toggle [checked]="item.publicado"
          (change)="actualizarPublicado(item.id, $event)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="editar">
        <th mat-header-cell *matHeaderCellDef>Editar</th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="editarItem(item)">
            <i-tabler name="pencil"></i-tabler>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="eliminar">
        <th mat-header-cell *matHeaderCellDef>Eliminar</th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="eliminarItem(item)">
            <i-tabler name="trash"></i-tabler>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="mas">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" class="text-end">

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let option of opciones" (click)="option.function(item)">
              <i-tabler [name]="option.icon"></i-tabler>
              <span class="ms-2">{{option.nombre}}</span>
            </button>
          </mat-menu>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


  <mat-paginator [pageSize]="50" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
  </mat-paginator>

</div>