import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Español Angular
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeFrExtra from '@angular/common/locales/extra/es';
registerLocaleData( localeEs, 'es-ES');

// Angularfire2
// import { environment } from '../environments/environment';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule, provideAuth,getAuth } from '@angular/fire/auth';
// import { AngularFirestoreModule, provideFirestore,getFirestore  } from '@angular/fire/firestore';
// import { AngularFireStorageModule, provideStorage,getStorage } from '@angular/fire/storage';
// import { AngularFireAnalyticsModule, provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';

import { MaterialFileInputModule } from 'ngx-material-file-input';

// Swiper Angular 8
// import { SwiperModule } from 'ngx-swiper-wrapper';
// import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };

import { SwiperModule } from 'swiper/angular';


import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

import { EditorModule } from '@tinymce/tinymce-angular';


import { DragDropModule } from '@angular/cdk/drag-drop';



// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicComponent } from './components/public/public.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { FooterComponent } from './components/footer/footer.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { ExpoTurismoComponent } from './components/expo-turismo/expo-turismo.component';
import { AsociadosComponent } from './components/asociados/asociados.component';
import { GremiosComponent } from './components/gremios/gremios.component';
import { CamarasRegionalesComponent } from './components/camaras-regionales/camaras-regionales.component';
import { EmpresasAsociadasComponent } from './components/empresas-asociadas/empresas-asociadas.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { UneteComponent } from './components/unete/unete.component';
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';
import { PrensaComponent } from './components/prensa/prensa.component';
import { SuscripcionComponent } from './components/suscripcion/suscripcion.component';
import { CrearEmpresaAsociadaComponent } from './components/asociados/crear-empresa-asociada/crear-empresa-asociada.component';
import { CardEmpresaAsociadaComponent } from './components/card-empresa-asociada/card-empresa-asociada.component';
import { EstatutosComponent } from './components/estatutos/estatutos.component';
import { AlianzasComponent } from './components/alianzas/alianzas.component';
import { AdminComponent } from './components/admin/admin.component';
import { CrearNoticiaComponent } from './components/admin/noticias/crear-noticia/crear-noticia.component';
import { EditarNoticiaComponent } from './components/admin/noticias/editar-noticia/editar-noticia.component';
import { EliminarNoticiaComponent } from './components/admin/noticias/eliminar-noticia/eliminar-noticia.component';
import { AdminNoticiasComponent } from './components/admin/noticias/admin-noticias/admin-noticias.component';
import { NoticiaDetalleAdminComponent } from './components/admin/noticias/noticia-detalle-admin/noticia-detalle-admin.component';
import { CardNoticiaComponent } from './components/card-noticia/card-noticia.component';
import { CardSeccionComponent } from './components/card-seccion/card-seccion.component';
import { AfiliacionesCanaturComponent } from './components/admin/afiliaciones-canatur/afiliaciones-canatur.component';
import { ListaAfiliacionesCanaturComponent } from './components/admin/afiliaciones-canatur/lista-afiliaciones-canatur/lista-afiliaciones-canatur.component';
import { HeaderAdminComponent } from './components/admin/header-admin/header-admin.component';
import { InicioAdminComponent } from './components/admin/inicio-admin/inicio-admin.component';
import { LoginComponent } from './components/login/login.component';
import { SuscripcionesNoticiasComponent } from './components/admin/suscripciones-noticias/suscripciones-noticias.component';
import { ListaSuscripcionesNoticiasComponent } from './components/admin/suscripciones-noticias/lista-suscripciones-noticias/lista-suscripciones-noticias.component';
import { GraciasUneteComponent } from './components/gracias-unete/gracias-unete.component';
import { ModalInicioComponent } from './components/modal-inicio/modal-inicio.component';
import { HelvetasComponent } from './components/helvetas/helvetas.component';
import { LeerNoticiaComponent } from './components/admin/noticias/leer-noticia/leer-noticia.component';
import { ListaAsociadosComponent } from './components/admin/asociados/lista-asociados/lista-asociados.component';
import { EliminarAsociadoComponent } from './components/admin/asociados/eliminar-asociado/eliminar-asociado.component';
import { XvcntComponent } from './components/xvcnt/xvcnt.component';
import { CrearGremioComponent } from './components/admin/asociados/gremios/crear-gremio/crear-gremio.component';
import { ListaGremiosComponent } from './components/admin/asociados/gremios/lista-gremios/lista-gremios.component';
import { EditarGremioComponent } from './components/admin/asociados/gremios/editar-gremio/editar-gremio.component';
import { EliminarGremioComponent } from './components/admin/asociados/gremios/eliminar-gremio/eliminar-gremio.component';
import { CrearEmpresaComponent } from './components/admin/asociados/empresas/crear-empresa/crear-empresa.component';
import { EditarEmpresaComponent } from './components/admin/asociados/empresas/editar-empresa/editar-empresa.component';
import { EliminarEmpresaComponent } from './components/admin/asociados/empresas/eliminar-empresa/eliminar-empresa.component';
import { ListaEmpresasComponent } from './components/admin/asociados/empresas/lista-empresas/lista-empresas.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { CardEmpresaComponent } from './components/card-empresa/card-empresa.component';
import { CrearDocumentoComponent } from './components/admin/helvetas/documentos/crear-documento/crear-documento.component';
import { EliminarDocumentoComponent } from './components/admin/helvetas/documentos/eliminar-documento/eliminar-documento.component';
import { ListaDocumentosComponent } from './components/admin/helvetas/documentos/lista-documentos/lista-documentos.component';
import { CrearNoticiaHelComponent } from './components/admin/helvetas/noticias/crear-noticia-hel/crear-noticia-hel.component';
import { DetalleNoticiaHelComponent } from './components/detalle-noticia-hel/detalle-noticia-hel.component';
import { EliminarNoticiaHelComponent } from './components/admin/helvetas/noticias/eliminar-noticia-hel/eliminar-noticia-hel.component';
import { ListaNoticiasHelComponent } from './components/admin/helvetas/noticias/lista-noticias-hel/lista-noticias-hel.component';
import { CrearVideoComponent } from './components/admin/helvetas/videos/crear-video/crear-video.component';
import { EliminarVideoComponent } from './components/admin/helvetas/videos/eliminar-video/eliminar-video.component';
import { ListaVideosComponent } from './components/admin/helvetas/videos/lista-videos/lista-videos.component';
import { CrearBannerComponent } from './components/admin/helvetas/banners/crear-banner/crear-banner.component';
import { EliminarBannerComponent } from './components/admin/helvetas/banners/eliminar-banner/eliminar-banner.component';
import { ListaBannersComponent } from './components/admin/helvetas/banners/lista-banners/lista-banners.component';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { environment } from '../environments/environment';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { HelvetasAdminComponent } from './components/admin/helvetas/helvetas-admin/helvetas-admin.component';
import { EditarNoticiaHelComponent } from './components/admin/helvetas/noticias/editar-noticia-hel/editar-noticia-hel.component';
import { FooterAdminComponent } from './components/admin/footer-admin/footer-admin.component';
import { CrearBannerFtpComponent } from './components/admin/ftp/bannersFtp/crear-banner-ftp/crear-banner-ftp.component';
import { EditarBannerFtpComponent } from './components/admin/ftp/bannersFtp/editar-banner-ftp/editar-banner-ftp.component';
import { EliminarBannerFtpComponent } from './components/admin/ftp/bannersFtp/eliminar-banner-ftp/eliminar-banner-ftp.component';
import { ListaBannersFtpComponent } from './components/admin/ftp/bannersFtp/lista-banners-ftp/lista-banners-ftp.component';
import { BannersFtpComponent } from './components/banners-ftp/banners-ftp.component';
import { BloqueNoticiasHelComponent } from './components/helvetas/bloque-noticias-hel/bloque-noticias-hel.component';
import { EditarDocumentoComponent } from './components/admin/helvetas/documentos/editar-documento/editar-documento.component';
import { BloqueDocumentosHelComponent } from './components/helvetas/bloque-documentos-hel/bloque-documentos-hel.component';
import { FtpComponent } from './components/ftp/ftp.component';
import { EditarVideoComponent } from './components/admin/helvetas/videos/editar-video/editar-video.component';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { BloqueVideosHelComponent } from './components/helvetas/bloque-videos-hel/bloque-videos-hel.component';
import { EditarBannerComponent } from './components/admin/helvetas/banners/editar-banner/editar-banner.component';
import { BloqueBannersComponent } from './components/helvetas/bloque-banners/bloque-banners.component';
import { BloqueCarouselComponent } from './components/helvetas/bloque-carousel/bloque-carousel.component';
import { HelvetasNoticiasComponent } from './components/helvetas-noticias/helvetas-noticias.component';
import { CrearBoletinComponent } from './components/admin/boletines/crear-boletin/crear-boletin.component';
import { EditarBoletinComponent } from './components/admin/boletines/editar-boletin/editar-boletin.component';
import { EliminarBoletinComponent } from './components/admin/boletines/eliminar-boletin/eliminar-boletin.component';
import { ListaBoletinesComponent } from './components/admin/boletines/lista-boletines/lista-boletines.component';
import { BoletinesComponent } from './components/boletines/boletines.component';
import { SafePipe } from './pipes/safe.pipe';
import { PoliticaAplicativoMovilComponent } from './components/politica-aplicativo-movil/politica-aplicativo-movil.component';
import { CrearQueHacemosComponent } from './components/admin/nosotros-admin/que-hacemos/crear-que-hacemos/crear-que-hacemos.component';
import { EditarQueHacemosComponent } from './components/admin/nosotros-admin/que-hacemos/editar-que-hacemos/editar-que-hacemos.component';
import { EliminarQueHacemosComponent } from './components/admin/nosotros-admin/que-hacemos/eliminar-que-hacemos/eliminar-que-hacemos.component';
import { ListaQueHacemosComponent } from './components/admin/nosotros-admin/que-hacemos/lista-que-hacemos/lista-que-hacemos.component';
import { ListaDirectivosComponent } from './components/admin/nosotros-admin/junta-directiva/lista-directivos/lista-directivos.component';
import { CrearDirectivoComponent } from './components/admin/nosotros-admin/junta-directiva/crear-directivo/crear-directivo.component';
import { EliminarDirectivoComponent } from './components/admin/nosotros-admin/junta-directiva/eliminar-directivo/eliminar-directivo.component';
import { EditarDirectivoComponent } from './components/admin/nosotros-admin/junta-directiva/editar-directivo/editar-directivo.component';
import { NosotrosAdminComponent } from './components/admin/nosotros-admin/nosotros-admin.component';
import { PeriodoJuntaDirectivaComponent } from './components/admin/nosotros-admin/junta-directiva/periodo-junta-directiva/periodo-junta-directiva.component';
import { BuscarComponent } from './components/admin/buscar/buscar.component';
import { HeaderSectionComponent } from './components/admin/header-section/header-section.component';
import { CrearCamaraRegionalComponent } from './components/admin/asociados/camaras-regionales/crear-camara-regional/crear-camara-regional.component';
import { EliminarCamaraRegionalComponent } from './components/admin/asociados/camaras-regionales/eliminar-camara-regional/eliminar-camara-regional.component';
import { ListaCamarasRegionalesComponent } from './components/admin/asociados/camaras-regionales/lista-camaras-regionales/lista-camaras-regionales.component';
import { EditarCamaraRegionalComponent } from './components/admin/asociados/camaras-regionales/editar-camara-regional/editar-camara-regional.component';
import { EliminarItemComponent } from './components/admin/eliminar-item/eliminar-item.component';
import { CrearEventoComponent } from './components/admin/eventos/crear-evento/crear-evento.component';
import { EditarEventoComponent } from './components/admin/eventos/editar-evento/editar-evento.component';
import { ListaEventosComponent } from './components/admin/eventos/lista-eventos/lista-eventos.component';
import { ItemListaComponent } from './components/admin/item-lista/item-lista.component';
import { UploadImagesComponent } from './components/admin/upload-images/upload-images.component';
import { CrearAlianzaComponent } from './components/admin/alianzas/crear-alianza/crear-alianza.component';
import { EliminarAlianzaComponent } from './components/admin/alianzas/eliminar-alianza/eliminar-alianza.component';
import { EditarAlianzaComponent } from './components/admin/alianzas/editar-alianza/editar-alianza.component';
import { ListaAlianzasComponent } from './components/admin/alianzas/lista-alianzas/lista-alianzas.component';
import { UploadFileComponent } from './components/admin/upload-file/upload-file.component';
import { CrearEstatutoComponent } from './components/admin/estatutos/crear-estatuto/crear-estatuto.component';
import { EditarEstatutoComponent } from './components/admin/estatutos/editar-estatuto/editar-estatuto.component';
import { ListaEstatutosComponent } from './components/admin/estatutos/lista-estatutos/lista-estatutos.component';
import { CategoriasEmpresasComponent } from './components/admin/asociados/empresas/categorias-empresas/categorias-empresas.component';
import { CrearCategoriaComponent } from './components/admin/asociados/empresas/crear-categoria/crear-categoria.component';
import { VxicntComponent } from './components/vxicnt/vxicnt.component';
import { SetEmpresaComponent } from './components/admin/asociados/empresas/set-empresa/set-empresa.component';


@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    HeaderComponent,
    HeaderMobileComponent,
    FooterComponent,
    InicioComponent,
    NosotrosComponent,
    ExpoTurismoComponent,
    AsociadosComponent,
    GremiosComponent,
    CamarasRegionalesComponent,
    EmpresasAsociadasComponent,
    MarketplaceComponent,
    UneteComponent,
    MenuMobileComponent,
    PrensaComponent,
    SuscripcionComponent,
    CrearEmpresaAsociadaComponent,
    CardEmpresaAsociadaComponent,
    EstatutosComponent,
    AlianzasComponent,
    AdminComponent,
    CrearNoticiaComponent,
    EditarNoticiaComponent,
    EliminarNoticiaComponent,
    AdminNoticiasComponent,
    NoticiaDetalleAdminComponent,
    CardNoticiaComponent,
    CardSeccionComponent,
    AfiliacionesCanaturComponent,
    ListaAfiliacionesCanaturComponent,
    HeaderAdminComponent,
    InicioAdminComponent,
    LoginComponent,
    SuscripcionesNoticiasComponent,
    ListaSuscripcionesNoticiasComponent,
    GraciasUneteComponent,
    ModalInicioComponent,
    HelvetasComponent,
    LeerNoticiaComponent,
    ListaAsociadosComponent,
    EliminarAsociadoComponent,
    XvcntComponent,
    CrearGremioComponent,
    ListaGremiosComponent,
    EditarGremioComponent,
    EliminarGremioComponent,
    CrearEmpresaComponent,
    EditarEmpresaComponent,
    EliminarEmpresaComponent,
    ListaEmpresasComponent,
    EmpresasComponent,
    CardEmpresaComponent,
    CrearDocumentoComponent,
    EliminarDocumentoComponent,
    ListaDocumentosComponent,
    CrearNoticiaHelComponent,
    DetalleNoticiaHelComponent,
    EliminarNoticiaHelComponent,
    ListaNoticiasHelComponent,
    CrearVideoComponent,
    EliminarVideoComponent,
    ListaVideosComponent,
    CrearBannerComponent,
    EliminarBannerComponent,
    ListaBannersComponent,
    HelvetasAdminComponent,
    EditarNoticiaHelComponent,
    FooterAdminComponent,
    CrearBannerFtpComponent,
    EditarBannerFtpComponent,
    EliminarBannerFtpComponent,
    ListaBannersFtpComponent,
    BannersFtpComponent,
    BloqueNoticiasHelComponent,
    EditarDocumentoComponent,
    BloqueDocumentosHelComponent,
    FtpComponent,
    EditarVideoComponent,
    SanitizeUrlPipe,
    BloqueVideosHelComponent,
    EditarBannerComponent,
    BloqueBannersComponent,
    BloqueCarouselComponent,
    HelvetasNoticiasComponent,
    CrearBoletinComponent,
    EditarBoletinComponent,
    EliminarBoletinComponent,
    ListaBoletinesComponent,
    BoletinesComponent,
    SafePipe,
    PoliticaAplicativoMovilComponent,
    CrearQueHacemosComponent,
    EditarQueHacemosComponent,
    EliminarQueHacemosComponent,
    ListaQueHacemosComponent,
    ListaDirectivosComponent,
    CrearDirectivoComponent,
    EliminarDirectivoComponent,
    EditarDirectivoComponent,
    NosotrosAdminComponent,
    PeriodoJuntaDirectivaComponent,
    BuscarComponent,
    HeaderSectionComponent,
    CrearCamaraRegionalComponent,
    EliminarCamaraRegionalComponent,
    ListaCamarasRegionalesComponent,
    EditarCamaraRegionalComponent,
    EliminarItemComponent,
    CrearEventoComponent,
    EditarEventoComponent,
    ListaEventosComponent,
    ItemListaComponent,
    UploadImagesComponent,
    CrearAlianzaComponent,
    EliminarAlianzaComponent,
    EditarAlianzaComponent,
    ListaAlianzasComponent,
    UploadFileComponent,
    CrearEstatutoComponent,
    EditarEstatutoComponent,
    ListaEstatutosComponent,
    CategoriasEmpresasComponent,
    CrearCategoriaComponent,
    VxicntComponent,
    SetEmpresaComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatIconModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTabsModule,
    MatToolbarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatExpansionModule,
    MatTooltipModule,
    MatBadgeModule,
    MaterialFileInputModule,
    SwiperModule,
    TablerIconsModule.pick(TablerIcons),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => getStorage()),
    EditorModule,
    DragDropModule
  ],
  exports: [
    TablerIconsModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
   { provide: LOCALE_ID, useValue: 'es-ES' },
   ScreenTrackingService, UserTrackingService,
   { provide: FIREBASE_OPTIONS, useValue: environment.firebase }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
