import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { Directivo } from 'src/app/classes/directivo';

@Component({
  selector: 'app-editar-directivo',
  templateUrl: './editar-directivo.component.html',
  styleUrls: ['./editar-directivo.component.scss']
})
export class EditarDirectivoComponent implements OnInit {

  formDirectivo!: FormGroup;
  loading = false;
  directivoId: string;
  item: Directivo;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  today = new Date();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
      // this.directivoId = this.afs.collection('juntaDirectiva').doc('zU4clnyjs8I4N6Pu1B7b').collection('directivos').doc().ref.id;
      // console.log(this.directivoId);
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {

      this.directivoId = res.id;

      this.afs.doc(`juntaDirectiva/zU4clnyjs8I4N6Pu1B7b/directivos/${this.directivoId}`).valueChanges().subscribe( (data: Directivo) => {

        this.item = data;

        // this.formQueHacemos = this.fb.group({
        //   titulo: [this.item.titulo, Validators.required],
        //   descripcion: [this.item.descripcion, Validators.required],
        //   image: ['', [FileValidator.maxContentSize(this.maxSize)]],
        //   imageName: [''],
        //   publicado: [this.item.publicado],
        //   fechaEdicion: [Timestamp.now()]
        // });

        this.formDirectivo = this.fb.group({
          nombre: [this.item.nombre, Validators.required],
          cargo: [this.item.cargo, Validators.required],
          image: ['', [FileValidator.maxContentSize(this.maxSize)]],
          imageName: [''],
          publicado: [this.item.publicado],
          fechaEdicion: [Timestamp.now()]
        });

      });

    });

    // this.formDirectivo = this.fb.group({
    //   id: [this.directivoId],
    //   nombre: ['', Validators.required],
    //   cargo: ['', Validators.required],
    //   image: ['', [FileValidator.maxContentSize(this.maxSize)]],
    //   imageName: [''],
    //   publicado: [false],
    //   order: [1],
    //   fechaCreacion: [Timestamp.now()]
    // });

  }

  onSubmit() {
    if (this.formDirectivo.valid) {
      this.loading = true;
      if (this.formDirectivo.get('image')?.value === '') {
        this.actualizarDirectivo();
      } else {
        this.uploadImageAndUpdateDirectivo();
      }
    } else {
      this.validateAllFormFields(this.formDirectivo);
    }
  }

  // crearDirectivo() {
  //   this.afs.doc(`juntaDirectiva/zU4clnyjs8I4N6Pu1B7b/directivos/${this.directivoId}`).set(this.formDirectivo.value)
  //   .then(() => {
  //     this.router.navigate(['/admin/nosotros/juntaDirectiva']);
  //     console.log('Directivo actualizado');
  //   });
  // }

  actualizarDirectivo() {
    const directivo = this.formDirectivo.value;
    ['image', 'imageName'].forEach(e => delete directivo[e]);

    this.afs.doc(`juntaDirectiva/zU4clnyjs8I4N6Pu1B7b/directivos/${this.directivoId}`).update(this.formDirectivo.value)
    .then(() => {
      this.router.navigate(['/admin/nosotros/juntaDirectiva']);
      console.log('Directivo actualizado');
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  

  uploadImageAndUpdateDirectivo() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `imagesJuntaDirectiva/${this.directivoId}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`juntaDirectiva/zU4clnyjs8I4N6Pu1B7b/directivos/${this.directivoId}`).update({
          ...this.formDirectivo.value,
          image: url,
          imageName: `${this.directivoId}.${nombreImage[1]}`
        });
        this.router.navigate(['/admin/nosotros/juntaDirectiva']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  errorImagen() {
    return this.formDirectivo.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

  errorBody() {
    return this.formDirectivo.controls.body.touched && this.formDirectivo.controls.body.hasError('required') ? 'Incluye una descripción de la noticia' : '';
  }

}
