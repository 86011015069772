import { Component, OnInit } from '@angular/core';
import { NoticiaHel } from 'src/app/classes/noticia-hel';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-helvetas-noticias',
  templateUrl: './helvetas-noticias.component.html',
  styleUrls: ['./helvetas-noticias.component.scss']
})
export class HelvetasNoticiasComponent implements OnInit {

  noticiasHel: NoticiaHel[] = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getNoticiasHel().subscribe( res => {
      this.noticiasHel = res;
    });
  }

}
