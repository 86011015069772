import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio-admin',
  templateUrl: './inicio-admin.component.html',
  styleUrls: ['./inicio-admin.component.scss']
})
export class InicioAdminComponent implements OnInit {

  items = [
    {
      nombre: 'Estatutos',
      url: '/admin/estatutos'
    },
    {
      nombre: 'Asociados',
      url: '/admin/asociados'
    },
    {
      nombre: 'Eventos',
      url: '/admin/eventos'
    },
    {
      nombre: 'Alianzas',
      url: '/admin/alianzas'
    },
    {
      nombre: 'Se Competitivo',
      url: '/admin/helvetas'
    },
    {
      nombre: 'Boletines',
      url: '/admin/boletines'
    },
    {
      nombre: 'Nosotros',
      url: '/admin/nosotros'
    },
    {
      nombre: 'Noticias',
      url: '/admin/noticias'
    },
    {
      nombre: 'Solicitud de afiliaciones a Canatur',
      url: '/admin/afiliacionesCanatur',
    },
    {
      nombre: 'Solicitud suscripción de noticias',
      url: '/admin/suscripcionesNoticias'
    },
   
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
