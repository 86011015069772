// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'canatur-1afd6',
    appId: '1:557881701614:web:3ef797fd3bd8d618e52fff',
    databaseURL: 'https://canatur-1afd6.firebaseio.com',
    storageBucket: 'canatur-1afd6.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAoZ4Rfa2Nw6lHiyvBB1xaJCasJtd5OUDA',
    authDomain: 'canatur-1afd6.firebaseapp.com',
    messagingSenderId: '557881701614',
    measurementId: 'G-FBKMY5PFNJ',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
