<div class="mb-3 mb-md-4">



  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item" *ngFor="let item of seccion.breadCrumb">
        <a [routerLink]="item.url">{{item.nombre}}</a>
      </li>
    </ol>
  </nav>



  <div class="d-flex justify-content-between align-items-center">

    <div class="d-flex align-items-center gap-2">
      <a [routerLink]="seccion.back">
        <i-tabler name="arrow-left" class="me-1"></i-tabler>
      </a>
      <p class="h3 mb-0">{{seccion.titulo}}</p>
    </div>

    <a class="btn btn-primary" [routerLink]="seccion.urlButton" *ngIf="seccion.urlButton">
      <div class="d-flex">
        <i-tabler name="plus"></i-tabler>
        <span class="ms-1 d-none d-md-inline-block">Agregar</span>
      </div>
    </a>

  </div>



</div>





<!-- <div class="d-flex gap-2 gap-md-3">
  <mat-form-field appearance="outline" class="w-100">
    <input matInput (keyup)="applyFilter($event)" [(ngModel)]="value" placeholder="Buscar">
    <mat-icon *ngIf="!value" matSuffix class="text-muted">search</mat-icon>
    <button mat-button *ngIf="value" matSuffix mat-icon-button (click)="clearFilters()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div class="flex-shrink-0">
    <a class="btn btn-primary d-inline-flex align-items-center" style="margin-top: 4px; height: 59.5px" [routerLink]="seccion.urlButton">
      <div class="d-flex">
        <i-tabler name="plus"></i-tabler>
        <span class="ms-1 d-none d-md-inline-block">Agregar</span>
      </div>
    </a>
  </div>
</div> -->