import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afiliaciones-canatur',
  templateUrl: './afiliaciones-canatur.component.html',
  styleUrls: ['./afiliaciones-canatur.component.scss']
})
export class AfiliacionesCanaturComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
