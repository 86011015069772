import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FileItem } from '../classes/file-item';

@Injectable({
  providedIn: 'root'
})
export class UploadImagesService {

  loading!: boolean;
  resultados: any[] = [];
  imageId!: string;

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) { }

  uploadFilesItem( fotos: FileItem[], data: any, sizeCollection: number ) {

    this.loading = true;

    // Guardar en Storage
    const promises = fotos.map( (image: any, i: number) => {

      this.imageId = this.afs.collection(`${data.route}/images`).doc().ref.id;

      const formatImage = image.nameArchivo.split('.').pop()
      const newNameArchivo = `${this.imageId}.${formatImage}`;

      const imageToServer = this.storage.ref(`${data.route}/${newNameArchivo}`).put(image.archivo, {
        customMetadata: {
          name: image.nameArchivo,
          type: image.typeArchivo,
          size: image.sizeArchivo.toString(),
        }
      });

      imageToServer.percentageChanges().subscribe( (res) => {
        image.progreso = res;
        console.log(res);
      });

      // console.log(this.uploadPercent);

      return imageToServer.then( (uploadTaskSnapshot: any) => {

        // const nameImage = image.nameArchivo;
        const nameImage = newNameArchivo;
        
        return uploadTaskSnapshot.ref.getDownloadURL()
        .then( (url: any) => {
          console.log(url, nameImage);
          return { url, nameImage }
        });
      });
    })

    

    // Guardar en Firestore
    Promise.all(promises)
    .then( (response: any) => {

      console.log(response);

      response.map( (element: any, i: number) => {

        // const refImage = this.afs.collection('negocios').doc(negocioId).collection('items').doc(proyectoId).collection('images').doc().ref.id;

        const imageComplete = {
          urlImage: element.url,
          nameImage: element.nameImage,
          fechaCreacion: new Date(),
          destacado: false,
          order: sizeCollection + i + 1,
          publicado: true,
          id: element.nameImage.split('.').shift(),
          rows: 2,
          cols: 2,
        }
        this.resultados.push(imageComplete);
      });

      console.log(this.resultados);

      this.resultados.map( element => {
        // this.afs.collection('negocios').doc(negocioId).collection('items').doc(proyectoId).collection('images').doc(element.id).set(element)
        this.afs.collection(`${data.route}/images`).doc(element.id).set(element);
      });

      // set/update data Firestore
      this.afs.doc(`${data.route}`).set({
        fechaCreacion: new Date(),
        id: data.id,
        image: this.resultados[0].urlImage,
        order: 1
      }, {merge: true});
     
    })
    .catch( error => {
      console.log(error);
    });

  }

}
