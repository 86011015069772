import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { CrearNoticiaComponent } from '../crear-noticia/crear-noticia.component';
import { EditarNoticiaComponent } from '../editar-noticia/editar-noticia.component';
import { EliminarNoticiaComponent } from '../eliminar-noticia/eliminar-noticia.component';
import { NoticiaDetalleAdminComponent } from '../noticia-detalle-admin/noticia-detalle-admin.component';

@Component({
  selector: 'app-admin-noticias',
  templateUrl: './admin-noticias.component.html',
  styleUrls: ['./admin-noticias.component.scss']
})
export class AdminNoticiasComponent implements OnInit {

  count: number;
  loading: boolean;
  displayedColumns = [ 'imagen', 'id', 'titulo', 'fecha', 'publicado', 'destacado', 'mas' ];
  noticiasData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.noticiasData.filter = filterValue;
  }


  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog
  ) { }


  ngOnInit(): void {
    this.fs.getAllNoticias().subscribe(data => {
      this.noticiasData.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.noticiasData.paginator = this.paginator;
    this.noticiasData.sort = this.sort;
  }

  openModalCrear() {
    const dialogRef = this.dialog.open(CrearNoticiaComponent, {
      panelClass: 'modalFullScreen',
    });
    dialogRef.afterClosed().subscribe();
  }

  eliminarAviso(idProduct) {
    this.fs.deleteNoticia(idProduct);
  }

  openModalDetalle(producto) {
    const dialogRef = this.dialog.open(NoticiaDetalleAdminComponent, {
      panelClass: 'modalFullScreen',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  openModalDelete(producto) {
    const dialogRef = this.dialog.open(EliminarNoticiaComponent, {
      panelClass: 'dialogDeleteItem',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  openModalEdit(producto) {
    const dialogRef = this.dialog.open(EditarNoticiaComponent, {
      panelClass: 'modalFullScreen',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.noticiasData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  actualizarPublicado(key, obj, e) {
    this.fs.updatePublicado(key, e);
  }

  actualizarDestacado(key, obj, e) {
    this.fs.updateDestacado(key, e);
  }

  trackByPublicado(item) {
    return item.publicado;
  }


}
