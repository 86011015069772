import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Boletin } from 'src/app/classes/boletin';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarBoletinComponent } from '../eliminar-boletin/eliminar-boletin.component';

@Component({
  selector: 'app-lista-boletines',
  templateUrl: './lista-boletines.component.html',
  styleUrls: ['./lista-boletines.component.scss']
})
export class ListaBoletinesComponent implements OnInit {

  loading!: boolean;
  displayedColumns = [ 'archivo', 'titulo', 'publicado', 'editar', 'mas' ];
  boletinesData = new MatTableDataSource();
  value: any;
  iconItem: any;
  iconUrl: string;

  opciones = [
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (boletin: Boletin) => this.eliminarItem(boletin)
    }
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.boletinesData.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.fs.getAllBoletines().subscribe((data: Boletin[]) => {
      this.boletinesData.data = data;
      this.loading = false;
    });
    this.boletinesData.paginator = this.paginator;
    this.boletinesData.sort = this.sort;
  }

  clearFilters() {
    this.boletinesData.filter = '';
    this.value = '';
  }

  ngAfterViewInit() {
    this.boletinesData.paginator = this.paginator;
  }

  readTypeFile(typeFile) {
    this.iconItem = this.ds.iconsTypeFiles.find( find => typeFile === find.nombre);
    this.iconUrl = this.iconItem.icon;
    return this.iconUrl
  }

  actualizarPublicado(idboletin: string, change: MatSlideToggleChange) {
    this.afs.collection('boletines').doc(idboletin).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item: any) {
    return item.publicado;
  }

  editarItem(boletin: Boletin) {
    this.router.navigate([`admin/boletines/editarBoletin/${boletin.id}`]);
  }

  eliminarItem(boletin: Boletin) {
    this.matDialog.open(EliminarBoletinComponent, {
      panelClass: 'dialogSmall',
      data: boletin
    });
  }

}
