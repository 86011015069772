import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DocumentoHel } from 'src/app/classes/documento-hel';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarDocumentoComponent } from '../eliminar-documento/eliminar-documento.component';

@Component({
  selector: 'app-lista-documentos',
  templateUrl: './lista-documentos.component.html',
  styleUrls: ['./lista-documentos.component.scss']
})
export class ListaDocumentosComponent implements OnInit {

  loading!: boolean;
  displayedColumns = [ 'archivo', 'nombre', 'publicado', 'editar', 'mas' ];
  documentosHelData = new MatTableDataSource();
  value: any;
  iconItem: any;
  iconUrl: string;

  opciones = [
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (documentoHel: DocumentoHel) => this.eliminarItem(documentoHel)
    }
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.documentosHelData.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.fs.getAllDocumentosHel().subscribe((data: DocumentoHel[]) => {
      this.documentosHelData.data = data;
      this.loading = false;
    });
    this.documentosHelData.paginator = this.paginator;
    this.documentosHelData.sort = this.sort;
  }

  clearFilters() {
    this.documentosHelData.filter = '';
    this.value = '';
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.documentosHelData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  // actualizarPublicado(key, obj, e) {
  //   this.fs.updatePublicadoGremio(key, e);
  // }

  readTypeFile(typeFile) {
    this.iconItem = this.ds.iconsTypeFiles.find( find => typeFile === find.nombre);
    this.iconUrl = this.iconItem.icon;
    return this.iconUrl
  }

  actualizarPublicado(idDocumentoHel: string, change: MatSlideToggleChange) {
    this.afs.collection('documentosHel').doc(idDocumentoHel).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item: any) {
    return item.publicado;
  }

  editarItem(documentoHel: DocumentoHel) {
    this.router.navigate([`admin/helvetas/documentos/editarDocumento/${documentoHel.id}`]);
  }

  eliminarItem(documentoHel: DocumentoHel) {
    this.matDialog.open(EliminarDocumentoComponent, {
      panelClass: 'dialogSmall',
      data: documentoHel
    });
  }

}
