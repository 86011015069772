import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentData, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { merge, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Gremio } from 'src/app/classes/gremio';

@Component({
  selector: 'app-editar-gremio',
  templateUrl: './editar-gremio.component.html',
  styleUrls: ['./editar-gremio.component.scss']
})
export class EditarGremioComponent implements OnInit {

  formGremio: UntypedFormGroup;
  loading = false;
  loadingLogo = true;
  idGremio: string;
  gremio: Gremio;
  pathGremio: any;

  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  readonly maxSize = 1048576 * 5;
  actualSize: any;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
      
  }

  ngOnInit(): void {

    this.idGremio = this.activatedRoute.snapshot.params.id;
    console.log(this.idGremio);

    this.pathGremio = this.afs.collection('gremios').doc(this.idGremio);
    
    this.pathGremio.valueChanges().subscribe( (res: Gremio) => {
      this.gremio = res;

      this.formGremio = this.fb.group({
        nombre: [this.gremio.nombre, Validators.required],
        descripcion: [this.gremio.descripcion, Validators.required],
        web: [this.gremio.web],
        imageLogo: ['', [FileValidator.maxContentSize(this.maxSize)]],
        imageLogoName: [''],
        publicado: [false],
        fechaCreacion: [Timestamp.now()]
      });

    });

    
 
  }

  onSubmit() {
    if (this.formGremio.valid) {
      this.loading = true;
      if (this.formGremio.get('imageLogo').value === '') {
        this.guardarCambios();
      } else {
        this.uploadLogoAndCreateGremio();
      }
    } else {
      this.validateAllFormFields(this.formGremio)
    }
  }

  guardarCambios() {
    const gremio = this.formGremio.value;
    ['imageLogo', 'imageLogoName'].forEach(e => delete gremio[e]);
    this.pathGremio.update(this.formGremio.value)
    .then(() => {
      this.router.navigate(['/admin/asociados/gremios']);
      console.log('Gremio actualizado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadLogoAndCreateGremio() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `logosGremios/${this.idGremio}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe( (url) => {

          this.deletePreviewImage();
          const objectGremio = this.formGremio.value;
          const imageLogo = url;
          const imageLogoName = `${this.idGremio}.${nombreImage[1]}`;
          const newGremio = { ...objectGremio, imageLogo, imageLogoName };
          this.pathGremio.update(newGremio);

          this.router.navigate(['/admin/asociados/gremios'])
          console.log(url);
        })
      })
    )
    .subscribe();
  }

  deletePreviewImage() {
    if (this.gremio.imageLogoName === '' ) {
      console.log('no hay logo'); 
    } else {
      this.storage.ref(`logosGremios/${this.gremio.imageLogoName}`).delete();
    }
  }

  detectarCargado() {
    this.loadingLogo = false;
  }

  errorImagen() {
    return this.formGremio.controls.imageLogo.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

}
