<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/boletines/">Boletines</a></li>
    </ol>
  </nav>


  <div class="row gy-4 gx-md-8">

    <div class="col-md-4">
      <p class="h2">Boletines</p>
      <p class="small text-muted">Sigue nuestros boletines mensuales para enterarte de las actividades que realizamos y compartimos con nuestros colaboradores y afiliados</p>
    </div>

    <div class="col-md-8">
      <div class="row gy-3">

        <div class="col-12" *ngFor="let boletin of boletines">

          <!-- <div mat-menu-item class="position-relative" style="height: initial;">
            <div class="d-flex justify-content-between align-items-center py-3">
              <div class="d-flex align-items-center">
                <div class="me-2 me-md-3">
                  <img [src]="readTypeFile(boletin.typeFile)" alt="" width="36px" height="auto">
                </div>
                <p class="mb-0">{{boletin.titulo}}</p>
              </div>
              <div>
                <i-tabler name="external-link" class="text-primary"></i-tabler>
              </div>
            </div>
            <a class="stretched-link" [href]="boletin.archivo" target="_blank"></a>
          </div> -->

          <div class="d-grid">
            <a class="btn btn-outline-light" [href]="boletin.archivo" target="_blank">
              <div class="d-flex justify-content-between align-items-center py-3">
                <div class="d-flex align-items-center">
                  <div class="me-2 me-md-3">
                    <img [src]="readTypeFile(boletin.typeFile)" alt="" width="36px" height="auto">
                  </div>
                  <p class="mb-0 text-dark">{{boletin.titulo}}</p>
                </div>
                <div>
                  <i-tabler name="external-link" class="text-dark"></i-tabler>
                </div>
              </div>
            </a>
          </div>

        </div>

      </div>
    </div>

  </div>


</div>