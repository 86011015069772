<div class="container">
  <swiper [config]="config" class="">
    <ng-template swiperSlide *ngFor="let video of videosHelDestacados">
      <div class="ratio ratio-16x9">
        <iframe width="100%" height="auto" [src]="video.urlEmbed | sanitizeUrl" allowfullscreen></iframe>
      </div>
    </ng-template>
    <div class="swiper-pagination"></div>
  </swiper>
</div>

