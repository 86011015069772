<div class="container" *ngIf="formDocumentoHel">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/helvetas/">Se Competitivo</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/helvetas/documentos">Documentos</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/admin/helvetas/documentos">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Editar documento</p>
  </div>


  <div class="row justify-content-between">


    <div class="col-md-6">
      <form [formGroup]="formDocumentoHel" (submit)="onSubmit()" novalidate class="row">

        <!-- <div class="d-flex">
          <div>
            <img [src]="iconUrl" alt="" height="59.5px" width="auto">
          </div>
          <p>{{documentoHel.archivoName}}</p>
        </div> -->

        <div class="col-12 mb-3">
          <a class="btn btn-light" [href]="documentoHel.archivo" target="_blank">
            <div class="d-flex align-items-center">
              <div class="me-2">
                <img [src]="iconUrl" alt="" height="48px" width="auto">
              </div>
              <span>{{documentoHel.archivoName}}</span>
            </div>
          </a>
        </div>

        

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Cambiar archivo</mat-label>
          <ngx-mat-file-input #removableInput formControlName="archivo" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png, .pdf, .xls" (change)="detectFiles($event)"></ngx-mat-file-input>
          <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
            <mat-icon>clear</mat-icon>
          </button>
          <!-- <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon> -->
          <i-tabler matSuffix name="upload" *ngIf="removableInput.empty"></i-tabler>
          <mat-error *ngIf="errorArchivo()">{{errorArchivo()}}</mat-error>
          <mat-hint>PDF, XLS, JPG, PNG, JPEG</mat-hint>
          <mat-hint align="end">Peso máximo: 5 MB</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Nombre</mat-label>
          <input matInput type="text" formControlName="nombre">
        </mat-form-field>

        <div class="buttonSubmit mt-4 mt-md-5">
          <button class="btn btn-primary w-100">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">Guardar cambios</span>
          </button>
        </div>
      </form>
    </div>



    <!-- <div class="col-md-5">
      <pre>{{formNoticia.value | json}}</pre>
    </div> -->

    

  </div>

</div>