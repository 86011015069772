import { Component, OnInit } from '@angular/core';
import { Estatuto } from 'src/app/classes/estatuto';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-estatutos',
  templateUrl: './estatutos.component.html',
  styleUrls: ['./estatutos.component.scss']
})
export class EstatutosComponent implements OnInit {

  estatutos: Estatuto[] = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getEstatutos().subscribe( (res) => {
      this.estatutos = res;
    });
  }

}
