import { Component, OnInit } from '@angular/core';
import { Alianza } from 'src/app/classes/alianza';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-alianzas',
  templateUrl: './alianzas.component.html',
  styleUrls: ['./alianzas.component.scss']
})
export class AlianzasComponent implements OnInit {

  // tslint:disable-next-line:ban-types
  ids: Array<String> = ['one', 'two', 'three', 'four'];


  // alianzas = [
  //   // {
  //   //   id: 'canaturPromperu',
  //   //   nombre: 'CANATUR – PROMPERÚ',
  //   //   descripcion: 'Desarrollo de la plataforma tecnológica CANATUR Marketplace que permitirá implementar una herramienta que ayudará en la inclusión y democratización de las empresas turísticas, haciendo énfasis en las MYPES y PYMES turísticas, para la comercialización de la oferta de turismo interno nacional.',
  //   //   pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturPromperu.pdf?alt=media&token=3117c632-633e-48ea-8340-24102764a632'
  //   // },
  //   {
  //     id: 'canaturBureauVeritas',
  //     nombre: 'CANATUR- BUREAU VERITAS',
  //     descripcion: 'Tiene como ﬁnalidad fomentar dentro de la industria del turismo, el cumplimiento del marco regulatorio de bioseguridad que deben observar las empresas del sector para el correcto reinicio de actividades a nivel nacional, en donde CANATUR se compromete a promocionar entre sus asociados y los asociados de sus agremiados, el programa de certiﬁcación de cumplimiento de obligaciones de bioseguridad, mientras que BUREAU VERITAS se compromete a prestar a favor de los asociados que decidan contratar sus servicios, el Programa de Cumplimiento de Obligaciones de Bioseguridad otorgando el Certiﬁcado de SAFE GUARD BY BUREAU VERITAS.',
  //     suscripcion: 'Suscrito el 30 septiembre 2020. Vigencia de 02 años.'
  //   },
  //   {
  //     id: 'canaturphilipi',
  //     nombre: 'CANATUR- PHILIPPI, PRIETOCARRIZOSA, FERRERO DU & URÍA S.C.R.L (PPU)',
  //     descripcion: 'Establece una estrategia conjunta de colaboración mutua entre CANATUR y PPU en la difusión de información legal de interés para los asociados de CANATUR y público general que tenga acceso a su portal web u otros medios de difusión, sin que implique costo alguno para las partes.',
  //     pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturPhilippi.pdf?alt=media&token=64bfdc27-86df-4e8d-a2c6-3119094770f5',
  //     suscripcion: 'Suscrito el 5 agosto 2020. Duración indefinida.'
  //   },
  //   {
  //     id: 'canaturMinisterioRREE',
  //     nombre: 'CANATUR - MINISTERIO DE RELACIONES EXTERIORES (RREE)',
  //     descripcion: 'Tiene como objetivo desarrollar actividades conjuntas para la promoción del turismo y otras actividades asociadas, establecer el intercambio de información de interés en materia de turismo y facilitar la cooperación entre las partes en materia de turismo y actividades afines tales como: promoción y difusión de la gastronomía peruana, participación en seminarios, conferencias, ferias workshops y otros relacionados al sector turismo.',
  //     pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturRREE.pdf?alt=media&token=9aec4e5b-c870-40dc-b09c-f25fe0d776e0',
  //     suscripcion: 'Suscrito el 27 Noviembre 2019. Duración indeterminada.'
  //   },
  //   {
  //     id: 'canaturMuniMiraflores',
  //     nombre: 'CANATUR – MUNICIPALIDAD DE MIRAFLORES',
  //     descripcion: 'Compromiso a realizar esfuerzo para promocionar y colaborar mutuamente en la reactivación, promoción y desarrollo del turismo interno y receptivo en la jurisdicción del distrito de Miraflores, realzando sus atractivos y recursos turísticos.',
  //     pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturMuniMiraflores.pdf?alt=media&token=1dcb839c-f475-4f95-acd8-ca75043e2253',
  //     email: 'asistente@canaturperu.org',
  //     suscripcion: 'Suscrito el 15 julio 2020. Vigencia de 2 años. Se viene trabajando la firma de un nuevo convenio.'
  //   },
  //   {
  //     id: 'canaturInstititoDefensa',
  //     nombre: 'CANATUR – INSTITUTO NACIONAL DE DEFENSA DE LA COMPETENCIA Y DE LA PROTECCIÓN DE LA PROPIEDAD INTELECTUAL (INDECOPI)',
  //     descripcion: 'Tiene como objetivo estrechar lazos de cooperación a favor de los consumidores del sector turismo, que permitirá implementar el servicio de información y orientación sobre los derechos y deberes de los ciudadanos que son usuarios del sector, teniendo a disposición información estadística que facilite elaborar estudios para mejorar la prestación de servicios a favor de la colectividad, busca poner a disposición del consumidor del rubro turístico un medio de contacto directo con proveedores, para la gestión de consultas a través del personal calificado y capacitado.',
  //     pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturIndecopi.pdf?alt=media&token=dc91f47d-379e-4267-9524-b06a999f64d3',
  //     suscripcion: 'Suscrito el 27 diciembre 2019. Vigencia indefinida.'
  //   },
  //   // {
  //   //   id: 'canaturCHS',
  //   //   nombre: 'CANATUR – ORGANIZACIÓN CAPITAL HUMANO SOCIAL Y ALTERNATIVO (CHS ALTERNATIVO)',
  //   //   descripcion: 'Busca promover que los gremios, socios o empresas desarrollen acciones vinculadas a la generación de entornos seguros para el desarrollo de un turismo responsable en  defensa y protección de los niños, niñas y adolescentes frente a la explotación sexual.',
  //   //   pdf:'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturCSH.pdf?alt=media&token=f365ec88-4bb3-442a-a24e-879beaa96374',
  //   //   suscripcion: 'Suscrito el 29 agosto 2017. Vigencia de 3 años. (vencido).'
  //   // },
  //   {
  //     id: 'canaturMarinaDeGuerra',
  //     nombre: 'CANATUR – MARINA DE GUERRA DEL PERÚ',
  //     descripcion: 'Tiene como objetivos, establecer lineamientos de mutua coordinación y cooperación entre LAS PARTES, que les permita unir esfuerzos para ejecutar acciones orientadas al cumplimiento de fines y objetivos comunes, en sus respectivos ámbitos de intervención y competencia, fortaleciendo las capacidades internas de ambas Instituciones; así como profundizar el trabajo conjunto en favor de la promoción turística del Perú, y el fomento de la identidad nacional, la conciencia marítima y los intereses marítimos.',
  //     pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturMarinaGuerra.pdf?alt=media&token=3be8313f-cee5-40fe-b099-516666053d2f',
  //     suscripcion: 'Suscrito el 28 septiembre 2020. Vigencia de 2 años.'
  //   },
  //   // {
  //   //   id: 'canaturEmilima',
  //   //   nombre: 'CANATUR- EMPRESA MUNICIPAL INMOBILIARIA DE LIMA S.A.– EMILIMA S.A.',
  //   //   descripcion: 'Establece las pautas generales de cooperación entre EMILIMA S.A. y CANATUR para la realización de actividades propias de cada una de acuerdo con sus competencias, en condiciones de mutua colaboración y sin ﬁnes de lucro para el apoyo y desarrollo turístico de Lima.',
  //   //   pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FcanaturEmilima.pdf?alt=media&token=82d92248-0f0f-4d9d-9582-bc2b70c7b156',
  //   // suscripcion: 'Suscrito el 22 septiembre 2020. Dado de baja.'
  //   // },
  //   {
  //     id: 'canaturCamaraComercio',
  //     nombre: 'CANATUR - CÁMARA DE COMERCIO, INDUSTRIA, SERVICIOS, TURISMO Y DE LA PRODUCCIÓN DEL CUSCO',
  //     descripcion: '',
  //     pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/pdfs%2FConvenio%20CANATUR%20-%20CAMARA%20DE%20COMERCIO%20INDUSTRIA%20Y%20TURISMO%20DE%20CUSCO.pdf?alt=media&token=a5aafb28-18d1-4bb4-92ab-6488398444cb',
  //     suscripcion: 'Suscrito el 29 octubre 2021. Vigencia de 01 año.'
  //   },
  //   {
  //     id: 'canaturLeCordonBlue',
  //     nombre: 'CANATUR - UNIVERSIDAD LE CORDON BLEU',
  //     descripcion: 'Tiene como objetivo formalizar una alianza estratégica de cooperación interinstitucional entre CANATUR y la ULCB, proponiendo acciones o actividades académicas y de servicio de calidad para la formación de profesionales, capacitación y actualización de ejecutivos y empresarios y transferencia de conocimiento.',
  //     pdf: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/alianzas%2FConvenio%20Marco%20ULCB%20-%20CANATUR-versi%C3%B3n%20final%20(1).pdf?alt=media&token=9169f852-9bf9-4de1-b7e5-ce25f22bd376',
  //     suscripcion: 'Suscrito el 12 de julio de  2022. Vigencia de 05 años.'
  //   },
  // ];

  alianzas: Alianza[] = [];

  constructor(
    private fs: FirebaseService
  ) {}

  ngOnInit(): void {
    this.fs.getAlianzas().subscribe( (res) => {
      this.alianzas = res;
    });
  }

}
