<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/helvetas/">Se Competitivo</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/helvetas/videos">Videos</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/admin/helvetas/videos">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Añadir video</p>
  </div>


  <div class="row justify-content-between">


    <div class="col-md-6">
      <form [formGroup]="formVideoHel" (submit)="onSubmit()" novalidate class="row">

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Link video Youtube</mat-label>
          <input matInput type="text" formControlName="url">
          <mat-icon matSuffix class="text-muted">link</mat-icon>
          <!-- <i-tabler matSuffix name="link"></i-tabler> -->
        </mat-form-field>

        <div class="buttonSubmit mt-3">
          <button class="btn btn-primary w-100">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">Añadir video</span>
          </button>
        </div>
      </form>
    </div>



    <!-- <div class="col-md-5">
      <pre>{{formVideoHel.value | json}}</pre>
    </div> -->

    

  </div>

</div>