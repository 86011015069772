import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import { DataFiles } from 'src/app/classes/data-files';
import { DataService } from 'src/app/services/data.service';
import { CrearCategoriaComponent } from '../crear-categoria/crear-categoria.component';

@Component({
  selector: 'app-set-empresa',
  templateUrl: './set-empresa.component.html',
  styleUrls: ['./set-empresa.component.scss']
})
export class SetEmpresaComponent implements OnInit {

  formEmpresa: FormGroup;
  disabled = true;
  loading = false;

  categoriasEmpresas = [];

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
      {
        nombre: 'Empresas Asociadas',
        url: '/admin/asociados/empresasAsociadas'
      },
    ],
    back: '/admin/asociados/empresasAsociadas',
    titulo: 'Empresa Asociada',
    urlButton: ''
  };

  itemId: any;
  data: DataFiles;
 

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog
  ) {

    this.itemId = this.activatedRoute.snapshot.params.id;

    this.data = {
      id: this.itemId,
      route: `empresas/${this.itemId}`,
      maxNumFiles: 1,
      maxFileSize: 1048576 * 5,
      title: 'Logo',
      typeFiles: [
        '.jpg',
        '.jpeg',
        '.png'
      ],
      condiciones: [
        'Tamaño recomendado: 600 x 600',
        'Peso máximo: 5 MB',
      ]
    }
  }

  ngOnInit(): void {

    this.afs.collection('categoriasEmpresas').valueChanges().subscribe( res => {
      this.categoriasEmpresas = res;
    });

    this.formEmpresa = this.fb.group({
      categoria: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: [''],
      web: [''],
      telefono: [''],
      // imageLogo: ['', [FileValidator.maxContentSize(this.maxSize)]],
      // imageLogoName: [''],
      publicado: false,
      fechaCreacion: [Timestamp.now()]
    })


  }

  onSubmit() {
    if (this.formEmpresa.valid) {
      this.disabled = false;
      this.loading 
      this.setEmpresa();
    }
  }

  setEmpresa() {
    this.afs.doc(`${this.data.route}`).set(this.formEmpresa.value, {merge: true})
    .then( (res) => {
      this.router.navigate([`${this.seccion.back}`]);
    });
  }

  openModalAgregarCategoria() {
    this.matDialog.open(CrearCategoriaComponent, {
      autoFocus: false
    })
  }

}
