<div class="container">


	<nav class=" small mt-3">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
			<li class="breadcrumb-item active text-muted">Únete</li>
		</ol>
	</nav>

	<div class="row justify-content-center">
		<div class="col-md-10 py-5 py-md-5">

			<div class="text-center col-md-9 mx-auto pb-4 pb-md-6">
				<p class="h1">Únete</p>
				<p class="lead">La Cámara Nacional de Turismo, como ente representativo del sector empresarial privado de
					turismo
					y principal vocero ante el Gobierno, busca obtener los mejores beneficios para el sector que influya
					directamente en sus asociados, dentro de las
					áreas de legislación, infraestructura, seguridad, entre otras.
				</p>
			</div>
			<div>
				<p class="h3 mb-3">Beneficios del asociado</p>
				<!-- <ol>
            <li *ngFor="let beneficio of beneficios">{{beneficio}}</li>
        </ol> -->
				<div *ngFor="let beneficio of beneficios"
					class="d-flex bg-light rounded-lg px-2 px-md-4 py-3 mb-2 mb-md-3 align-items-center">
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check me-2" width="48"
							height="48" viewBox="0 0 24 24" stroke-width="1" stroke="#E77917" fill="none" stroke-linecap="round"
							stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" />
							<circle cx="12" cy="12" r="9" />
							<path d="M9 12l2 2l4 -4" />
						</svg>
					</div>

					<p class="mb-0">{{beneficio}}</p>
				</div>
			</div>
		</div>
	</div>

	<div class="col-md-10 mx-auto">
		<p class="h3">Únete a CANATUR</p>
		<form #f="ngForm" [formGroup]="formUnete" (ngSubmit)="onSubmit()" novalidate>
			<div class="row">
				<mat-form-field appearance="outline" class="col-12 col-md-6">
					<mat-label>Nombres</mat-label>
					<input matInput formControlName="nombres">
					<mat-error *ngIf="errorNombres()">{{errorNombres()}}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="col-12 col-md-6">
					<mat-label>Apellidos</mat-label>
					<input matInput formControlName="apellidos">
					<mat-error *ngIf="errorApellidos()">{{errorApellidos()}}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="col-12 col-md-6">
					<mat-label>RUC</mat-label>
					<input matInput formControlName="ruc">
					<mat-error *ngIf="errorRuc()">{{errorRuc()}}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="col-12 col-md-6">
					<mat-label>Razón Social</mat-label>
					<input matInput formControlName="razonSocial">
					<mat-error *ngIf="errorRazonSocial()">{{errorRazonSocial()}}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="col-12 col-md-6">
					<mat-label>Email</mat-label>
					<input matInput formControlName="email">
					<mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="col-12 col-md-6">
					<mat-label>Teléfono</mat-label>
					<input matInput formControlName="telefono">
					<mat-error *ngIf="errorTelefono()">{{errorTelefono()}}</mat-error>
				</mat-form-field>
				<div class="col-12 mt-3 d-flex justify-content-end">
					<div style="width: 240px;">
						<div class="d-grid">
							<button type="submit" class="btn btn-primary py-2 px-3">
								<span class="myColorSpinner d-flex justify-content-center" *ngIf="deshabilitado">
									<mat-spinner class="text-center" [diameter]="24"></mat-spinner>
								</span>
								<span *ngIf="!deshabilitado">Solicitar afiliación</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>

</div>