export class NoticiaHel {
  id?: string;
  categoria?: string;
  destacado?: boolean;
  fechaCreacion?: string;
  image?: string;
  imageName?: string;
  publicado?: boolean;
  subtitulo?: string;
  titulo?: string;
  fecha?: any;
  body?: any;
}
