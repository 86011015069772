import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-card-seccion',
  templateUrl: './card-seccion.component.html',
  styleUrls: ['./card-seccion.component.scss']
})
export class CardSeccionComponent implements OnInit {

  @Input() seccion;

  constructor(
    // private router: Router, private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    // this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) =>
    // {
    //   // console.log(e);

    //   // this is fix for dynamic generated(loaded..?) content
    //   setTimeout(() => {
    //     if (e.position) {
    //       this.viewportScroller.scrollToPosition(e.position);
    //     } else if (e.anchor) {
    //       this.viewportScroller.scrollToAnchor(e.anchor);
    //     } else {
    //       this.viewportScroller.scrollToPosition([0, 0]);
    //     }
    //   });
    // });
  }

}
