import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-gracias-unete',
  templateUrl: './gracias-unete.component.html',
  styleUrls: ['./gracias-unete.component.scss']
})
export class GraciasUneteComponent implements OnInit {

  idAfiliacion;
  afiliacion;


  constructor(
    private fs: FirebaseService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.idAfiliacion = params.id;

      this.fs.getAfiliacionCanatur(this.idAfiliacion).subscribe(res => {
        this.afiliacion = res;
        console.log(JSON.stringify(this.afiliacion));
      });
    });
  }

}
