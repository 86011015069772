import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { IdValidatorService } from 'src/app/services/id-validator.service';

@Component({
  selector: 'app-crear-que-hacemos',
  templateUrl: './crear-que-hacemos.component.html',
  styleUrls: ['./crear-que-hacemos.component.scss']
})
export class CrearQueHacemosComponent implements OnInit {

  formQueHacemos!: FormGroup;
  loading = false;
  queHacemosId: string;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  today = new Date();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private idValidator: IdValidatorService
  ) {
      this.queHacemosId = this.afs.collection('queHacemos').doc().ref.id;
      console.log(this.queHacemosId);
  }

  ngOnInit(): void {

    this.formQueHacemos = this.fb.group({
      titulo: ['', Validators.required],
      id: [this.queHacemosId],
      descripcion: ['', Validators.required],
      image: ['', [FileValidator.maxContentSize(this.maxSize), Validators.required]],
      imageName: [''],
      publicado: [false],
      order: [1],
      fechaCreacion: [Timestamp.now()]
    });

    // this.formQueHacemos.get('titulo').valueChanges.subscribe( res => {
    //   const tituloSinSaltoLinea = res.replace(/\n/g, ' ');
    //   const noticiaHelPre = res.replace(/ /g, '-').replace(/\n/g, '-');
    //   this.queHacemosId = noticiaHelPre.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
    //   this.formQueHacemos.get('id').setValue(this.queHacemosId);
    //   this.formQueHacemos.get('titulo').patchValue(tituloSinSaltoLinea, {emitEvent: false});
    // });

    // this.formQueHacemos.get('subtitulo').valueChanges.subscribe( res => {
    //   const subtituloSinSaltosLinea = res.replace(/\n/g, '-');
    //   this.formQueHacemos.get('subtitulo').patchValue(subtituloSinSaltosLinea, {emitEvent: false});
    // });

  }

  onSubmit() {
    if (this.formQueHacemos.valid) {
      this.loading = true;
      this.uploadImageAndCreateItem();
    } else {
      this.validateAllFormFields(this.formQueHacemos);
    }
  }

  crearQueHacemos() {
    this.afs.doc(`queHacemos/${this.queHacemosId}`).set(this.formQueHacemos.value)
    .then(() => {
      this.router.navigate(['/admin/nosotros/queHacemos']);
      console.log('Ítem Que Hacemos creado');
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  

  uploadImageAndCreateItem() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `imagesQueHacemos/${this.queHacemosId}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`queHacemos/${this.queHacemosId}`).set({
          ...this.formQueHacemos.value,
          image: url,
          imageName: `${this.queHacemosId}.${nombreImage[1]}`
        });
        this.router.navigate(['/admin/nosotros/queHacemos']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  errorImagen() {
    return this.formQueHacemos.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

  errorBody() {
    return this.formQueHacemos.controls.body.touched && this.formQueHacemos.controls.body.hasError('required') ? 'Incluye una descripción de la noticia' : '';
  }

}
