import { Component, OnInit } from '@angular/core';
import { DocumentoHel } from 'src/app/classes/documento-hel';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-bloque-documentos-hel',
  templateUrl: './bloque-documentos-hel.component.html',
  styleUrls: ['./bloque-documentos-hel.component.scss']
})
export class BloqueDocumentosHelComponent implements OnInit {

  documentosHel: DocumentoHel[] = [];
  iconItem: any;
  iconUrl: string;

  constructor(
    private fs: FirebaseService,
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.fs.getDocumentosHel().subscribe( res => {
      this.documentosHel = res;
    });
  }

  readTypeFile(typeFile) {
    this.iconItem = this.ds.iconsTypeFiles.find( find => typeFile === find.nombre);
    this.iconUrl = this.iconItem.icon;
    return this.iconUrl
  }

}
