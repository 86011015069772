import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { IdValidatorService } from 'src/app/services/id-validator.service';
import { DocumentoHel } from 'src/app/classes/documento-hel';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-editar-documento',
  templateUrl: './editar-documento.component.html',
  styleUrls: ['./editar-documento.component.scss']
})
export class EditarDocumentoComponent implements OnInit {

  formDocumentoHel!: UntypedFormGroup;
  loading = false;
  documentoHelId: string;
  documentoHel: DocumentoHel;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 50;
  actualSize: any;
  today = new Date();
  iconItem: any;
  iconUrl: string;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ds: DataService
  ) {
      this.documentoHelId = this.afs.collection('documentosHel').ref.doc().id;
      console.log(this.documentoHelId); 
  }

  
  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {
      this.documentoHelId = res.id;

      this.afs.doc(`documentosHel/${this.documentoHelId}`).valueChanges().subscribe( (data: DocumentoHel) => {

        this.documentoHel = data;

        this.iconItem = this.ds.iconsTypeFiles.find( find => this.documentoHel.typeFile === find.nombre);
        this.iconUrl = this.iconItem.icon;

        this.formDocumentoHel = this.fb.group({
          archivo: ['', [FileValidator.maxContentSize(this.maxSize)]],
          archivoName: [''],
          nombre: [this.documentoHel.nombre, Validators.required],
          // id: [this.documentoHelId],
          // publicado: [this.documentoHel.id],
          // fechaCreacion: [Timestamp.now()],
          fechaEdicion: [Timestamp.now()]
        });
      })
    }); 

   

  }

  onSubmit() {
    if (this.formDocumentoHel.valid) {
      this.loading = true;
      if (this.formDocumentoHel.get('archivo')?.value === '') {
        this.crearDocumentoHel();
      } else {
        this.uploadImageAndCreateDocumentoHel();
      }
    } else {
      this.validateAllFormFields(this.formDocumentoHel);
    }
  }

  crearDocumentoHel() {
    const documento = this.formDocumentoHel.value;
    ['archivo', 'archivoName'].forEach(e => delete documento[e]);
    this.afs.doc(`documentosHel/${this.documentoHelId}`).update(this.formDocumentoHel.value)
    .then(() => {
      this.router.navigate(['/admin/helvetas/documentos']);
      console.log('Banner FTP creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadImageAndCreateDocumentoHel() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `documentosHel/${this.nameItem}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`documentosHel/${this.documentoHelId}`).update({
          ...this.formDocumentoHel.value,
          archivo: url,
          archivoName: this.nameItem,
          typeFile: nombreImage[1]
        });
        this.router.navigate(['/admin/helvetas/documentos']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  eliminarArchivoAnterior() {
    
  }

  errorArchivo() {
    return this.formDocumentoHel.controls.archivo.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

}
