import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Noticia } from 'src/app/classes/noticia';
import { NoticiaHel } from 'src/app/classes/noticia-hel';
import { QueHacemos } from 'src/app/classes/que-hacemos';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarQueHacemosComponent } from '../eliminar-que-hacemos/eliminar-que-hacemos.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-lista-que-hacemos',
  templateUrl: './lista-que-hacemos.component.html',
  styleUrls: ['./lista-que-hacemos.component.scss']
})
export class ListaQueHacemosComponent implements OnInit {

  loading!: boolean;
  displayedColumns = [ 'order', 'imagen', 'titulo', 'publicado', 'editar', 'mas' ];
  queHacemosData = new MatTableDataSource();
  value: any;

  opciones = [
    // {
    //   nombre: 'Editar',
    //   icon: 'pencil',
    //   function: (noticia: Noticia) => this.editarItem(noticia)
    // },
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (noticia: Noticia) => this.eliminarItem(noticia)
    }
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.queHacemosData.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {

    // this.fs.getAllNoticiasHel().subscribe((data: QueHacemos[]) => {
    //   this.queHacemosData.data = data;
    //   this.loading = false;
    // });


    this.afs.collection('queHacemos', ref => ref
    .orderBy('order', 'asc')
    ).valueChanges().subscribe((data: QueHacemos[]) => {
      this.queHacemosData.data = data;
      this.loading = false;
    });



    this.queHacemosData.paginator = this.paginator;
    this.queHacemosData.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.queHacemosData.data, event.previousIndex, event.currentIndex);
    this.queHacemosData.data.map( (element: any, index: number )=> {
      this.afs.collection('queHacemos').doc(element.id).update({
        order: index + 1
      });
    });
  }

  clearFilters() {
    this.queHacemosData.filter = '';
    this.value = '';
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.queHacemosData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  // actualizarPublicado(key, obj, e) {
  //   this.fs.updatePublicadoGremio(key, e);
  // }

  
  actualizarPublicado(idItem: string, change: MatSlideToggleChange) {
    this.afs.collection('queHacemos').doc(idItem).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item: any) {
    return item.publicado;
  }

  editarItem(item: QueHacemos) {
    this.router.navigate([`admin/nosotros/queHacemos/editarQueHacemos/${item.id}`]);
  }

  eliminarItem(item: QueHacemos) {
    this.matDialog.open(EliminarQueHacemosComponent, {
      panelClass: 'dialogSmall',
      data: item
    });
  }

}
