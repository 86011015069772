import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-unete',
  templateUrl: './unete.component.html',
  styleUrls: ['./unete.component.scss']
})
export class UneteComponent implements OnInit {

  deshabilitado: boolean;

  beneficios = [
    'CANATUR otorga el más amplio apoyo en defensa de sus legítimos derechos, en todo aquello que esté dentro de su ámbito, atribuciones y posibilidades',
    'Brinda respaldo legal y reconocimiento ante el sector y sus principales clientes, considerando que al integrarse a CANATUR, demuestra que su empresa es seria, responsable y que está legalmente constituida.',
    'Participación en charlas, seminarios de capacitación y conferencias sobre temas de actualidad, al igual que en los principales eventos de interés turístico nacional e internacional que la Institución organiza para promocionar el Destino Perú.',
    'El Asociado podrá insertar el logo de su empresa en la página institucional de CANATUR: www.canaturperu.org',
    'Podrá publicar notas de prensa, noticias o circulares sobre su empresa de manera gratuita e ilimitada.',
    'Podrá obtener tarifas especiales para la publicidad respectiva dentro del Portal de Noticias: www.portaldeturismo.pe'
  ];

  formUnete: UntypedFormGroup;
  @ViewChild('f') form;

  afiliacion;

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    public snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formUnete = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      ruc: ['', Validators.required],
      razonSocial: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // telefono: ['', Validators.required],
      fechaCreacion: [ Timestamp.now() ],
      telefono: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(9)] ],
    });

    this.afiliacion = this.afs.collection('afiliacionesCanatur').ref.doc();
    console.log(this.afiliacion.id);
  }

  onSubmit() {
    if (this.formUnete.valid) {
      this.guardarAfiliacion();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formUnete);
      this.deshabilitado = false;
    }
  }

  guardarAfiliacion() {
    this.fs.addAfiliacionCanatur(this.afiliacion.id, this.formUnete.value)
    .then(() => {
      console.log(this.formUnete.value);
      this.form.resetForm({
        fechaCreacion: Timestamp.now(),
      });
      this.router.navigate([`/unete/${this.afiliacion.id}`]);
      // this.snackBar.open('Tu mensaje ha sido enviado', 'CERRAR', {
      //   duration: 8000,
      // });
    }, error => console.error('error al enviar el formulario:', error));
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorNombres() {
    return this.formUnete.controls.nombres.hasError('required') ? 'Escriba sus nombres' : '';
  }

  errorApellidos() {
    return this.formUnete.controls.apellidos.hasError('required') ? 'Escriba sus apellidos' : '';
  }

  errorRuc() {
    return this.formUnete.controls.ruc.hasError('required') ? 'Ingrese el RUC' : '';
  }

  errorRazonSocial() {
    return this.formUnete.controls.razonSocial.hasError('required') ? 'Ingrese la Razón Social' : '';
  }

  errorEmail() {
    return this.formUnete.controls.email.hasError('required') ? 'Ingrese un email' :
    this.formUnete.controls.email.hasError('email') ? 'No es un email válido' : '';
  }
  errorTelefono() {
    return this.formUnete.controls.telefono.hasError('required') ? 'Ingrese un número de teléfono' :
    this.formUnete.controls.telefono.hasError('maxlength') ? 'No debe exceder los 9 caracteres' :
    this.formUnete.controls.telefono.hasError('pattern') ? 'Solo se admiten números.' : '';
  }

}
