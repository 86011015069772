<div class="container">

	<nav class="extraSmall my-3 my-md-4">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
			<li class="breadcrumb-item"><a routerLink="/asociados">Asociados</a></li>
			<li class="breadcrumb-item active text-muted">Gremios</li>
		</ol>
	</nav>


	<div class="">
		
		<p class="h1 mb-3 text-center">Gremios</p>

		<div class="row g-2 g-md-5">

			<div class="col-md-6" *ngFor="let gremio of gremios | async">
				<div class="d-flex align-items-center border py-3 pe-3" >
					<div class="me-2">
						<img class="imageGremio" [src]="gremio.imageLogo || 'assets/images/imageFakerCamara.png' ">
					</div>
					<div class="ms-0 ms-md-3">
						<p class="mb-1 h5 text-uppercase">{{gremio.nombre}}</p>
						<p class="text-muted">{{gremio.descripcion}}</p>
						<a class="" [href]="gremio.web" target="blank" *ngIf="gremio.web != ''" style="word-break: break-all;">
							{{gremio.web}}</a>
					</div>
				</div>
			</div>

		</div>
	</div>

</div>



