<div class="container">

  <app-header-section [seccion]="seccion"></app-header-section>

  <div class="table-responsive">

    <table mat-table [dataSource]="data" matSort class="example-table" [trackBy]="trackByPublicado">

      <!-- <ng-container matColumnDef="imagen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</th>
        <td mat-cell *matCellDef="let item"> <img class="imageItemAdmin border my-1 rounded"
            [src]="item.imageLogo || 'assets/images/imageFakerCamara.png' "></td>
      </ng-container> -->

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
        <td mat-cell *matCellDef="let item"> {{item.nombre}} </td>
      </ng-container>
      
      <ng-container matColumnDef="presidente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Presidente</th>
        <td mat-cell *matCellDef="let item"> {{item.presidente}} </td>
      </ng-container>  

      <ng-container matColumnDef="publicado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Visible</th>
        <td mat-cell *matCellDef="let item">
          <mat-slide-toggle color="primary" #toggle [checked]="item.publicado"
          (change)="actualizarPublicado(item.id, $event)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="editar">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <a [routerLink]=" 'editarEstatuto/' + item.id" class="btn btn-sm btn-outline-primary">Editar</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="eliminar">
        <th mat-header-cell *matHeaderCellDef>Eliminar</th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="eliminarItem(item)">
            <i-tabler class="text-primary" name="trash"></i-tabler>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="detalles">
        <th mat-header-cell *matHeaderCellDef>Detalles</th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button>
            <mat-icon class="material-icons-outlined text-muted">remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</div>