import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BannerFtp } from 'src/app/classes/banner-ftp';
import { Noticia } from 'src/app/classes/noticia';
import { NoticiaHel } from 'src/app/classes/noticia-hel';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarBannerFtpComponent } from '../eliminar-banner-ftp/eliminar-banner-ftp.component';

@Component({
  selector: 'app-lista-banners-ftp',
  templateUrl: './lista-banners-ftp.component.html',
  styleUrls: ['./lista-banners-ftp.component.scss']
})
export class ListaBannersFtpComponent implements OnInit {

  loading!: boolean;
  displayedColumns = [ 'imagen', 'referencia', 'publicado', 'editar', 'mas' ];
  noticiasData = new MatTableDataSource();
  value: any;

  opciones = [
    // {
    //   nombre: 'Editar',
    //   icon: 'pencil',
    //   function: (noticia: Noticia) => this.editarItem(noticia)
    // },
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (bannerFtp: BannerFtp) => this.eliminarItem(bannerFtp)
    }
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.noticiasData.filter = filterValue.trim().toLowerCase();
  }

  
  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.fs.getAllBannersFtp().subscribe((data: BannerFtp[]) => {
      this.noticiasData.data = data;
      this.loading = false;
    });
    this.noticiasData.paginator = this.paginator;
    this.noticiasData.sort = this.sort;
  }

  clearFilters() {
    this.noticiasData.filter = '';
    this.value = '';
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.noticiasData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  // actualizarPublicado(key, obj, e) {
  //   this.fs.updatePublicadoGremio(key, e);
  // }

  actualizarPublicado(idBannerFtp: string, change: MatSlideToggleChange) {
    this.afs.collection('bannersFtp').doc(idBannerFtp).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item: any) {
    return item.publicado;
  }

  editarItem(bannerFtp: BannerFtp) {
    this.router.navigate([`admin/eventos/ftp/bannersFtp/editarBannerFtp/${bannerFtp.id}`]);
  }

  eliminarItem(bannerFtp: BannerFtp) {
    this.matDialog.open(EliminarBannerFtpComponent, {
      panelClass: 'dialogSmall',
      data: bannerFtp
    });
  }

}
