import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  formMarketplace: UntypedFormGroup;
  @ViewChild('f') form;

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.formMarketplace = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      ruc: ['', Validators.required],
      razonSocial: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // telefono: ['', Validators.required],
      fechaCreacion: [ Timestamp.now() ],
      telefono: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(9)] ],
    });
  }

  onSubmit() {
    if (this.formMarketplace.valid) {
      this.guardarAfiliacion();
    } else {
      this.validateAllFormFields(this.formMarketplace);
    }
  }

  guardarAfiliacion() {
    this.fs.addAfiliacionMarketplace(this.formMarketplace.value)
    .then(() => {
      console.log(this.formMarketplace.value);
      this.form.resetForm({
        fechaCreacion: Timestamp.now(),
      });
      this.snackBar.open('Tu mensaje ha sido enviado', 'CERRAR', {
        duration: 8000,
      });
    }, error => console.error('error al enviar el formulario:', error));
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorNombres() {
    return this.formMarketplace.controls.nombres.hasError('required') ? 'Escriba sus nombres' : '';
  }

  errorApellidos() {
    return this.formMarketplace.controls.apellidos.hasError('required') ? 'Escriba sus apellidos' : '';
  }

  errorRuc() {
    return this.formMarketplace.controls.ruc.hasError('required') ? 'Ingrese el RUC' : '';
  }

  errorRazonSocial() {
    return this.formMarketplace.controls.razonSocial.hasError('required') ? 'Ingrese la Razón Social' : '';
  }

  errorEmail() {
    return this.formMarketplace.controls.email.hasError('required') ? 'Ingrese un email' :
    this.formMarketplace.controls.email.hasError('email') ? 'No es un email válido' : '';
  }
  errorTelefono() {
    return this.formMarketplace.controls.telefono.hasError('required') ? 'Ingrese un número de teléfono' :
    this.formMarketplace.controls.telefono.hasError('maxlength') ? 'No debe exceder los 9 caracteres' :
    this.formMarketplace.controls.telefono.hasError('pattern') ? 'Solo se admiten números.' : '';
  }

}
