<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/admin">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Se Competitivo</p>
  </div>

  <div class="border p-3 p-md-4 rounded position-relative mb-2" *ngFor="let link of links">
    <div class="d-flex justify-content-between">
      <p class="mb-0">{{link.nombre}}</p>
      <div>
        <i-tabler name="arrow-right"></i-tabler>
      </div>
    </div>
    <a class="stretched-link" [routerLink]="link.url"></a>
  </div>  

</div>