<div class="container py-3 py-md-4">

	<div class="row">

		<div class="col-md-8">
			<swiper [config]="config" class="mb-2 mb-md-0 myBannerNoticias rounded">
				<ng-template swiperSlide *ngFor="let slide of slides | async">
					<div class="imagesBanner w-100 h-100"
						[style.background-image]=" 'url('+ (slide.image || slide.urlImage ) +')' ">
						<div class="bgGradient w-100 h-100 px-4 px-md-5 pb-5 pb-md-6 d-flex justify-content-center align-items-end">
							<div class="text-center">
								<p class="h4 text-white myShadowText">{{slide.titulo}}</p>
								<a class="btn btn-sm btn-outline-primary" [href]="slide.url" target="blank">ver en
									portaldeturismo.pe</a>
							</div>
						</div>
					</div>
				</ng-template>
				<div class="swiper-pagination"></div>
			</swiper>
		</div>



		<!-- <swiper [config]="config" class="mb-5 mb-md-6">
      <ng-template swiperSlide *ngFor="let item of carousel">
        <img [src]="item" class="slideCnt">
      </ng-template>
      <div class="swiper-pagination"></div>
    </swiper> -->

		<div class="col-md-4">
			<div class="bannerHelvetas p-4 p-md-5 rounded-3 text-center position-relative">
				<span class="titulo">Proyecto SeCompetitivo</span>
				<div class="grillaLogosHelvetasHome my-3 my-md-4">
					<div class="" *ngFor="let logo of logosHelvetas">
						<img [src]="logo" alt="" width="100%">
					</div>
				</div>
				<p class="h5">“Tarapoto y Alto Mayo: Turismo y Naturaleza en Valor”, facilitado por HELVETAS y ejecutado por
					CANATUR</p>
				<a class="btn btn-primary stretched-link" routerLink="/helvetas">Conoce más aquí</a>
			</div>
		</div>

	</div>



	<div class="row g-3 py-3">
		<div class="col-md-4" *ngFor="let seccion of seccionesHome">
			<app-card-seccion [seccion]="seccion"></app-card-seccion>
		</div>
	</div>



	<div class="py-3 py-md-4">
		<p class="h4 mb-2 mb-md-3">Noticias recientes</p>
		<div class="grillaNoticiasHome">
			<app-card-noticia *ngFor="let noticia of noticias | async" [noticia]="noticia"></app-card-noticia>
		</div>
	</div>



	<div class="my-4 my-md-5">
		<div class="mb-3 mb-md-4" *ngFor="let bannerFtp of bannersFtp">
			<a [href]="bannerFtp.url" target="_blank">
				<img class="border rounded" [src]="bannerFtp.image" alt="" width="100%">
			</a>
		</div>
	</div>




	<!-- <div
		class="backSafePlace rounded border p-3 px-md-5 py-md-4 mt-4 d-flex flex-column flex-md-row shadow-sm position-relative">
		<div class="mr-0 me-md-4">
			<img class="logoSafePlace me-3"
				src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logoSafePlace.svg?alt=media&token=7b12c2c4-0cc9-47e0-b9b1-eef807acaa2e">
			<img class="logoSafePlace"
				src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logoSafeGuard.png?alt=media&token=6408c43f-e4ed-4574-bd47-abf286bfcedb">
		</div>
		<div>
			<p class="h2 font-weight-bold">Certificado SAFE PLACE</p>
			<p class="lead">Obtén el Sello de Protección que certificará tu operación segura</p>
			<a class="btn btn-primary stretched-link" href="https://mailchi.mp/13d6e8b557ff/formulariobioseguridadbv"
				target="blank">Obtener sello</a>
		</div>
	</div> -->


</div>