<div class="container">
  <div class="row gy-6">

    <div class="col-12" *ngFor="let banner of bannersHel">

      <div class="bg-light">
        <div class="row gx-5 gy-3">

          <div class="col-md-6">
            <div class="p-4 p-md-6">
              <p class="h2 text-primary mb-3">{{banner.titulo}}</p>
              <p class="mb-3">{{banner.descripcion}}</p>
              <a class="btn btn-outline-primary" [href]="banner.urlButton" target="_blank">
                Conoce más aquí
                <i-tabler name="arrow-right" class="ms-2"></i-tabler>
              </a>
            </div>
            
          </div>
  
          <div class="col-md-6">
            <div class="h-100">
              <img [src]="banner.image" alt="" width="100%" height="100%" style="object-fit: cover;">
            </div>
          </div>
  
        </div>
      </div>

      

    </div>

  </div>
</div>