import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataFiles } from 'src/app/classes/data-files';

@Component({
  selector: 'app-crear-evento',
  templateUrl: './crear-evento.component.html',
  styleUrls: ['./crear-evento.component.scss']
})
export class CrearEventoComponent implements OnInit {

  
  formEvento: FormGroup;
  disabled = true;
  loading = false;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Eventos',
        url: '/admin/eventos'
      },
    ],
    back: '/admin/eventos',
    titulo: 'Agregar Evento',
    urlButton: ''
  };

  itemId: any;
  data: DataFiles;

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    this.itemId = this.activatedRoute.snapshot.params.id;
    
    this.data = {
      id: this.itemId,
      route: `eventos/${this.itemId}`,
      maxNumFiles: 6,
      maxFileSize: 1048576 * 5,
      title: 'Imágenes',
      typeFiles: [
        '.jpg',
        '.jpeg',
        '.png'
      ],
      condiciones: [
        'Tamaño recomendado: 800 x 450',
        'Peso máximo por imagen 5 MB',
        `Máximo 6 imágenes`,
      ]
    }
  }

  ngOnInit(): void {

    this.formEvento = this.fb.group({
      nombre: ['', Validators.required],
      subtitulo: ['', Validators.required],
      descripcion: ['', Validators.required],
      nameButton: [''],
      urlButton: [''],
      publicado: false,
      lastModified: new Date(),

    })

  }

  onSubmit() {
    if (this.formEvento.valid) {
      this.disabled = false;
      this.loading = true;
      this.crearEvento();
    }
  }

  crearEvento() {
    this.afs.doc(`eventos/${this.itemId}`).set(this.formEvento.value, {merge: true})
    .then( (res) => {
      this.router.navigate(['/admin/eventos']);
    });
  }

}
