export class Gremio {
  nombre: string;
  descripcion: string;
  web: string;
  fechaCreacion: string;
  publicado: boolean;
  imageLogo: string;
  imageLogoName: string;
  id: string
}
