import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { IdValidatorService } from 'src/app/services/id-validator.service';

@Component({
  selector: 'app-crear-banner-ftp',
  templateUrl: './crear-banner-ftp.component.html',
  styleUrls: ['./crear-banner-ftp.component.scss']
})
export class CrearBannerFtpComponent implements OnInit {

  formBannerFtp!: UntypedFormGroup;
  loading = false;
  bannerFtpId: string;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  today = new Date();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private idValidator: IdValidatorService
  ) {
      // this.noticia = this.afs.collection('noticiasHel').doc(this.bannerFtpId);
      // console.log(this.noticia.id);
      this.bannerFtpId = this.afs.collection('bannersFtp').ref.doc().id;
      console.log(this.bannerFtpId);
      
  }

  ngOnInit(): void {

    this.formBannerFtp = this.fb.group({
      url: [''],
      referencia: [''],
      id: [this.bannerFtpId],
      image: ['', [ Validators.required, FileValidator.maxContentSize(this.maxSize)]],
      imageName: [''],
      publicado: [false],
      fechaCreacion: [Timestamp.now()]
    });

  }

  onSubmit() {
    if (this.formBannerFtp.valid) {
      this.loading = true;
      if (this.formBannerFtp.get('image')?.value === '') {
        this.crearBannerFtp();
      } else {
        this.uploadImageAndCreateBannerFtp();
      }
    } else {
      this.validateAllFormFields(this.formBannerFtp);
    }
  }

  crearBannerFtp() {
    this.afs.doc(`bannersFtp/${this.bannerFtpId}`).set(this.formBannerFtp.value)
    .then(() => {
      this.router.navigate(['/admin/eventos/ftp/bannersFtp']);
      console.log('Banner FTP creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadImageAndCreateBannerFtp() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `imagesBannersFtp/${this.bannerFtpId}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`bannersFtp/${this.bannerFtpId}`).set({
          ...this.formBannerFtp.value,
          image: url,
          imageName: `${this.bannerFtpId}.${nombreImage[1]}`
        });
        this.router.navigate(['/admin/eventos/ftp/bannersFtp']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  errorImagen() {
    return this.formBannerFtp.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

  errorBody() {
    return this.formBannerFtp.controls.body.touched && this.formBannerFtp.controls.body.hasError('required') ? 'Incluye una descripción de la noticia' : '';
  }

}
