import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  links = [
    {
      nombre: 'Nosotros',
      url: '/nosotros'
    },
    {
      nombre: 'Estatutos',
      url: '/estatutos'
    },
    {
      nombre: 'Asociados',
      url: '/asociados',
      submenu: [
        {
          nombre: 'Gremios',
          url: '/asociados/gremios'
        },
        {
          nombre: 'Cámaras Regionales',
          url: '/asociados/camarasRegionales'
        },
        {
          nombre: 'Empresas Asociadas',
          url: '/asociados/empresas'
        },
      ]
    },
    {
      nombre: 'Eventos',
      url: '/eventos',
      submenu: [
        {
          nombre: 'Eventos',
          url: '/eventos/eventos'
        },
        {
          nombre: 'Congreso Nacional de Turismo',
          url: '/eventos/xvCongresoNacionalTurismo'
        },
      ]
    },
    // {
    //   nombre: 'Marketplace',
    //   url: '/marketplace'
    // },
    {
      nombre: 'Alianzas',
      url: '/alianzas'
    },
    {
      nombre: 'seCompetitivo',
      url: '/helvetas'
    },
    {
      nombre: 'Noticias',
      url: '/noticias'
    },
    {
      nombre: 'Boletines',
      url: '/boletines'
    },

  ];

  categoriasAsociados = [
    'Agencias de viajes',
    'Casinos',
    'Hoteles',
    'Institutos y Universidades',
    'Proveedores',
    'Restaurantes',
    'Transportes',
    'Trenes'
  ];

  slidesMarketplace = [
    'Afíliate aquí al CANATUR MARKETPLACE',
    'Tus ofertas en la web Oficial de Turismo Interno del Perú',
    'Se parte de CANATUR MARKETPLACE aquí'
  ];

  categorias = [
    'Locales', 'Regiones', 'Internacional', 'Empresas', 'Canatur', 'Gremios', 'Tendencias', 'Gastronomía', 'Estadíticas', 'Eventos', 'Opinión', 'Especiales', 'Turismo', 'Entrevista', 'Economía', 'Campañas'
  ];

  seccionesHome = [
    // {
    //   nombre: 'Aló Turista',
    //   descripcion: 'Convenio CANATUR–INDECOPI',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/seccionesHome%2FaloTurista.jpg?alt=media&token=55594c29-e7e2-4776-9a92-717925e341e6',
    //   url: '/',
    // },
    // {
    //   nombre: 'Peru Travel Mart',
    //   descripcion: '23-27 Noviembre 2020',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/seccionesHome%2FperuTravelMart.jpg?alt=media&token=7e19f605-f589-4add-9eda-7f81a113eae9',
    //   url: '/eventos',
    // },
    // {
    //   nombre: 'Canatur Marketplace',
    //   descripcion: 'Convenio CANATUR-PROMPERÚ',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/seccionesHome%2Fmarketplace.jpg?alt=media&token=442a1ea5-f1a6-4b81-9d97-95d2dd79a2b3',
    //   url: '/marketplace',
    // },
    {
      nombre: 'Licencias de funcionamiento',
      descripcion: 'CANATUR - Municipalidad de Miraflores',
      image: '',
      tipoUrl: 'interno',
      url: '/alianzas',
      fragment: 'canaturMuniMiraflores',
      nombreBoton: 'Conoce más'
    },
    // {
    //   nombre: 'Certificación SAFE PLACE',
    //   descripcion: 'Convenio CANATUR - BUREAU VERITAS',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/backSafePlace.jpg?alt=media&token=9d987eb9-77bd-493c-a8e1-9f952485c1c4',
    //   tipoUrl: 'externo',
    //   url: 'https://mailchi.mp/13d6e8b557ff/formulariobioseguridadbv',
    //   nombreBoton: 'Obtener sello'
    // },
    {
      nombre: 'Plataforma TPA',
      descripcion: 'Convenio CANATUR - SIPPO',
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logoSippo.svg?alt=media&token=35ef3634-1584-4804-9926-e57bde762356',
      tipoUrl: 'externo',
      url: 'https://www.tradepromotionacademy.org/',
      nombreBoton: 'Ver plataforma'
    },
    {
      nombre: 'Únete a CANATUR',
      descripcion: 'Se parte de la fuerza turística de nuestro país',
      image: '',
      tipoUrl: 'interno',
      url: '/unete',
      nombreBoton: 'Únete ahora'
    },
    // {
    //   nombre: 'Proyecto Tarapoto y Alto Mayo',
    //   descripcion: 'Turismo y naturaleza en valor',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/backSafePlace.jpg?alt=media&token=9d987eb9-77bd-493c-a8e1-9f952485c1c4',
    //   tipoUrl: 'interno',
    //   url: '/helvetas',
    //   nombreBoton: 'Conoce más'
    // },
  ];

  slides = [
    {
      titulo: 'City Tour Panorámico',
      tituloEn: 'Panoramic City Tour',
      descriptorCorto: 'Descubre la esencia de la ciudad',
      descriptorCortoEn: 'Discover the escence of the city',
      callToAction: '20',
      image: 'https://firebasestorage.googleapis.com/v0/b/turibus-59f94.appspot.com/o/destacados%2FbannerCityTourPanoramico.jpg?alt=media&token=dd1bc79a-d370-45b1-8603-54872806fd32',
      urlButton: 'cityTourPanoramico',
      urlButtonEn: 'panoramicCityTour'
    },
    {
      titulo: 'Lima Iluminada + Fuentes Lima',
      tituloEn: 'City Night + Fountains of Lima',
      descriptorCorto: 'Descubre la belleza de Lima',
      descriptorCortoEn: 'Discover the beauty of Lima',
      callToAction: '18',
      image: 'https://firebasestorage.googleapis.com/v0/b/turibus-59f94.appspot.com/o/destacados%2FfuentesCenaShow.jpg?alt=media&token=5348cc4f-a9ba-474a-a888-69c2f4fec512',
      urlButton: 'limaIluminadaFuentesLima',
      urlButtonEn: 'cityNightFountainsOfLima'
    },
    {
      titulo: 'Barranco + Miraflores Tour',
      tituloEn: 'Barranco + Miraflores Tour',
      descriptorCorto: 'Conoce los secretos de Barranco',
      descriptorCortoEn: 'Discover the secrets of Barranco',
      callToAction: '7',
      image: 'https://firebasestorage.googleapis.com/v0/b/turibus-59f94.appspot.com/o/destacados%2FbannerMirafloresBarranco.jpg?alt=media&token=5172e7f9-cbc4-4945-8b85-f1ee33b615db',
      urlButton: 'tourBarrancoMiraflores',
      urlButtonEn: 'barrancoMirafloresTour'
    },
  ];



  redesSociales = [
    {
      icon: 'brand-facebook',
      url: 'https://www.facebook.com/CamaraNacionalDeTurismoPERU/'
    },
    {
      icon: 'brand-instagram',
      url: 'https://www.instagram.com/canaturperu/'
    },
    {
      icon: 'brand-youtube',
      url: 'http://www.youtube.com/c/CANATURPERÚOFICIAL'
    },
    {
      icon: 'brand-x',
      url: 'https://twitter.com/CanaturdelPeru'
    },
  ];

  asociados = [
    {
      nombre: 'Gremios',
      url: '/asociados/gremios',
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/general%2FasociadosGremios.jpg?alt=media&token=62854078-d552-41f8-ac11-ed22f75f0a3e',
    },
    {
      nombre: 'Cámaras Regionales',
      url: '/asociados/camarasRegionales',
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/general%2FasociadosCamarasRegionales.jpg?alt=media&token=70051cd7-4d00-472f-bd02-e47bdb984156',
    },
    {
      nombre: 'Empresas Asociadas',
      url: '/asociados/empresasAsociadas',
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/general%2FasociadosEmpresasAsociadas.jpg?alt=media&token=bd8e80ce-70f4-4b18-9304-8060025b29b2',
    },
  ];

  expoturismos = [
    {
      image: 'assets/images/imageFaker.jpg',
      nombre: 'Perú Travel Mart',
      descripcion: 'Rueda de Negocios Internacional más importante del país, dirigida a altos ejecutivos internacionales con poder de decisión; mediante una plataforma B2B que permite la comercialización y la promoción de diversos productos y servicios que ofrece el Perú a través de la asignación de citas pre-programadas. El evento considera también sesiones de encuentros de corta duración (Speed Networking), permitiendo afianzar negocios, además de ampliar la red de contactos con empresas relacionadas al rubro.',
    },
    {
      image: 'assets/images/imageFaker.jpg',
      nombre: 'Feria Turismo Perú',
      descripcion: 'Fortalece y desarrolla el turismo interno en el Perú. Plataforma ideal para la comercialización y venta de paquetes turísticos, alojamiento, boletos aéreos y terrestres, transporte fluvial y lacustre, entre otros servicios turísticos a tarifas promocionales, contando con descuentos especiales para el público en general. Ofrece a las empresas expositoras la oportunidad de generar lazos comerciales, acercamiento con futuros contactos potenciales, incremento de cartera de clientes, entre otros.',
    },
    {
      image: 'assets/images/imageFaker.jpg',
      nombre: 'Turismo Mundo',
      descripcion: 'Espacio ideal para que las autoridades de promoción turística y empresas mayoristas de los distintos países, puedan exhibir y comercializar su oferta al principal mercado emisor de turistas peruanos al exterior: Lima y Callao. Permite a los expositores establecer lazos comerciales, incremento de cartera de clientes y acercamiento con futuros contactos potenciales: agencias de viajes y operadores de turismo peruanos. Para más información sobre la participación en los eventos pueden escribir a eventos2@canaturperu.org',
    },
  ];

  caretures = [
    {
      nombre: 'CARETUR AMAZONAS',
      representante: 'Jeff Contreras Soto',
      cargo: 'Presidente',
      telefonos: ['944222888'],
      emails: ['jcontreras@quiunti.com'],
    },
    {
      nombre: 'CARETUR AREQUIPA',
      representante: 'Walter Bustamante Cano',
      cargo: 'Presidente',
      telefonos: ['999 999 730'],
      emails: ['sonccollay@gmail.com'],
    },
    {
      nombre: 'CARETUR AYACUCHO',
      representante: 'Apolonio Flores Sulca',
      cargo: 'Presidente',
      telefonos: ['975664744', '995553533'],
      emails: ['presidenciacareturayacucho@hotmail.com', 'urpillaytours@hotmail.com'],
    },
    {
      nombre: 'CARETUR CAJAMARCA',
      representante: 'Eusebio Díaz López',
      cargo: 'Presidente',
      telefonos: ['960261289'],
      emails: ['careturcajamarca1@gmail.com', 'oronortesrl@hotmail.com'],
    },
    {
      nombre: 'CARTUC CUSCO',
      representante: 'Berner Caballero Castro',
      cargo: 'Presidente',
      telefonos: ['952719920'],
      emails: ['bernercaballero@gmail.com'],
    },
    {
      nombre: 'CARETUR ICA',
      representante: 'Víctor Rojas Chilquillo',
      cargo: 'Presidente',
      telefonos: ['956462132', '955929233'],
      emails: ['rojasvaica@hotmail.com'],
    },
    {
      nombre: 'CAMARA DE TURISMO DE NAZCA',
      representante: 'Elizabeth Scaparti',
      cargo: 'Presidenta',
      telefonos: ['(056) 523490', '956789810', '961964900'],
      emails: ['elporton@speedy.com.pe'],
    },
    {
      nombre: 'CADETUR LA LIBERTAD',
      representante: 'Francisco San Martín Baldwin',
      cargo: 'Presidente',
      telefonos: ['993289550'],
      emails: ['francisco.sanmartin.baldwin@gmail.com'],
    },
    {
      nombre: 'CADETUR LAMBAYEQUE',
      representante: 'Jorge Vértiz Cellerini',
      cargo: 'Presidente',
      telefonos: ['979614630', '*621898'],
      emails: ['cadeturlambayeque@gmail.com'],
    },
    {
      nombre: 'CÁMARA DE TURISMO DE BARRANCA',
      representante: 'Haydee Larrea',
      cargo: 'Presidenta',
      telefonos: ['963332230'],
      emails: ['hlarrea52@yahoo.com'],
    },
    {
      nombre: 'CARETUR LORETO',
      representante: 'Tedy Vásquez',
      cargo: 'Presidente',
      telefonos: ['984939392'],
      emails: ['presidencia@careturloreto.org'],
    },
    {
      nombre: 'CARETUR MACHUPICCHU',
      representante: 'Maycol Ugarte Vilca',
      cargo: 'Presidente',
      telefonos: ['956285979'],
      emails: ['camaramachupicchu@gmail.com'],
    },
    {
      nombre: 'CARETUR MOQUEGUA',
      representante: 'Rony Alberto Soto Vilca',
      cargo: 'Presidente',
      telefonos: ['942171029'],
      emails: ['descubremoquegua@hotmail.com'],
    },
    {
      nombre: 'CAPATUR PARACAS',
      representante: 'Eduardo Jáuregui Cabrera',
      cargo: 'Presidente',
      telefonos: ['956672163'],
      emails: ['edu_jc3@hotmail.com'],
    },
    {
      nombre: 'CARETUR SAN MARTIN',
      representante: 'Ramon da Cruz Cam',
      cargo: 'Presidente',
      telefonos: ['972514699'],
      emails: ['rdc@hoteltarapoto.com'],
    },
    {
      nombre: 'CARETUR TACNA',
      representante: 'Wilfredo Espinoza Chavez',
      cargo: 'Presidente',
      telefonos: ['944004044'],
      emails: ['presidentecareturtcq@gmail.com'],
    },
    {
      nombre: 'CARETUR TUMBES',
      representante: 'Carlos Viñas de Vivero',
      cargo: 'Presidente',
      telefonos: ['981555458'],
      emails: ['careturtumbes@yahoo.es', 'cfvvservice@yahoo.es'],
    },
    {
      nombre: 'CARETUR UCAYALI',
      representante: 'Giovana Vega Vásquez',
      cargo: 'Presidente',
      telefonos: ['961621577'],
      emails: ['G.vega@amazonworldperu.com.pe'],
    },
  ];

  // gremios = [
  //   {
  //     nombre: 'ADMINISTRADORA PERUANA DE ALBERGUES - APHI',
  //     direccion: '',
  //     telefonos: [ '' ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'Asociación de Ferias del Perú - AFEP',
  //     direccion: '',
  //     telefonos: [ '' ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'ASOCIACIÓN DE GUÍAS OFICIALES DE TURISMO - AGOTUR',
  //     direccion: 'Calle Coronel Inclan 831 dpto C 102, Miraflores',
  //     telefonos: [ '987 710 416' ],
  //     web: 'http://www.agoturlima.com/',
  //     image: '',
  //   },
  //   {
  //     nombre: 'ASOCIACIÓN DE OPERADORES DE SERVICIO ESPECIAL DE TRANSPORTE TURÍSTICO DEL PERÚ - ASOSETTUR PERU',
  //     direccion: '',
  //     telefonos: [ ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'ASOCIACIÓN FEMENINA DE EJECUTIVAS DE EMPRESAS TURÍSTICAS DEL PERÚ - AFEET',
  //     direccion: '',
  //     telefonos: [ ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'ASOCIACIÓN PERUANA DE AGENCIAS DE VIAJES Y TURISMO - APAVIT',
  //     direccion: '',
  //     telefonos: [ ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'Asociación Peruana de Agentes Afiliados a Iata – APAAI',
  //     direccion: 'Av. 28 de Julio 675, Miraflores',
  //     telefonos: [ ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'ASOCIACIÓN PERUANA DE HOTELES, RESTAURANTES Y AFINES - AHORA',
  //     direccion: 'Av. José Larco Nº 730 Of. 503, Miraflores',
  //     telefonos: [ '998 327 728', '445 2762' ],
  //     web: 'http://www.ahora-peru.com/',
  //     image: '',
  //   },
  //   {
  //     nombre: 'ASOCIACIÓN PERUANA DE OPERADORES DE TURISMO RECEPTIVO E INTERNO - APOTUR',
  //     direccion: '',
  //     telefonos: [  ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'ASOCIACIÓN PERUANA DE TURISMO DE AVENTURA Y ECOTURISMO - APTAE',
  //     direccion: 'Calle San Fernando # 287, Miraflores',
  //     telefonos: [ '987 935 070' ],
  //     web: 'http://www.aptae.pe/',
  //     image: '',
  //   },
  //   {
  //     nombre: 'BURO DE CONVENCIONES Y VISITANTES DE LIMA',
  //     direccion: '',
  //     telefonos: [ '449 2653' ],
  //     web: 'http://www.limaconvention.com/',
  //     image: '',
  //   },
  //   {
  //     nombre: 'Peruanos Unidos por la Cocina y la Alimentación – PUCA',
  //     direccion: '',
  //     telefonos: [  ],
  //     web: '',
  //     image: '',
  //   },
  //   {
  //     nombre: 'SOCIEDAD HOTELES DEL PERÚ - SHP',
  //     direccion: 'Calle Andalucía Nro.174, Miraflores',
  //     telefonos: [ '962 784 574', '933 196 340' ],
  //     web: 'http://www.sociedadhotelesdelperu.org.pe/',
  //     image: '',
  //   },
  // ];

  gremios = [
    {
      nombre: 'AGOTUR',
      descripcion: 'Asociación de Guías Oficiales de Turismo',
      web: 'https://agoturlima.com/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0002_agoturLima.jpg?alt=media&token=97525fd3-e265-4771-90f4-27b08bef8902'
    },
    {
      nombre: 'BURÓ LIMA',
      descripcion: 'Buró de Convenciones y Visitantes de Lima',
      web: 'https://limaconvention.com/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0008_buroConvenciones.jpg?alt=media&token=27042aec-578d-4eb7-b2bd-d483985ace65'
    },
    {
      nombre: 'APOTUR',
      descripcion: 'Asociación Peruana de Operadores de Turismo Receptivo e Interno',
      web: 'http://apoturperu.org/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0005_apoturPeru.jpg?alt=media&token=0028d70c-cce0-4027-9654-d8a6a7328570'
    },
    {
      nombre: 'AHORA PERU',
      descripcion: 'Asociación Peruana de Hoteles, Restaurantes y Afines',
      web: 'http://ahora-peru.com/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0003_ahoraPeru.jpg?alt=media&token=da62a4d4-68d3-4b7d-8daf-8c2d3994ac9f'
    },
    {
      nombre: 'SHP',
      descripcion: 'Sociedad Hoteles del Perú',
      web: 'https://sociedadhotelesdelperu.org.pe/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0013_shpSociedad.jpg?alt=media&token=ce81d142-3cba-4ef5-bc61-abfbe1dabe54'
    },
    {
      nombre: 'APTAE',
      descripcion: 'Asociación Peruana de Turismo de Aventura y Ecoturismo',
      web: 'http://www.aptae.pe/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0006_aptae.jpg?alt=media&token=11054a8c-6592-43e2-8d64-ff693b4cd037'
    },
    {
      nombre: 'SONAJA',
      descripcion: 'Sociedad Nacional de Juegos de Azar',
      web: 'http://www.sonaja.org/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0012_logoSonaja.jpg?alt=media&token=2b0fbb07-8f3c-4826-aa74-94a24064ff0b'
    },
    {
      nombre: 'APAVIT',
      descripcion: 'Asociación Peruana de Agencias de Viajes y Turismo',
      web: 'https://apavitperu.org/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0004_apavit.jpg?alt=media&token=24e9c5d6-31f4-44eb-b510-cf68439515dc'
    },
    {
      nombre: 'AFEET PERÚ',
      descripcion: 'La Asociación de Ejecutivas de Empresas de Turismo del Perú',
      web: 'http://afeetperu.org/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0001_afeetPeru.jpg?alt=media&token=4991c60b-b97c-43da-9331-91d1aff02395'
    },
    {
      nombre: 'ASOSETTUR PERÚ',
      descripcion: 'Asociación de Operadores de Servicio Especial de Transporte Turístico del Perú',
      web: 'https://asosetturperu.org/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0007_asosetturPeru.jpg?alt=media&token=8ea15f00-bad9-41db-88e4-72ea1a09c8de'
    },
    {
      nombre: 'APAAI',
      descripcion: 'Asociación Peruana de Agentes Afiliados a la IATA',
      web: 'http://www.apaai.org/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0010_logoApaai.jpg?alt=media&token=06bf37ca-1fd2-498f-af39-1ecb3e367a6a'
    },
    // {
    //   nombre: 'APEA',
    //   descripcion: 'Asociación Peruana de Empresas Aéreas',
    //   web: '',
    // },
    {
      nombre: 'AFEP',
      descripcion: 'Asociación de Ferias del Perú',
      web: 'http://afep.pe/',
      logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0009_logoAfep.jpg?alt=media&token=305f6f0e-3741-4122-95fe-8e2faf624353'
    },
    // {
    //   nombre: 'PUCA',
    //   descripcion: 'Peruanos Unidos por la Cocina y la Alimentación',
    //   web: '',
    //   logo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremios%2F_0011_logoPuca.jpg?alt=media&token=32bff362-18ef-414f-a1d5-7f514f581b47'
    // },
    {
      nombre: 'APHI',
      descripcion: 'Administradora Peruana de Albergues',
      web: '',
      logo: ''
    },
  ];

  gremiosDark = [
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fafeet.jpg?alt=media&token=aa0bff67-3809-4d8c-8d37-94c6f9b16a25',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fafep.jpg?alt=media&token=b5a31256-a4b0-4892-a27c-7128658b2c2c',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fagotur.jpg?alt=media&token=f1452635-f9f2-4962-a4aa-6b57884dfbd0',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2FahoraPeru.jpg?alt=media&token=eeb3198d-9908-4f1f-b647-f7e1d83c72fa',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fapaai.jpg?alt=media&token=607f7bae-c754-4931-9f45-0cbc1cb418c5',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fapavit.jpg?alt=media&token=3a5bec43-93dd-4309-8089-15cf8bcd511d',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fapotur.jpg?alt=media&token=b60ed405-2c8c-4cec-972f-f1695349bb7e',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Faptae.jpg?alt=media&token=7100a3c9-c192-4249-bfea-3aa44148f066',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fasosettur.jpg?alt=media&token=0fb6e022-fcd9-4022-a367-8b50e58235e6',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2FburoConvenciones.jpg?alt=media&token=e09cf97e-e109-44ab-bb00-8726f4a67b19',
    // 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fpuca.jpg?alt=media&token=1a26b540-0e09-4a29-b4ab-250a831d4755',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2Fsonaja.jpg?alt=media&token=319f0a50-5cd2-4f5a-aa0e-8af145686c77',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosGremiosDark%2FsphSocieda.jpg?alt=media&token=3db51fd9-06ae-4452-8c08-72cba5220265',
  ];

  logosHelvetas = [
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FlogoSeCompetitivo.png?alt=media&token=aea864ce-0c3b-4dd2-ad66-d4a32b3722e5',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FlogoSeco.png?alt=media&token=fc0c3942-9b6f-4fda-b731-f59e4f7c1153',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FlogoHelvetas.png?alt=media&token=4df5f7ea-3a92-4322-8fc1-e868f749222b'
  ];

  iconsTypeFiles = [
    {
      nombre: 'pdf',
      icon: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/iconsTypesFiles%2Fpdf.svg?alt=media&token=6084a45e-9842-4866-96b6-711b6f97d872'
    },
    {
      nombre: 'xls',
      icon: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/iconsTypesFiles%2Fxls.svg?alt=media&token=0a55babc-52cd-4dda-800a-b882f49a0f6e'
    },
    {
      nombre: 'jpg',
      icon: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/iconsTypesFiles%2Fjpg.svg?alt=media&token=6cd7113a-10d7-4c05-afdd-e8ef84f251be'
    },
    {
      nombre: 'png',
      icon: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/iconsTypesFiles%2Fpng.svg?alt=media&token=68c495e2-5e53-40ce-8ca0-0098fb2799c2'
    },
  ]

  constructor() { }
}
