import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { IdValidatorService } from 'src/app/services/id-validator.service';
import { QueHacemos } from 'src/app/classes/que-hacemos';

@Component({
  selector: 'app-editar-que-hacemos',
  templateUrl: './editar-que-hacemos.component.html',
  styleUrls: ['./editar-que-hacemos.component.scss']
})
export class EditarQueHacemosComponent implements OnInit {

  formQueHacemos!: FormGroup;
  loading = false;
  queHacemosId: string;
  item: QueHacemos;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  today = new Date();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {

      this.queHacemosId = res.id;

      this.afs.doc(`queHacemos/${this.queHacemosId}`).valueChanges().subscribe( (data: QueHacemos) => {

        this.item = data;

        this.formQueHacemos = this.fb.group({
          titulo: [this.item.titulo, Validators.required],
          descripcion: [this.item.descripcion, Validators.required],
          image: ['', [FileValidator.maxContentSize(this.maxSize)]],
          imageName: [''],
          publicado: [this.item.publicado],
          fechaEdicion: [Timestamp.now()]
        });

      });



    });



  }

  // onSubmit() {
  //   if (this.formQueHacemos.valid) {
  //     this.loading = true;
  //     this.uploadImageAndCreateItem();
  //   } else {
  //     this.validateAllFormFields(this.formQueHacemos);
  //   }
  // }

  onSubmit() {
    if (this.formQueHacemos.valid) {
      this.loading = true;
      if (this.formQueHacemos.get('image')?.value === '') {
        this.actualizarNoticia();
      } else {
        this.uploadImageAndUpdateItem();
      }
    } else {
      this.validateAllFormFields(this.formQueHacemos);
    }
  }

  actualizarNoticia() {
    const noticia = this.formQueHacemos.value;
    ['image', 'imageName'].forEach(e => delete noticia[e]);

    this.afs.doc(`queHacemos/${this.queHacemosId}`).update(this.formQueHacemos.value)
    .then(() => {
      this.router.navigate(['/admin/nosotros/queHacemos']);
      console.log('Ítem Que Hacemos actualizado');
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  

  uploadImageAndUpdateItem() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `imagesQueHacemos/${this.queHacemosId}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        
        this.afs.doc(`queHacemos/${this.queHacemosId}`).update({
          ...this.formQueHacemos.value,
          image: url,
        });
        this.router.navigate(['/admin/nosotros/queHacemos']);
        console.log(url);



        });
        }
        )).subscribe();
  }

  errorImagen() {
    return this.formQueHacemos.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

  errorBody() {
    return this.formQueHacemos.controls.body.touched && this.formQueHacemos.controls.body.hasError('required') ? 'Incluye una descripción de la noticia' : '';
  }

}
