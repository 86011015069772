import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-crear-gremio',
  templateUrl: './crear-gremio.component.html',
  styleUrls: ['./crear-gremio.component.scss']
})
export class CrearGremioComponent implements OnInit {

  formGremio: UntypedFormGroup;
  loading = false;
  gremio: any;

  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  readonly maxSize = 1048576 * 5;
  actualSize: any;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
      {
        nombre: 'Gremios',
        url: '/admin/asociados/gremios'
      },
    ],
    back: '/admin/asociados/gremios',
    titulo: 'Crear Gremio',
    urlButton: ''
  };

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router
  ) {
      this.gremio = this.afs.collection('gremios').ref.doc();
      console.log(this.gremio.id);
  }


  ngOnInit(): void {
    this.formGremio = this.fb.group({
      nombre: ['', Validators.required],
      descripcion: ['', Validators.required],
      web: [''],
      imageLogo: ['', [FileValidator.maxContentSize(this.maxSize)]],
      imageLogoName: [''],
      publicado: [false],
      fechaCreacion: [Timestamp.now()]
    });
  }

  onSubmit() {
    if (this.formGremio.valid) {
      this.loading = true;
      if (this.formGremio.get('imageLogo').value === '') {
        this.crearGremio();
      } else {
        this.uploadLogoAndCreateGremio();
      }
    } else {
      this.validateAllFormFields(this.formGremio);
    }
  }

  crearGremio() {
    this.gremio.set(this.formGremio.value)
    .then(() => {
      this.router.navigate(['/admin/asociados/gremios']);
      console.log('Gremio creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadLogoAndCreateGremio() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `logosGremios/${this.gremio.id}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise().then( (url) => {

          const objectGremio = this.formGremio.value;
          delete objectGremio.imageLogo;
          this.gremio.set(objectGremio);
          this.gremio.set({
            imageLogo: url,
            imageLogoName: `${this.gremio.id}.${nombreImage[1]}`,
          }, {merge: true});
          this.router.navigate(['/admin/asociados/gremios']);
          console.log(url);
          
        }).catch(err => { console.log(err); } );
      })
    )
    .subscribe();
  }

  errorImagen() {
    return this.formGremio.controls.imageLogo.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

}
