import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FieldValue, arrayRemove, arrayUnion, doc, getDoc, getFirestore } from 'firebase/firestore';
import { Observable, merge } from 'rxjs';
import { FileItem } from 'src/app/classes/file-item';
import { FirebaseService } from 'src/app/services/firebase.service';
import * as firebase from "firebase/app";
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { DataFiles } from 'src/app/classes/data-files';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnChanges  {

  @Input() data: DataFiles;

  fotos: FileItem[] = [];
  disabled = true;
  uploadPercent: Observable<number> | undefined;
  downloadURL!: Observable<string>;
  urlsImages: any[] = [];
  resultados: any[] = [];
  loadingDelete = false;
  fotoTipo!: string;
  avisoSuccess = false;
  negocioId: string | undefined;
  imagesPreview = [];
  maxFotos = false;
  maxButton = true;
  // maxNumFiles = 5;
  fotosFirestore: any[] = [];
  visiblePreviewImages = true;
  sizeCollection: number | undefined;
  showButtonAddFile!: boolean;
  showAlert!: boolean;
  disabledUpload = true;
  totalFotos!: number;
  fileId!: string;
  imgPreview = false;
  iconPreview = false;
  subiendo: boolean;

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private fs: FirebaseService,
    // private uploadImages: UploadImagesService
  ) {}

  async ngOnInit() {

    console.log(this.data.maxFileSize);
    

    this.afs.doc(`${this.data.route}`).get().subscribe( (res) => {
      console.log(res.exists);
      if ( res.exists ) {

        this.getFilesFirestore();
        
        // this.afs.doc(`${this.data.route}`).set({
        //   files: []
        // }, { merge: true }).then( () => {
          
        // });

        } else {
          this.fotosFirestore.length = 0;
          this.showButtonAddFile = true;
      }
    }) ;
  
  }

  ngOnChanges(changes: SimpleChanges) {

    if ( changes.existe ) {
        
      this.afs.doc(`${this.data.route}`).valueChanges().subscribe( (res: any) => {
        this.fotosFirestore = res.files;
        this.fotos = [];
        this.checkNumFotos();
      })

      } else {

        this.fotosFirestore.length = 0;
        this.showButtonAddFile = true;

    }
    
  }

  getFilesFirestore() {
    this.afs.doc(`${this.data.route}`).valueChanges().subscribe( (res: any) => {

      // this.fotosFirestore = res.files;
      

      if (res.files) {
        this.fotosFirestore = res.files;
      } else {
        this.afs.doc(`${this.data.route}`).update({
          files: []
        })
      }

      this.fotos = [];
      this.checkNumFotos();

    })
  }



  getInputFile() {
    document.getElementById('formFileMultiple')?.click();
  }


  getFileDetails (event: any) {

    const files = event.target.files;

    for (let i = 0; i < files?.length; i++) {
      const nuevoArchivo = new FileItem(files[i]);
      this.fotos.push(nuevoArchivo);
      // Crear vista previa imagen
      nuevoArchivo.imgUrl = URL.createObjectURL(files[i]);
      // Asignar peso archivo
      nuevoArchivo.sizeArchivo = files[i].size;
      // Validar peso archivo
      nuevoArchivo.sizeArchivo < this.data.maxFileSize ? nuevoArchivo.sizeValidator = false : nuevoArchivo.sizeValidator = true;
      // Validar alerta número máximo de archivos
      this.totalFotos = this.fotosFirestore.length + this.fotos.length
      this.totalFotos > this.data.maxNumFiles ? nuevoArchivo.remove = true : nuevoArchivo.remove = false;


    }

    console.log(this.fotos);
    this.checkSizeValidator();
    this.checkNumFotos();
    // this.uploadFiles();

  }




  uploadFiles() {
    
    

      this.subiendo = true;

      // Guardar en Storage
      const promises = this.fotos.map( (file: any, i: number) => {


        this.fileId = this.afs.collection(`${this.data.route}/files`).doc().ref.id;
        const formatImage = file.nameArchivo.split('.').pop()
        const newNameArchivo = `${this.fileId}.${formatImage}`;

        const imageToServer = this.storage.ref(`${this.data.route}/${newNameArchivo}`).put(file.archivo, {
          customMetadata: {
            name: file.nameArchivo,
            type: file.typeArchivo,
            size: file.sizeArchivo.toString(),
          }
        });

        imageToServer.percentageChanges().subscribe( (res) => {
          file.progreso = res;
          console.log(res);
        });

        return imageToServer.then( (uploadTaskSnapshot: any) => {
          const nameFile = file.nameArchivo;
          const id = newNameArchivo;
          const sizeFile = file.sizeArchivo;
          return uploadTaskSnapshot.ref.getDownloadURL()
          .then( (url: any) => {
            console.log(url, nameFile);
            return { url, nameFile, sizeFile, id }
          });
        });

      });






      // Guardar en Firestore
      Promise.all(promises)
      .then( (response: any) => {

        console.log(response);

        response.map( (element: any, i: number) => {

          const fileComplete = { 
            nombre: element.nameFile,
            size: element.sizeFile,
            urlFile: element.url,
            fechaCreacion: new Date(),
            destacado: false,
            publicado: true,
            id: element.id
          }
          this.resultados.push(fileComplete);
        });

        console.log(this.resultados);

        this.afs.doc(`${this.data.route}`).set({
          files: arrayUnion(...this.resultados),
          lastModified: new Date()
        }, { merge: true })

        .then( () =>  {
          this.getFilesFirestore();
          this.subiendo = false;
        });

      
      })
      .catch( error => {
        console.log(error);
      });




  }

  checkNumFotos() {
    this.totalFotos = this.fotosFirestore.length + this.fotos.length;
    this.totalFotos < this.data.maxNumFiles ? this.showButtonAddFile = true : this.showButtonAddFile = false;
    this.totalFotos > this.data.maxNumFiles ? this.showAlert = true : this.showAlert = false;
  }

  checkSizeValidator() {
    const check = this.fotos.some( e => e.sizeValidator == true );
    // console.log(check);
    return check;
  }


  openFile(url) {
    window.open(url, '_blank');
  }


  removeItem(i: any) {
    this.fotos.splice(i, 1);
    this.checkNumFotos();
    // this.uploadFiles();
  }

  
  drop(event: CdkDragDrop<any[]>) {

    moveItemInArray(this.fotosFirestore, event.previousIndex, event.currentIndex);

    console.log(this.fotosFirestore);

    this.afs.doc(`${this.data.route}`).update({
      files: this.fotosFirestore
    }).then( () => {
      console.log('Orden actualizado');
    });
    

    // this.fotosFirestore.map( (element: any, index: number )=> {
    //   this.afs.collection(`${this.data.route}/images`).doc(element.id).update({
    //     order: index + 1
    //   });
    // });

    // this.updateFirstImage();

  }



  dropPreview(event: CdkDragDrop<any[]>) {

    moveItemInArray(this.fotos, event.previousIndex, event.currentIndex);

    console.log(this.fotos);

    // this.afs.doc(`${this.data.route}`).update({
    //   files: this.fotosFirestore
    // }).then( () => {
    //   console.log('Orden actualizado');
    // });
  

  }


  // deleteFileFirebase(item: { id: string | undefined; nameFile: any; }) {
  //   // Delete of Firestore
  //   this.afs.collection(`${this.data.route}/images`).doc(item.id).delete().then( () => {
  //     console.log('Eliminado de firestore');
  //     // Delete of Storage
  //     this.storage.ref(`${this.data.route}/${item.nameFile}`).delete().subscribe( res => {
  //       console.log('Eliminado de Storage');
  //     });
  //     // Update first file
  //     this.updateFirstImage();
  //   });
  // };


  deleteFileFirebase(file, i) {

    // Cargar loading
    const newFiles = this.fotosFirestore;
    const item  = newFiles.find( obj => obj.id === file.id );
    const newFile = {...item, loadingDelete: true};
    Object.assign(item, newFile);
    console.log(newFiles);

   
    // Eliminar de Storage y Firestore
    this.storage.ref(`${this.data.route}/${file.id}`).delete().subscribe( res => {

      delete file.loadingDelete;
      console.log('Eliminado de Storage');

      this.afs.doc(`${this.data.route}`).update({
        files: arrayRemove(file),
        lastModified: new Date()
      }).then( () => {
        console.log('Eliminado de Firestore');
        this.checkNumFotos();
      });

    });




  };


  updateFirstImage() {
    this.afs.doc(`${this.data.route}`).update({
      file: this.fotosFirestore[0].urlImage
    });
  };

  iconFile(fileName) {
    const ext = fileName.split('.').pop();
    if( ext === 'png' ||
        ext === 'jpg' ||
        ext === 'jpeg' ||
        ext === 'gif' ||
        ext === 'ico' ||
        ext === 'bmp' ||
        ext === 'tiff'
        ) {
      return true;
    } else {
      return false
    }
  }

}
