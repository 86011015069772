import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { EliminarEmpresaComponent } from '../eliminar-empresa/eliminar-empresa.component';

@Component({
  selector: 'app-lista-empresas',
  templateUrl: './lista-empresas.component.html',
  styleUrls: ['./lista-empresas.component.scss']
})
export class ListaEmpresasComponent implements OnInit {

  itemId = this.afs.collection('empresas').ref.doc().id;

  opciones = [
    {
      nombre: 'Editar',
      icon: 'pencil',
      function: (gremio) => this.editarItem(gremio)
    },
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (gremio) => this.eliminarItem(gremio)
    }
  ];

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
    ],
    back: '/admin/asociados',
    titulo: 'Empresas Asociadas',
    urlButton: `/admin/asociados/empresasAsociadas/crearEmpresa`
  }

  itemsGroup = [];
  


  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {

    // this.fs.getAllEmpresas().subscribe(data => {
    //   this.gremiosData.data = data;
    //   this.loading = false;
    //   this.count = data.length;
    // });


    // this.gremiosData.paginator = this.paginator;
    // this.gremiosData.sort = this.sort;

    
    this.fs.getAllEmpresas().subscribe( res => {
      const empresas = res;
      const xx = empresas.filter( obj => obj.categoria === 'Casinos' );
      console.log(xx);
    })



    this.fs.getAllEmpresas().subscribe( res => {
      const items = res;
      this.itemsGroup = items.reduce((prev, { categoria, ...items }) => {
        const id = prev.findIndex((item) => item.categoria === categoria);
        id >= 0
          ? prev[id].items.push(items)
          : prev.push({
            categoria,
            items: [items],
            nombre: categoria,
            url: categoria,
            // url: categoria.replace(/\s/g, '')
          });
        return prev;
      }, []);
      console.log(this.itemsGroup);
    });



  }


  actualizarPublicado(idGremio, change: MatSlideToggleChange) {
    this.afs.collection('empresas').doc(idGremio).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item) {
    return item.publicado;
  }

  editarItem(empresa) {
    this.router.navigate([`admin/asociados/empresas/editarEmpresa/${empresa.id}`])
  }

  eliminarItem(empresa) {
    this.matDialog.open(EliminarEmpresaComponent, {
      data: empresa
    });
  }

}
