<div class="text-end">
	<button mat-icon-button (click)="cancelar()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div class="ps-4">
	<div class="d-flex flex-column">
		<div *ngFor="let link of links" class="py-1">
			<a class="linkMenuMobile text-decoration-none py-1" [routerLink]="link.url"
				(click)="cancelar()">{{link.nombre}}</a>
			<div *ngFor="let submenu of link.submenu" class="pt-2 pl-2">
				<a class="text-decoration-none py-1" [routerLink]="submenu.url" (click)="cancelar()">
					<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-corner-down-right" width="18"
						height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#828282" fill="none" stroke-linecap="round"
						stroke-linejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" />
						<path d="M6 6v6a3 3 0 0 0 3 3h10l-5 -5m0 10l5 -5" />
					</svg> {{submenu.nombre}}
				</a>
			</div>

		</div>
		<a class="btn btn-block btn-outline-primary my-3" href="https://portaldeturismo.pe/"
			target="blank">portaldeturismo.pe</a>
		<a class="btn btn-block btn-dark" routerLink="/unete">ÚNETE</a>
	</div>

	<div class="text-muted mt-4 pr-4">
		<hr>
		<a class="text-reset" href="tel:5117155983"><strong>T.</strong> (511) 715 5983</a><br>
		<a class="text-reset" href="mailto:asistente@canaturperu.org"><strong>T.</strong> asistente@canaturperu.org</a><br>
		<a class="text-reset" href="https://goo.gl/maps/Bg8YWZuPtDevCQgK6">Av. Paseo de la República 6348, Miraflores -
			Lima</a>
	</div>
</div>