import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gremio } from 'src/app/classes/gremio';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-eliminar-empresa',
  templateUrl: './eliminar-empresa.component.html',
  styleUrls: ['./eliminar-empresa.component.scss']
})
export class EliminarEmpresaComponent implements OnInit {

  loading: boolean;

  constructor(
    private dialogRef: MatDialogRef<EliminarEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Gremio,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }


  eliminarGremio() {

    this.loading = true;

    // Eliminar logo gremio de storage
    if (this.data.imageLogoName === '' ) {
      console.log('no hay logo'); 
    } else {
      this.storage.ref(`logosEmpresas/${this.data.imageLogoName}`).delete();
      console.log('Logo empresa eliminado de Storage');
      
    }

    // Eliminar gremio de Firestore
    this.afs.collection('empresas').doc(this.data.id).delete()
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Empresa eliminada', 'CERRAR', {
        duration: 3000,
      });
      console.log('Empresa eliminada de Firestore');
    });

  }

  cancelar() {
    this.dialogRef.close();
  }

}
