<div class="position-relative h-100">
    <div class="buttonClose">

        <!-- <button class="btn btn-primary" (click)="cancelar()">
            <mat-icon class="border">close</mat-icon>
        </button> -->


        <button class="btn btn-primary myButtonCircleRedSocial" (click)="cancelar()">
            <div class="d-flex align-items-center justify-content-center h-100">
                 <mat-icon class="">close</mat-icon>
            </div>
        </button>


    </div>
    <a href="https://www.facebook.com/CamaraNacionalDeTurismoPERU/videos/1108822886281016" target="blank"><img class="sizeImageModalConferencia" src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/modalConferencia.jpg?alt=media&token=9f25fcd8-282a-47c4-b9ae-210325c11bdc" alt=""></a>

</div>