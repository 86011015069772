<div class="bgBosque">
  <div class="blur">
    <div class="container py-5 py-md-6">
      <p class="h2 mb-3 text-white">Videos</p>
      <div class="row g-3 g-md-5">
        <div class="col-md-4" *ngFor="let video of videosHel">
          <div class="ratio ratio-16x9">
            <iframe width="100%" height="auto" [src]="video.urlEmbed | sanitizeUrl" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
