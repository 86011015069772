<div class="d-flex justify-content-center align-items-center" style="height: 600px;" *ngIf="loader">
  <span class="myColorSpinnerSoatDetalle ">
      <mat-spinner class="text-center" [diameter]="36"></mat-spinner>
  </span>
</div>


<div class="container mb-4 mb-md-6" *ngIf="noticia">

  <nav class="small mt-2 mt-md-3 mb-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/noticias">Noticias</a></li>
      <li class="breadcrumb-item active text-muted">{{idNoticia}}</li>
    </ol>
  </nav>

  <div class="row justify-content-between">
    <!-- <div class="col-md-4">
      <img class="rounded" [src]="noticia.image || noticia.urlImage" alt="" width="100%">
    </div> -->
    <div class="col-md-7">
      <span class="small text-muted">Título</span>
      <p>{{noticia.titulo}}</p>
      <span class="small text-muted">Subtítulo</span>
      <p>{{noticia.subtitulo}}</p>
      <span class="small text-muted">Categoría</span>
      <p>{{noticia.categoria}}</p>
      <span class="small text-muted">Fecha</span>
      <p>{{noticia.fecha.toDate() | date: 'short'}}</p>
      <span class="small text-muted">Link noticia</span>
      <p>
        <a [href]="noticia.url">{{noticia.url}}</a>
      </p>
      <span class="small text-muted d-block">Imagen</span>
      <img class="imageNoticiaAdminEdit rounded" [src]="noticia.image || noticia.urlImage">
      <div class="mt-3">
        <button class="btn btn-primary" [routerLink]=" 'editar' ">Editar</button>
      </div>
    </div>
    
  </div>

</div>