<div class="container" *ngIf="gremio">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/asociados">Asociados</a></li>
			<li class="breadcrumb-item"><a routerLink="/admin/asociados/gremios">Gremios</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/admin/asociados/gremios">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Editar gremio</p>
  </div>

  <div class="col-md-6" >
    <form [formGroup]="formGremio" (submit)="onSubmit()" novalidate class="row">

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Nombre</mat-label>
        <input matInput class="text" formControlName="nombre">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Descripción</mat-label>
        <input matInput class="text" formControlName="descripcion">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Web</mat-label>
        <input matInput class="text" formControlName="web">
      </mat-form-field>

      <div class="d-flex">

        <!-- <div class="me-2 my-1">
          <img class="border rounded" [src]="gremio.imageLogo || 'assets/images/imageFakerCamara.png' " style="width: 59.5px; height: 59.5px; object-fit: cover;" >
        </div> -->


        <div class="contenedorImage me-2 my-1">
          <div class="loader imageItemAdmin rounded-start border" *ngIf="loadingLogo">
            <div class="d-flex justify-content-center align-items-center h-100">
              <span class="mySpinnerDark" >
                <mat-spinner diameter="24"></mat-spinner>
              </span>
            </div>
          </div>
          <div class="image">
            <img class="imageItemAdmin rounded border" [src]="gremio.imageLogo || 'assets/images/imageFakerCamara.png' " (load)="detectarCargado()">
          </div>
        </div>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Imagen logo</mat-label>
          <ngx-mat-file-input #removableInput formControlName="imageLogo" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
          <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
          <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
          <mat-hint>Tamaño mínimo: 160 x 160 px.</mat-hint>
          <!-- <mat-hint align="end">Peso: Hasta 5 MB</mat-hint> -->
        </mat-form-field>

      </div>

      <div class="buttonSubmit mt-4 mt-md-5">
        <button class="btn btn-primary w-100">
          <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
            <mat-spinner class="" [diameter]="24"></mat-spinner>
          </span>
          <span *ngIf="!loading">Guardar cambios</span>
        </button>
      </div>
    </form>
  </div>

  <!-- <pre>{{formGremio.value | json}}</pre> -->

</div>