<nav class="container small mt-3">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
        <li class="breadcrumb-item active text-muted">Asociados</li>
    </ol>
</nav>
<div class="container py-4 py-md-5">
    <p class="h1 text-center pb-4 pb-md-6">Asociados</p>
    <div class="row">
        <div class="col-md-4 text-center" *ngFor="let asociado of asociados">
            <img class="croppedImageAsociados" [src]="asociado.image" alt="">
            <p class="textAsociadosHome text-muted my-2">- Asociados -</p>
            <p class="h4 font-weight-bold mb-0 px-6">{{asociado.nombre}}</p>
            <a class="btn btn-light btn-circle mt-3" [routerLink]="asociado.url">
                <div class="d-flex align-items-center justify-content-center h-100">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#E77917" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="13" y1="18" x2="19" y2="12" />
                <line x1="13" y1="6" x2="19" y2="12" />
                </svg>
                </div>

            </a>
        </div>
    </div>
</div>