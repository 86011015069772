import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { map } from 'rxjs/operators';
import { BannerFtp } from '../classes/banner-ftp';
import { BannerHel } from '../classes/banner-hel';
import { Boletin } from '../classes/boletin';
import { DocumentoHel } from '../classes/documento-hel';
import { Empresa } from '../classes/empresa';
import { EmpresaAsociada } from '../classes/empresa-asociada';
import { Gremio } from '../classes/gremio';
import { Noticia } from '../classes/noticia';
import { NoticiaHel } from '../classes/noticia-hel';
import { VideoHel } from '../classes/video-hel';
import { CamaraRegional } from '../classes/camara-regional';
import { Evento } from '../classes/evento';
import { FileItem } from '../classes/file-item';
import { Alianza } from '../classes/alianza';
import { Estatuto } from '../classes/estatuto';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  addSuscripcion(dataSuscripcion) {
    return this.afs.collection('suscripciones').add(dataSuscripcion);
  }

  addEmpresaAsociada(dataFormEmpresaAsociada) {
    return this.afs.collection('empresasAsociadas').add(dataFormEmpresaAsociada);
  }

  addNoticia(dataNoticia) {
    return this.afs.collection('noticias').add(dataNoticia);
  }

  getAllNoticias() {
    return this.afs.collection('noticias', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Noticia;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getNoticiasDestacadas() {
    return this.afs.collection('noticias', ref => ref
      // .where('publicado', '==', true)
      .where('destacado', '==', true)
      .orderBy('fecha', 'desc')
      .limit(6)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Noticia;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getNoticias() {
    return this.afs.collection('noticias', ref => ref
      .where('publicado', '==', true)
      .orderBy('fecha', 'desc')
      .limit(8)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Noticia;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }



  updatePublicado(idNoticia, publicado) {
    this.afs.doc('noticias/' + idNoticia).update({ publicado });
  }

  updateDestacado(idNoticia, destacado) {
    this.afs.doc('noticias/' + idNoticia).update({ destacado });
  }

  updateNoticia(idNoticia, dataNoticia) {
    return this.afs.collection('noticias').doc(idNoticia).update(dataNoticia);
  }

  deleteNoticia(idNoticia) {
    return this.afs.collection('noticias').doc(idNoticia).delete();
  }

  deleteNoticiaStorage(idNoticia, imageName) {
    return this.storage.ref(`imagesNoticias/${idNoticia}/${imageName}`).delete();
  }

  getAllAsociados() {
    return this.afs.collection('empresasAsociadas', ref => ref
    // .where('categoria', '==', 'Agencia de viajes')
    // .orderBy('nombre', 'asc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as EmpresaAsociada;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }


  getEmpresasAsociadasAgenciasViaje() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Agencia de viajes')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasAsociadasCasinos() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Casinos')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasAsociadasHoteles() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Hoteles')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasAsociadasInstitutosUniversidades() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Institutos y Universidades')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasAsociadasProveedores() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Proveedores')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasAsociadasRestaurantes() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Restaurantes')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasAsociadasTransporte() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Transporte')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasAsociadasTrenes() {
    return this.afs.collection('empresasAsociadas', ref => ref
      .where('categoria', '==', 'Trenes')
      .where('publicado', '==', true)
      .orderBy('nombre', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as EmpresaAsociada;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  addAfiliacionCanatur(idAfiliacion, dataAfiliacion) {
    return this.afs.collection('afiliacionesCanatur').doc(idAfiliacion).set(dataAfiliacion);
  }

  addAfiliacionMarketplace(dataAfiliacion) {
    return this.afs.collection('afiliacionesMarketplace').add(dataAfiliacion);
  }

  getAfiliacionesCanatur() {
    return this.afs.collection('afiliacionesCanatur', ref => ref
      // .where('categoria', '==', 'Trenes')
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAfiliacionCanatur(id) {
    return this.afs.doc('afiliacionesCanatur/' + id).valueChanges();
  }



  getAllGremios() {
    return this.afs.collection('gremios', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Gremio;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  updatePublicadoGremio(idGremio, publicado) {
    this.afs.doc('gremios/' + idGremio).update({ publicado });
  }

  getGremios() {
    return this.afs.collection('gremios', ref => ref
      .where( 'publicado', '==', true )
      .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Gremio;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllCamarasRegionales() {
    return this.afs.collection('camarasRegionales', ref => ref
    .orderBy('nombre', 'asc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as CamaraRegional;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }

  getCamarasRegionales() {
    return this.afs.collection('camarasRegionales', ref => ref
    .where( 'publicado', '==', true )
    .orderBy('nombre', 'asc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as CamaraRegional;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }

  getAllEventos() {
    return this.afs.collection('eventos', ref => ref
    .orderBy('lastModified', 'desc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Evento;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }

  getEventos() {
    return this.afs.collection('eventos', ref => ref
    .where( 'publicado', '==', true )
    .orderBy('nombre', 'asc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Evento;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }

  getAllAlianzas() {
    return this.afs.collection('alianzas', ref => ref
    .orderBy('lastModified', 'desc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Alianza;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }

  getAlianzas() {
    return this.afs.collection('alianzas', ref => ref
    .where( 'publicado', '==', true )
    .orderBy('nombre', 'asc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Alianza;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }

  getAllEstatutos() {
    return this.afs.collection('estatutos', ref => ref
    .orderBy('lastModified', 'desc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Estatuto;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }

  getEstatutos() {
    return this.afs.collection('estatutos', ref => ref
    .where( 'publicado', '==', true )
    .orderBy('nombre', 'asc')
  ).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Estatuto;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
  }


  getAllEmpresas() {
    return this.afs.collection('empresas', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Empresa;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  // getItemsDocument(idNegocio: string) {
  //   return this.afs.doc('negocios/' + idNegocio).collection('items', ref => ref
  //   .where('publicado', '==', true )
  //   // .orderBy('categoria')
  //   .orderBy('fechaCreacion')
  //   ).snapshotChanges().pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data() as Item;
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     }))
  // );
  // }

  
  getEmpresas() {
    return this.afs.collection('empresas', ref => ref
      .where( 'publicado', '==', true )
      .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Empresa;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getEmpresasByCategoria(categoria) {
    return this.afs.collection('empresas', ref => ref
      .where( 'categoria', '==', categoria )
      .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Empresa;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getAllNoticiasHel() {
    return this.afs.collection('noticiasHel', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as NoticiaHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getNoticiasHel() {
    return this.afs.collection('noticiasHel', ref => ref
    .where('publicado', '==', true)
      .orderBy('fecha', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as NoticiaHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getNoticiasHelDestacados() {
    return this.afs.collection('noticiasHel', ref => ref
    .where('publicado', '==', true)
    .orderBy('fecha', 'desc')
    .limit(3)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as NoticiaHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getAllDocumentosHel() {
    return this.afs.collection('documentosHel', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as DocumentoHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getDocumentosHel() {
    return this.afs.collection('documentosHel', ref => ref
    .where('publicado', '==', true)
    .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as DocumentoHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getAllBannersFtp() {
    return this.afs.collection('bannersFtp', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as BannerFtp;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getBannersFtp() {
    return this.afs.collection('bannersFtp', ref => ref
    .where('publicado', '==', true)
    .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as BannerFtp;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllVideosHel() {
    return this.afs.collection('videosHel', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as VideoHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getVideosHel() {
    return this.afs.collection('videosHel', ref => ref
    .where('publicado', '==', true)
    .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as VideoHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getVideosHelDestacados() {
    return this.afs.collection('videosHel', ref => ref
    .where('destacado', '==', true)
    .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as VideoHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllBannersHel() {
    return this.afs.collection('bannersHel', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as BannerHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getBannersHel() {
    return this.afs.collection('bannersHel', ref => ref
    .where('publicado', '==', true)
    .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as BannerHel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllBoletines() {
    return this.afs.collection('boletines', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Boletin;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getBoletines() {
    return this.afs.collection('boletines', ref => ref
    .where('publicado', '==', true)
    .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Boletin;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllImagesItem(data: any) {
    return this.afs.doc(`${data.route}`).collection('images', ref => ref
      .orderBy('order', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as FileItem;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }



}
