import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-lista-afiliaciones-canatur',
  templateUrl: './lista-afiliaciones-canatur.component.html',
  styleUrls: ['./lista-afiliaciones-canatur.component.scss']
})
export class ListaAfiliacionesCanaturComponent implements OnInit {

  count: number;
  loading: boolean;
  displayedColumns = [ 'id', 'fechaCreacion', 'nombres', 'apellidos', 'telefono', 'email', 'ruc', 'razonSocial' ];
  afiliacionesData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.afiliacionesData.filter = filterValue;
  }

  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fs.getAfiliacionesCanatur().subscribe(data => {
      this.afiliacionesData.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.afiliacionesData.paginator = this.paginator;
    this.afiliacionesData.sort = this.sort;
  }

  // openModalCrear() {
  //   const dialogRef = this.dialog.open(CrearNoticiaComponent, {
  //     panelClass: 'modalFullScreen',
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }


  // eliminarAviso(idProduct) {
  //   this.fs.deleteNoticia(idProduct);
  // }

  // openModalDetalle(producto) {
  //   const dialogRef = this.dialog.open(NoticiaDetalleAdminComponent, {
  //     panelClass: 'modalFullScreen',
  //     data: producto
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }

  // openModalDelete(producto) {
  //   const dialogRef = this.dialog.open(EliminarNoticiaComponent, {
  //     data: producto
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }

  // openModalEdit(producto) {
  //   const dialogRef = this.dialog.open(EditarNoticiaComponent, {
  //     panelClass: 'modalFullScreen',
  //     data: producto
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.afiliacionesData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  actualizarPublicado(key, obj, e) {
    this.fs.updatePublicado(key, e);
  }

  actualizarDestacado(key, obj, e) {
    this.fs.updateDestacado(key, e);
  }

  trackByPublicado(item) {
    return item.publicado;
  }

}
