<div class="container">

  <!-- <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/asociados">Asociados</a></li>
			<li class="breadcrumb-item"><a routerLink="/admin/asociados/gremios">Gremios</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3">
    <button mat-icon-button routerLink="/admin/asociados/gremios">
      <i-tabler name="arrow-left" class="me-1"></i-tabler>
    </button>
    <p class="h4 mb-0">Crear gremio</p>
  </div> -->


  <app-header-section [seccion]="seccion"></app-header-section>



  <div class="col-md-6">
    <form [formGroup]="formGremio" (submit)="onSubmit()" novalidate class="row">

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Nombre</mat-label>
        <input matInput class="text" formControlName="nombre">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Descripción</mat-label>
        <input matInput class="text" formControlName="descripcion">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Web</mat-label>
        <input matInput class="text" formControlName="web">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Imagen logo</mat-label>
        <ngx-mat-file-input #removableInput formControlName="imageLogo" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
        <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
          <mat-icon>clear</mat-icon>
        </button>
        <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
        <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
        <mat-hint>Recomendado: 160 x 160</mat-hint>
        <mat-hint align="end">Peso máximo: 5 MB</mat-hint>
      </mat-form-field>

      <div class="buttonSubmit mt-4 mt-md-5">
        <button class="btn btn-primary w-100">
          <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
            <mat-spinner class="" [diameter]="24"></mat-spinner>
          </span>
          <span *ngIf="!loading">Crear gremio</span>
        </button>
      </div>
    </form>
  </div>

  <!-- <pre>{{formGremio.value | json}}</pre> -->

</div>