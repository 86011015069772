<div class="d-flex align-items-center border p-3">

  <div class="me-2">
    <img class="imageEmpresaAsociada" [src]="empresa.imageLogo || 'assets/images/imageFakerCamara.png' ">
  </div>

  <!-- <div class="me-2 ratio ratio-1x1 bg-light imageEmpresaAsociada">
    <img class="object-fit-contain" [src]="empresa.imageLogo || 'assets/images/imageFakerCamara.png' ">
  </div> -->

  <div class="ms-0 ms-md-3">
    <p class="mb-1 h5 text-uppercase">{{empresa.nombre}}</p>
    <p class="text-muted lh-1">{{empresa.direccion}}</p>
    <a class="" [href]="empresa.web" target="blank" *ngIf="empresa.web != ''"
      style="word-break: break-all;">{{empresa.web}}</a>
    <br>
    <a class="" [href]=" 'tel:' + empresa.telefono" target="blank">{{empresa.telefono}}</a>
  </div>

</div>