export class Noticia {
    id?: string;
    categoria?: string;
    destacado?: boolean;
    fechaCreacion?: string;
    image?: string;
    imageName?: string;
    publicado?: boolean;
    subtitulo?: string;
    titulo?: string;
}
