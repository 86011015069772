<div class="container py-4 py-md-6">
	<div class="text-center">
		<p class="h1">Noticias</p>
		<p class="lead">Entérate de todas las noticias relacionadas con el turismo peruano.</p>
		<a class="btn btn-primary btn-lg px-4 py-3 my-4" href="https://portaldeturismo.pe/" target="blank">
			<div class="d-flex align-items-center">
				IR A PORTALDETURISMO.PE
				<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-external-link ms-2" width="36"
					height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#242424" fill="none" stroke-linecap="round"
					stroke-linejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" />
					<path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
					<line x1="10" y1="14" x2="20" y2="4" />
					<polyline points="15 4 20 4 20 9" />
				</svg>
			</div>
		</a>
	</div>

</div>