import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './components/public/public.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { ExpoTurismoComponent } from './components/expo-turismo/expo-turismo.component';
import { AsociadosComponent } from './components/asociados/asociados.component';
import { GremiosComponent } from './components/gremios/gremios.component';
import { CamarasRegionalesComponent } from './components/camaras-regionales/camaras-regionales.component';
import { EmpresasAsociadasComponent } from './components/empresas-asociadas/empresas-asociadas.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { UneteComponent } from './components/unete/unete.component';
import { PrensaComponent } from './components/prensa/prensa.component';
import { CrearEmpresaAsociadaComponent } from './components/asociados/crear-empresa-asociada/crear-empresa-asociada.component';
import { EstatutosComponent } from './components/estatutos/estatutos.component';
import { AlianzasComponent } from './components/alianzas/alianzas.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminNoticiasComponent } from './components/admin/noticias/admin-noticias/admin-noticias.component';
import { ListaAfiliacionesCanaturComponent } from './components/admin/afiliaciones-canatur/lista-afiliaciones-canatur/lista-afiliaciones-canatur.component';
import { InicioAdminComponent } from './components/admin/inicio-admin/inicio-admin.component';
import { LoginComponent } from './components/login/login.component';

import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { ListaSuscripcionesNoticiasComponent } from './components/admin/suscripciones-noticias/lista-suscripciones-noticias/lista-suscripciones-noticias.component';
import { GraciasUneteComponent } from './components/gracias-unete/gracias-unete.component';
import { HelvetasComponent } from './components/helvetas/helvetas.component';
import { CrearNoticiaComponent } from './components/admin/noticias/crear-noticia/crear-noticia.component';
import { LeerNoticiaComponent } from './components/admin/noticias/leer-noticia/leer-noticia.component';
import { EditarNoticiaComponent } from './components/admin/noticias/editar-noticia/editar-noticia.component';
import { ListaAsociadosComponent } from './components/admin/asociados/lista-asociados/lista-asociados.component';
import { XvcntComponent } from './components/xvcnt/xvcnt.component';
import { CrearGremioComponent } from './components/admin/asociados/gremios/crear-gremio/crear-gremio.component';
import { ListaGremiosComponent } from './components/admin/asociados/gremios/lista-gremios/lista-gremios.component';
import { EditarGremioComponent } from './components/admin/asociados/gremios/editar-gremio/editar-gremio.component';
import { ListaEmpresasComponent } from './components/admin/asociados/empresas/lista-empresas/lista-empresas.component';
import { CrearEmpresaComponent } from './components/admin/asociados/empresas/crear-empresa/crear-empresa.component';
import { EditarEmpresaComponent } from './components/admin/asociados/empresas/editar-empresa/editar-empresa.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { ListaNoticiasHelComponent } from './components/admin/helvetas/noticias/lista-noticias-hel/lista-noticias-hel.component';
import { CrearNoticiaHelComponent } from './components/admin/helvetas/noticias/crear-noticia-hel/crear-noticia-hel.component';
import { HelvetasAdminComponent } from './components/admin/helvetas/helvetas-admin/helvetas-admin.component';
import { EditarNoticiaHelComponent } from './components/admin/helvetas/noticias/editar-noticia-hel/editar-noticia-hel.component';
import { DetalleNoticiaHelComponent } from './components/detalle-noticia-hel/detalle-noticia-hel.component';
import { ListaBannersFtpComponent } from './components/admin/ftp/bannersFtp/lista-banners-ftp/lista-banners-ftp.component';
import { CrearBannerFtpComponent } from './components/admin/ftp/bannersFtp/crear-banner-ftp/crear-banner-ftp.component';
import { EditarBannerFtpComponent } from './components/admin/ftp/bannersFtp/editar-banner-ftp/editar-banner-ftp.component';
import { ListaDocumentosComponent } from './components/admin/helvetas/documentos/lista-documentos/lista-documentos.component';
import { CrearDocumentoComponent } from './components/admin/helvetas/documentos/crear-documento/crear-documento.component';
import { EditarDocumentoComponent } from './components/admin/helvetas/documentos/editar-documento/editar-documento.component';
import { FtpComponent } from './components/ftp/ftp.component';
import { ListaVideosComponent } from './components/admin/helvetas/videos/lista-videos/lista-videos.component';
import { CrearVideoComponent } from './components/admin/helvetas/videos/crear-video/crear-video.component';
import { EditarVideoComponent } from './components/admin/helvetas/videos/editar-video/editar-video.component';
import { ListaBannersComponent } from './components/admin/helvetas/banners/lista-banners/lista-banners.component';
import { CrearBannerComponent } from './components/admin/helvetas/banners/crear-banner/crear-banner.component';
import { EditarBannerComponent } from './components/admin/helvetas/banners/editar-banner/editar-banner.component';
import { HelvetasNoticiasComponent } from './components/helvetas-noticias/helvetas-noticias.component';
import { ListaBoletinesComponent } from './components/admin/boletines/lista-boletines/lista-boletines.component';
import { CrearBoletinComponent } from './components/admin/boletines/crear-boletin/crear-boletin.component';
import { EditarBoletinComponent } from './components/admin/boletines/editar-boletin/editar-boletin.component';
import { BoletinesComponent } from './components/boletines/boletines.component';
import { PoliticaAplicativoMovilComponent } from './components/politica-aplicativo-movil/politica-aplicativo-movil.component';
import { ListaQueHacemosComponent } from './components/admin/nosotros-admin/que-hacemos/lista-que-hacemos/lista-que-hacemos.component';
import { CrearQueHacemosComponent } from './components/admin/nosotros-admin/que-hacemos/crear-que-hacemos/crear-que-hacemos.component';
import { EditarQueHacemosComponent } from './components/admin/nosotros-admin/que-hacemos/editar-que-hacemos/editar-que-hacemos.component';
import { NosotrosAdminComponent } from './components/admin/nosotros-admin/nosotros-admin.component';
import { ListaDirectivosComponent } from './components/admin/nosotros-admin/junta-directiva/lista-directivos/lista-directivos.component';
import { CrearDirectivoComponent } from './components/admin/nosotros-admin/junta-directiva/crear-directivo/crear-directivo.component';
import { EditarDirectivoComponent } from './components/admin/nosotros-admin/junta-directiva/editar-directivo/editar-directivo.component';
import { ListaCamarasRegionalesComponent } from './components/admin/asociados/camaras-regionales/lista-camaras-regionales/lista-camaras-regionales.component';
import { CrearCamaraRegionalComponent } from './components/admin/asociados/camaras-regionales/crear-camara-regional/crear-camara-regional.component';
import { EditarCamaraRegionalComponent } from './components/admin/asociados/camaras-regionales/editar-camara-regional/editar-camara-regional.component';
import { ListaEventosComponent } from './components/admin/eventos/lista-eventos/lista-eventos.component';
import { CrearEventoComponent } from './components/admin/eventos/crear-evento/crear-evento.component';
import { EditarEventoComponent } from './components/admin/eventos/editar-evento/editar-evento.component';
import { ListaAlianzasComponent } from './components/admin/alianzas/lista-alianzas/lista-alianzas.component';
import { CrearAlianzaComponent } from './components/admin/alianzas/crear-alianza/crear-alianza.component';
import { EditarAlianzaComponent } from './components/admin/alianzas/editar-alianza/editar-alianza.component';
import { ListaEstatutosComponent } from './components/admin/estatutos/lista-estatutos/lista-estatutos.component';
import { CrearEstatutoComponent } from './components/admin/estatutos/crear-estatuto/crear-estatuto.component';
import { EditarEstatutoComponent } from './components/admin/estatutos/editar-estatuto/editar-estatuto.component';
import { CategoriasEmpresasComponent } from './components/admin/asociados/empresas/categorias-empresas/categorias-empresas.component';
import { VxicntComponent } from './components/vxicnt/vxicnt.component';
import { SetEmpresaComponent } from './components/admin/asociados/empresas/set-empresa/set-empresa.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);


const routes: Routes = [
  {
    path: '', component: PublicComponent,
    children: [
      { path: '', component: InicioComponent },
      { path: 'nosotros', component: NosotrosComponent },
      { path: 'estatutos', component: EstatutosComponent },
      { path: 'eventos/eventos', component: ExpoTurismoComponent },
      { path: 'eventos/xvCongresoNacionalTurismo', component: XvcntComponent },
      { path: 'asociados', component: AsociadosComponent },
      { path: 'asociados/gremios', component: GremiosComponent },
      { path: 'asociados/camarasRegionales', component: CamarasRegionalesComponent },
      { path: 'asociados/empresas', component: EmpresasComponent },
      // { path: 'asociados/empresasAsociadas', component: EmpresasAsociadasComponent },
      { path: 'marketplace', component: MarketplaceComponent },
      { path: 'alianzas', component: AlianzasComponent },
      { path: 'helvetas', component: HelvetasComponent },
      { path: 'helvetas/noticias', component: HelvetasNoticiasComponent },
      { path: 'helvetas/:id', component: DetalleNoticiaHelComponent },
      { path: 'noticias', component: PrensaComponent },
      { path: 'unete', component: UneteComponent },
      { path: 'unete/:id', component: GraciasUneteComponent },
      { path: 'feriaturismoperu2022', component: FtpComponent },
      { path: 'boletines', component: BoletinesComponent },
      { path: 'north-amazonian-birds-peru/politicas-de-privacidad', component: PoliticaAplicativoMovilComponent },
    ]
  },
  { path: 'login', component: LoginComponent},
  { path: 'xviCongresoNacionalTurismo', component: VxicntComponent },
  {
    path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      
      { path: '', component: InicioAdminComponent },
      { path: 'crearEmpresaAsociada', component: CrearEmpresaAsociadaComponent },

      { path: 'noticias', component: AdminNoticiasComponent },
      { path: 'noticias/crear', component: CrearNoticiaComponent },
      { path: 'noticias/:id', component: LeerNoticiaComponent },
      { path: 'noticias/:id/editar', component: EditarNoticiaComponent },

      { path: 'estatutos', component: ListaEstatutosComponent},
      { path: 'estatutos/crearEstatuto/:id', component: CrearEstatutoComponent},
      { path: 'estatutos/editarEstatuto/:id', component: EditarEstatutoComponent },

      { path: 'asociados', component: ListaAsociadosComponent },

      { path: 'asociados/gremios', component: ListaGremiosComponent },
      { path: 'asociados/gremios/crearGremio', component: CrearGremioComponent },
      { path: 'asociados/gremios/editarGremio/:id', component: EditarGremioComponent },

      { path: 'asociados/camarasRegionales', component: ListaCamarasRegionalesComponent},
      { path: 'asociados/camarasRegionales/crearCamaraRegional', component: CrearCamaraRegionalComponent },
      { path: 'asociados/camarasRegionales/editarCamaraRegional/:id', component: EditarCamaraRegionalComponent },


      { path: 'asociados/empresasAsociadas', component: ListaEmpresasComponent },
      { path: 'asociados/empresasAsociadas/crearEmpresa', component: CrearEmpresaComponent},
      { path: 'asociados/empresasAsociadas/:categoria/editarEmpresa/:id', component: EditarEmpresaComponent },
      // { path: 'asociados/empresasAsociadas/crearEmpresa/:id', component: SetEmpresaComponent},
      // { path: 'asociados/empresasAsociadas/:categoria/editarEmpresa/:id', component: SetEmpresaComponent},


      { path: 'asociados/empresasAsociadas/:categoria', component: CategoriasEmpresasComponent },

      { path: 'eventos', component: ListaEventosComponent},
      { path: 'eventos/crearEvento/:id', component: CrearEventoComponent },
      { path: 'eventos/editarEvento/:id', component: EditarEventoComponent },

      { path: 'alianzas', component: ListaAlianzasComponent},
      { path: 'alianzas/crearAlianza/:id', component: CrearAlianzaComponent},
      { path: 'alianzas/editarAlianza/:id', component: EditarAlianzaComponent },

      { path: 'afiliacionesCanatur', component: ListaAfiliacionesCanaturComponent },
      { path: 'suscripcionesNoticias', component: ListaSuscripcionesNoticiasComponent },

      { path: 'helvetas', component: HelvetasAdminComponent },

      { path: 'helvetas/noticias', component: ListaNoticiasHelComponent },
      { path: 'helvetas/noticias/crearNoticia', component: CrearNoticiaHelComponent },
      { path: 'helvetas/noticias/editarNoticia/:id', component: EditarNoticiaHelComponent },

      { path: 'helvetas/documentos', component: ListaDocumentosComponent },
      { path: 'helvetas/documentos/crearDocumento', component: CrearDocumentoComponent },
      { path: 'helvetas/documentos/editarDocumento/:id', component: EditarDocumentoComponent },

      { path: 'helvetas/banners', component: ListaBannersComponent },
      { path: 'helvetas/banners/crearBanner', component: CrearBannerComponent },
      { path: 'helvetas/banners/editarBanner/:id', component: EditarBannerComponent },

      { path: 'helvetas/videos', component: ListaVideosComponent },
      { path: 'helvetas/videos/crearVideo', component: CrearVideoComponent },
      { path: 'helvetas/videos/editarVideo/:id', component: EditarVideoComponent },

      { path: 'eventos/ftp/bannersFtp', component: ListaBannersFtpComponent },
      { path: 'eventos/ftp/bannersFtp/crearBannerFtp', component: CrearBannerFtpComponent },
      { path: 'eventos/ftp/bannersFtp/editarBannerFtp/:id', component: EditarBannerFtpComponent },

      { path: 'boletines', component: ListaBoletinesComponent },
      { path: 'boletines/crearBoletin', component: CrearBoletinComponent },
      { path: 'boletines/editarBoletin/:id', component: EditarBoletinComponent },

      { path: 'nosotros', component: NosotrosAdminComponent},

      { path: 'nosotros/queHacemos', component: ListaQueHacemosComponent },
      { path: 'nosotros/queHacemos/crearQueHacemos', component: CrearQueHacemosComponent },
      { path: 'nosotros/queHacemos/editarQueHacemos/:id', component: EditarQueHacemosComponent },

      { path: 'nosotros/juntaDirectiva', component: ListaDirectivosComponent },
      { path: 'nosotros/juntaDirectiva/crearDirectivo', component: CrearDirectivoComponent },
      { path: 'nosotros/juntaDirectiva/editarDirectivo/:id', component: EditarDirectivoComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
