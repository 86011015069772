import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { DataService } from 'src/app/services/data.service';
import { Boletin } from 'src/app/classes/boletin';


@Component({
  selector: 'app-editar-boletin',
  templateUrl: './editar-boletin.component.html',
  styleUrls: ['./editar-boletin.component.scss']
})
export class EditarBoletinComponent implements OnInit {

  formBoletin!: FormGroup;
  loading = false;
  boletinId: string;
  boletin: Boletin;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 50;
  actualSize: any;
  today = new Date();
  iconItem: any;
  iconUrl: string;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ds: DataService
  ) {
      this.boletinId = this.afs.collection('boletines').ref.doc().id;
      console.log(this.boletinId); 
  }

  
  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {
      this.boletinId = res.id;

      this.afs.doc(`boletines/${this.boletinId}`).valueChanges().subscribe( (data: Boletin) => {

        this.boletin = data;

        this.iconItem = this.ds.iconsTypeFiles.find( find => this.boletin.typeFile === find.nombre);
        this.iconUrl = this.iconItem.icon;

        this.formBoletin = this.fb.group({
          archivo: ['', [FileValidator.maxContentSize(this.maxSize)]],
          archivoName: [''],
          titulo: [this.boletin.titulo, Validators.required],
          // id: [this.boletinId],
          // publicado: [this.boletin.id],
          // fechaCreacion: [Timestamp.now()],
          fechaEdicion: [Timestamp.now()]
        });
      })
    }); 

   

  }

  onSubmit() {
    if (this.formBoletin.valid) {
      this.loading = true;
      if (this.formBoletin.get('archivo')?.value === '') {
        this.crearboletin();
      } else {
        this.uploadImageAndCreateboletin();
      }
    } else {
      this.validateAllFormFields(this.formBoletin);
    }
  }

  crearboletin() {
    const documento = this.formBoletin.value;
    ['archivo', 'archivoName'].forEach(e => delete documento[e]);
    this.afs.doc(`boletines/${this.boletinId}`).update(this.formBoletin.value)
    .then(() => {
      this.router.navigate(['/admin/boletines']);
      console.log('Banner FTP creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadImageAndCreateboletin() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `boletines/${this.nameItem}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`boletines/${this.boletinId}`).update({
          ...this.formBoletin.value,
          archivo: url,
          archivoName: this.nameItem,
          typeFile: nombreImage[1]
        });
        this.router.navigate(['/admin/boletines']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  errorArchivo() {
    return this.formBoletin.controls.archivo.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

}
