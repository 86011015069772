import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crear-camara-regional',
  templateUrl: './crear-camara-regional.component.html',
  styleUrls: ['./crear-camara-regional.component.scss']
})
export class CrearCamaraRegionalComponent implements OnInit {

  formCamaraRegional: FormGroup;
  disabled = true;
  loading = false;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
      {
        nombre: 'Cámaras Regionales',
        url: '/admin/asociados/camarasRegionales'
      },
    ],
    back: '/admin/asociados/camarasRegionales',
    titulo: 'Agregar Cámara Regional',
    urlButton: ''
  };

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.formCamaraRegional = this.fb.group({
      nombre: ['', Validators.required],
      presidente: ['', Validators.required],
      telefonos: this.fb.array([
        this.fb.control('', Validators.required)
      ]),
      emails: this.fb.array([
        this.fb.control('', Validators.required)
      ]),
      publicado: false,
      fechaCreacion: new Date(),
    })

  }

  onSubmit() {
    if (this.formCamaraRegional.valid) {
      this.disabled = false;
      this.loading = true;
      this.crearCamaraRegional();
    }
  }

  crearCamaraRegional() {
    this.afs.collection('camarasRegionales').add(this.formCamaraRegional.value)
    .then( (res) => {
      this.router.navigate(['/admin/asociados/camarasRegionales']);
    });
  }

  agregarTelefono() {
    const telefonos = this.formCamaraRegional.get('telefonos') as FormArray;
    telefonos.push(
      this.fb.control('', Validators.required)
    );
  }

  eliminarTelefono(i) {
    const telefonos = this.formCamaraRegional.get('telefonos') as FormArray;
    telefonos.removeAt(i);
  }

  agregarEmail() {
    const emails = this.formCamaraRegional.get('emails') as FormArray;
    emails.push(
      this.fb.control('', Validators.required)
    );
  }

  eliminarEmail(i) {
    const emails = this.formCamaraRegional.get('emails') as FormArray;
    emails.removeAt(i);
  }

}
