<div class="container">

  <nav class="container small mt-3">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active text-muted">Eventos</li>
    </ol>
  </nav>


  <div class="col-md-10">

    <p class="h1 mb-4 mb-md-5">Eventos</p>

    <div class="mb-6 mb-md-8" *ngFor="let evento of eventos">

      <p class="h1 fw-bold text-primary mb-3">{{evento.nombre}}</p>

      <swiper [config]="config" class="mb-3">
        <ng-template swiperSlide *ngFor="let item of evento.files">
          <div class="sliderPtm" [style.background-image]=" 'url('+ (item.urlFile) +')' ">
            <div class="bgGradient h-100"></div>
          </div>
        </ng-template>
        <div class="swiper-pagination"></div>
      </swiper>
      
      <div class="row gx-md-6">
        <div class="col-md-8">
          <p class="lead">{{evento.subtitulo}}</p>
          <p class="">{{evento.descripcion}}</p>
        </div>
        <div class="col-md-4">
          <p class="text-primary">Conoce más de este evento:</p>
          <p class="text-primary">{{evento.textMoreInfo}}</p>
          <div class="d-grid">
            <a class="btn btn-primary" [href]="evento.urlButton" target="_blank">{{evento.nameButton}}</a>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>