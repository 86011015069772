import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentoHel } from 'src/app/classes/documento-hel';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-eliminar-documento',
  templateUrl: './eliminar-documento.component.html',
  styleUrls: ['./eliminar-documento.component.scss']
})
export class EliminarDocumentoComponent implements OnInit {

  loading: boolean;

  constructor(
    private dialogRef: MatDialogRef<EliminarDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentoHel,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }


  eliminarNoticiaHel() {

    this.loading = true;

    // Eliminar logo gremio de storage
 
    this.storage.ref(`documentosHel/${this.data.archivoName}`).delete();
    console.log('documento Hel eliminado de Storage');
    
    // Eliminar documento Hel de Firestore
    this.afs.collection('documentosHel').doc(this.data.id).delete()
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Documento Hel eliminado', 'CERRAR', {
        duration: 3000,
      });
      console.log('Documento Hel eliminado de Firestore');
    });

  }

  cancelar() {
    this.dialogRef.close();
  }

}
