<div class="container py-3 py-md-4">


  <nav class="small">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active text-muted">Congreso Nacional de Turismo 2021</li>
    </ol>
  </nav>

  <div class="mb-3 mb-md-4">
    <p class="h1">Congreso Nacional de Turismo 2021</p>
    <p class="h4">Evento Formato Híbrido</p>
  </div>



  <div class="col-md-10">


    <swiper [config]="config" class="mb-5 mb-md-6">
      <ng-template swiperSlide *ngFor="let item of carousel">
        <img [src]="item" class="slideCnt">
      </ng-template>
      <div class="swiper-pagination"></div>
    </swiper>


    <p class="h1 text-primary mb-3 mb-md-4 text-center text-uppercase fw-bold">Revive los 2 días del XV CNT</p>


    <div class="row gy-4">
      <div class="col-md-6">
        <p class="h4">Inauguración</p>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="auto" src="https://www.youtube.com/embed/yMXRiOPwRSo" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-6">
        <p class="h4">Clausura</p>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="auto" src="https://www.youtube.com/embed/DDtzIuOYRgs" allowfullscreen></iframe>
        </div>
      </div>
    </div>


    <div class="my-4">
      
      <div class="mt-3">
        <a class="btn btn-lg btn-outline-primary" href="https://youtube.com/playlist?list=PL3V1B4_eprtptCXMFkzjLzlWqETuGO_8J" target="blank">Ver evento completo aquí</a>
      </div>
      
    </div>
  
    <p class="h3 text-primary">Objetivos</p>
    <p>Generar un espacio de interacción que permita de manera descentralizada el trabajo coordinado entre el sector público y privado para la reactivación del sector turismo en el corto y mediano plazo.</p>
  
    <p class="h3 text-primary">Dirigido a:</p>
  
    <p><span class="fw-bold">Público especializado:</span> Empresarios, gerentes generales, administradores, ejecutivos del sector turismo: agencias de viajes, hotelería, restaurantes y afines, guías oficiales de turismo, líneas aéreas.</p>
  
    <p><span class="fw-bold">Personas que coinciden con Intereses:</span> Agencias de viajes, Educación superior, Turismo, Estudiantes, Turismo, Restaurantes, Hoteles o Economía, Viajes de aventura, Temas de actualidad, Política, Líneas aéreas, Viajes.</p>
  
  
    <p class="h3 my-3 text-primary">Propuestas a promover</p>
  
    <div class="bg-light rounded-3 p-4 mb-4 mb-md-5">
      <p class="h3 fw-bold">Visión del Turismo Peruano</p>
      <p>Propuestas y Acciones del Sector para la Reactivación del Turismo Peruano.</p>
      <ul>
        <li>Propuestas y Acciones del Sector Público, Privado y Académico de Turismo para la reactivación del Turismo Peruano</li>
        <li>Propuestas y Acciones del Sector Privado de Turismo ámbito Regional para la Reactivación del Turismo Peruano
          </li>
      </ul>
    </div>
  
    <div class="bg-light rounded-3 p-4 mb-4 mb-md-5">
      <p class="h3 fw-bold">Reactivación del turismo</p>
      <div class="d-flex mb-3 mb-md-4" *ngFor="let item of reactivaciones">
        <span>
          <i-tabler class="me-2 text-primary" name="arrow-right" style="width: 20px; stroke-width: 1.5;"></i-tabler>
        </span>
        <div>
          <p class="h5 mb-1">{{item.nombre}}</p>
          <div class="d-flex" *ngFor="let descripcion of item.descripciones">
            <!-- <span>
              <i-tabler class="me-1 text-muted" name="chevron-right" style="width: 16px; stroke-width: 2;"></i-tabler>
            </span> -->
            <span class="small" style="padding-top:2px;">{{descripcion}}</span>
          </div>
        </div>
      
        <!-- <ul>
          <li *ngFor="let descripcion of item.descripciones">{{descripcion}}</li>
        </ul>
        <hr class="my-3"> -->
      </div>
    </div>
  
    <div>
      <img src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/galeriaBicentenario%2Fauspiciadores.jpg?alt=media&token=2e6593f5-57ef-40f9-beac-7065feba1b9c" alt="" width="100%">
    </div>

    

  </div>

  




</div>