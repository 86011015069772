<p class="lead">Imágenes</p>


<div class="alert alert-danger d-flex align-items-center gap-2" *ngIf="showAlert">
  <i-tabler name="alert-triangle"></i-tabler>
  <p class="small mb-0">Máximo {{data.maxNumFotos}} imágenes</p>
</div>


<input class="d-none" type="file" id="formFileMultiple" multiple (change)="getFileDetails($event)" accept="image/png, image/jpeg, image/jpg">


<div>

  <div class="grillaImagesItems mb-3 list" cdkDropListOrientation="horizontal" cdkDropList (cdkDropListDropped)="drop($event)">

    <div class="imageItemFile box" *ngFor="let item of fotosFirestore; let i = index" cdkDrag>
      <div class="h-100 border rounded" style="background-size: cover;" [ngStyle]=" { 'background-image': 'url(' + item.urlImage + ')' }">
        <div class="d-flex justify-content-between rounded h-100 p-2">
          <div>
            <i-tabler class="text-white" name="grip-vertical"></i-tabler>
          </div>
          <div>
            <button class="btn btn-light bg-white btn-circle-xs shadow" (click)="deleteImageFromFirebase(item)">
              <i-tabler class="text-primary" name="x"></i-tabler>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="position-relative imageItemPreview" *ngFor="let item of fotos; let i = index">
      <div class="h-100 rounded" style="background-size: cover;" [ngStyle]=" { 'background-image': 'url(' + item.imgUrl + ')' }">
        <div class="d-flex justify-content-center align-items-center bg-white bg-opacity-75 rounded h-100">
          <mat-progress-spinner color="primary" mode="determinate" [value]="item.progreso" diameter="36">
          </mat-progress-spinner>
        </div>
        <div class="buttonDeleteImageItem">
          <button class="btn btn-primary btn-circle-xs" (click)="removeItem(i)" *ngIf="item.remove">
            <i-tabler name="x"></i-tabler>
          </button>
        </div>
      </div>
    </div>
 
    <button class="p-0 border-0 bg-white" (click)="getInputFile()" type="button" *ngIf="showButtonAddFile">
      <div class="d-flex justify-content-center align-items-center buttonDotted rounded">
        <i-tabler name="plus" class="text-primary"
          style="width: 48px; height: 48px; stroke-width: 1.5; stroke-linecap: square;"></i-tabler>
      </div>
    </button>

  </div>

  <ul>
    <li class="small text-muted" *ngFor="let condicion of data.condiciones">{{condicion}}</li>
  </ul>


</div>