import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Alianza } from 'src/app/classes/alianza';
import { DataFiles } from 'src/app/classes/data-files';

@Component({
  selector: 'app-editar-alianza',
  templateUrl: './editar-alianza.component.html',
  styleUrls: ['./editar-alianza.component.scss']
})
export class EditarAlianzaComponent implements OnInit {

  formAlianza: FormGroup;
  disabled = true;
  loading = false;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Alianzas',
        url: '/admin/alianzas'
      },
    ],
    back: '/admin/alianzas',
    titulo: 'Editar Alianza',
    urlButton: ''
  };

  itemId: any;
  data: DataFiles;

  alianza: Alianza;
  id: string;

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {


    this.itemId = this.activatedRoute.snapshot.params.id;
    
    this.data = {
      id: this.itemId,
      route: `alianzas/${this.itemId}`,
      maxNumFiles: 1,
      maxFileSize: 1048576 * 50,
      typeFiles: [
        'image/*',
        '.pdf'
      ],
      condiciones: [
        'Peso máximo por archivo: 50 MB',
      ]
    }
  }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.params.id;
    console.log(this.id);

    this.afs.doc(`alianzas/${this.id}`).valueChanges().subscribe( (res: Alianza) => {
      this.alianza = res;
      this.formAlianza = this.fb.group({
        nombre: [this.alianza.nombre, Validators.required],
        descripcion: [this.alianza.descripcion],
        suscripcion: [this.alianza.suscripcion],
        lastModified: new Date(),
      });
    });

  }

  onSubmit() {
    if (this.formAlianza.valid) {
      this.disabled = false;
      this.loading = true;
      this.editarAlianza();
    }
  }

  editarAlianza() {
    this.afs.doc(`alianzas/${this.itemId}`).set(this.formAlianza.value, {merge: true})
    .then( (res) => {
      this.router.navigate(['/admin/alianzas']);
    });
  }

}
