import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarCamaraRegionalComponent } from '../eliminar-camara-regional/eliminar-camara-regional.component';
import { EliminarItemComponent } from '../../../eliminar-item/eliminar-item.component';

@Component({
  selector: 'app-lista-camaras-regionales',
  templateUrl: './lista-camaras-regionales.component.html',
  styleUrls: ['./lista-camaras-regionales.component.scss']
})
export class ListaCamarasRegionalesComponent implements OnInit {

  displayedColumns = [ 'nombre', 'presidente', 'publicado', 'editar', 'eliminar' ];
  data = new MatTableDataSource();

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
      {
        nombre: 'Cámaras Regionales',
        url: '/admin/asociados/camarasRegionales'
      },
    ],
    back: '/admin/asociados',
    titulo: 'Cámaras Regionales',
    urlButton: 'crearCamaraRegional'
  };

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {

    this.fs.getAllCamarasRegionales().subscribe(data => {
      this.data.data = data;
    });
  }


  actualizarPublicado(id, change: MatSlideToggleChange) {
    this.afs.collection('camarasRegionales').doc(id).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item) {
    return item.publicado;
  }

  editarItem(id) {
    this.router.navigate([`admin/asociados/camarasRegionales/editarCamaraRegional/${id}`])
  }

  eliminarItem(id) {
    this.matDialog.open(EliminarItemComponent, {
      data: {
        rutaFirestore: `camarasRegionales/${id}`,
      }
    });
  }

}
