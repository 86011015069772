<div class="container">

  <!-- <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/asociados">Asociados</a></li>
    </ol>
  </nav>


  <div class="d-flex justify-content-between align-items-center mb-2 mb-md-3">
    <div class="d-flex align-items-center">
      <button mat-icon-button routerLink="/admin/asociados">
        <i-tabler name="arrow-left" class="me-1"></i-tabler>
      </button>
      <p class="h4 mb-0">Empresas Asociadas</p>
    </div>
    <a class="btn btn-primary btn-sm" routerLink="/admin/asociados/empresasAsociadas/crearEmpresa">
      <i-tabler name="plus" class="me-1" style="width: 20px;"></i-tabler>
      <span>Crear</span>
    </a>
  </div> -->

  <app-header-section [seccion]="seccion"></app-header-section>



  <div class="row">
    <div class="col-12" *ngFor="let item of itemsGroup">

      <!-- <div class="border rounded p-4">
        <p class="mb-0">{{item.categoria}}</p>
      </div> -->

      <app-item-lista [item]="item"></app-item-lista>

    </div>
  </div>




  <!-- <div class="table-responsive">

    <table mat-table [dataSource]="gremiosData" matSort class="example-table" [trackBy]="trackByPublicado">

      <ng-container matColumnDef="imagen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</th>
        <td mat-cell *matCellDef="let item"> <img class="imageItemAdmin border my-1 rounded"
            [src]="item.imageLogo || 'assets/images/imageFakerCamara.png' "></td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
        <td mat-cell *matCellDef="let item"> {{item.nombre}} </td>
      </ng-container>
      
      <ng-container matColumnDef="categoria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría</th>
        <td mat-cell *matCellDef="let item"> {{item.categoria}} </td>
      </ng-container>  

      <ng-container matColumnDef="publicado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Publicado</th>
        <td mat-cell *matCellDef="let item">
          <mat-slide-toggle color="primary" #toggle [checked]="item.publicado"
          (change)="actualizarPublicado(item.id, $event)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="detalles">
        <th mat-header-cell *matHeaderCellDef>Detalles</th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button>
            <mat-icon class="material-icons-outlined text-muted">remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="mas">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" class="text-end">

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let option of opciones" (click)="option.function(item)">
              <i-tabler [name]="option.icon"></i-tabler>
              <span class="ms-2">{{option.nombre}}</span>
            </button>
          </mat-menu>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


  <mat-paginator [pageSize]="50" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
  </mat-paginator> -->






  

</div>