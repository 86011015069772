<p class="lead">{{data.title}}</p>




<!-- Input file oculto-->
<input class="d-none" type="file" id="formFileMultiple" multiple (change)="getFileDetails($event)"
  [accept]="data.typeFiles">




<!-- Botones -->
<div class="d-flex gap-3 mb-3">

  <button class="btn btn-sm btn-primary w-100" [disabled]="!showButtonAddFile" (click)="getInputFile()" type="button">
    <span>ELEGIR</span>
    <i-tabler class="ms-2" name="plus"></i-tabler>
  </button>

  <button class="btn btn-sm btn-primary w-100" [disabled]="fotos.length == 0 || showAlert || checkSizeValidator() || subiendo"
    (click)="uploadFiles()">
    <span>SUBIR</span>
    <i-tabler class="ms-2" name="upload"></i-tabler>
  </button>

</div>






<!-- Alertas -->
<div class="d-flex flex-column gap-2 my-3">

  <div class="bg-danger text-danger bg-opacity-10 d-flex align-items-center gap-2 px-3 py-2 rounded" *ngIf="showAlert">
    <i-tabler name="alert-triangle"></i-tabler>
    <p class="small mb-0">Máximo {{data.maxNumFiles}} {{data.title | lowercase}}</p>
  </div>

  <div class="bg-danger text-danger bg-opacity-10 d-flex align-items-center gap-2 px-3 py-2 rounded"
    *ngIf="checkSizeValidator()">
    <i-tabler name="alert-triangle"></i-tabler>
    <p class="small mb-0">Peso máximo por archivo: {{data.maxFileSize /1024 / 1024 | number: '1.0-0'}} MB </p>
  </div>

</div>






<!-- Archivos de Firestore -->
<div class="d-flex flex-column gap-2 list mb-2" cdkDropList (cdkDropListDropped)="drop($event)">

  <div class="box" *ngFor="let item of fotosFirestore; last as last; let i = index" cdkDrag>

    <div class="d-flex justify-content-between align-items-center">

      <div class="d-flex align-items-center gap-2 w-100">

        <button mat-icon-button cdkDragHandle matTooltip="Arrastrar para ordenar">
          <i-tabler class="text-primary" name="grip-vertical"></i-tabler>
        </button>

        <div>
          <img class="imageItemPreview rounded" [src]="item.urlFile" *ngIf="iconFile(item.nombre)">
          <div class="">
            <i-tabler class="i text-muted" name="file-text" style=" width: 60px; height: 60px; stroke-width: 0.9"
              *ngIf="!iconFile(item.nombre)"></i-tabler>
          </div>

        </div>

        <div class="w-100">
          <p class="small mb-0 w-75 text-truncate">{{item.nombre}}</p>
          <p class="text-muted small mb-0">{{item.size / 1024 / 1024 | number: '1.2-2'}} MB</p>
        </div>

        <!-- <div>
          <button mat-icon-button>
            <i-tabler name="external-link"></i-tabler>
          </button>
        </div> -->

      </div>

      <div class="d-flex gap-2">

        <button mat-icon-button matTooltip="Vista previa" (click)="openFile(item.urlFile)">
          <i-tabler class="text-primary" name="external-link"></i-tabler>
        </button>

        <button mat-icon-button (click)="deleteFileFirebase(item, i)" matTooltip="Eliminar">
          <mat-spinner diameter="20" *ngIf="item.loadingDelete"></mat-spinner>
          <i-tabler class="text-primary" name="trash" *ngIf="!item.loadingDelete"></i-tabler>
        </button>

      </div>

    </div>

    <!-- <mat-divider class="my-1"></mat-divider> -->

  </div>

</div>




<!-- Archivos local -->

<div class="d-flex flex-column gap-3 list" cdkDropList (cdkDropListDropped)="dropPreview($event)">

  <div class="box" *ngFor="let item of fotos; last as last; let i = index" cdkDrag>

    <div class="d-flex justify-content-between align-items-center">

      <div class="d-flex align-items-center gap-2 w-100">

        <!-- <div>
            <p class="small text-muted mb-0">{{i+1}}</p>
          </div> -->

        <button mat-icon-button cdkDragHandle>
          <i-tabler class="text-muted" name="grip-vertical"></i-tabler>
        </button>

        <div class="">
          <img class="imageItemPreview rounded opacity-50" [src]="item.imgUrl | sanitizeUrl"
            *ngIf="iconFile(item.nameArchivo)">
          <i-tabler class="i text-muted" name="file-text" style=" width: 60px; height: 60px; stroke-width: 0.9"
            *ngIf="!iconFile(item.nameArchivo)"></i-tabler>
        </div>

        <div class="w-100">
          <p class="small mb-0 text-muted">{{item.nameArchivo}}</p>

          <div class="progress my-1" style="height: 6px;">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
              [style]=" 'width:' + item.progreso + '%' "></div>
          </div>

          <div class="d-flex align-items-center">
            <p class="mb-0">
              <span class="text-muted small opacity-50">{{item.sizeArchivo / 1024 / 1024 | number: '1.0-2'}}
                MB</span>&nbsp;
              <span class="text-danger small" *ngIf="item.sizeValidator">Máximo {{data.maxFileSize /1024 / 1024 |
                number: '1.0-0'}} MB</span>
              <span class="text-muted small" *ngIf="!item.sizeValidator">{{item.progreso}} %</span>
            </p>

            <!-- <div *ngIf="item.sizeValidator">
                <i-tabler class="text-danger" name="alert-circle" style="width: 21px; height: 21px; stroke-linecap: square;" ></i-tabler>
                <span class="text-danger small">Excede el peso</span>
              </div> -->

          </div>


        </div>

        <div>
          <button class="btn btn-primary btn-circle-xs" (click)="removeItem(i)" [disabled]="subiendo">
            <i-tabler name="x"></i-tabler>
          </button>
        </div>

      </div>

    </div>

    <!-- <mat-divider class="my-1"></mat-divider> -->

  </div>


</div>





<!-- Condiciones -->
<div class="d-flex flex-column mt-2">
  <div class="d-flex gap-1 small text-muted" *ngFor="let condicion of data.condiciones">
    <p class="mb-0">*</p>
    <p class="mb-0">{{condicion}}</p>
  </div>
</div>