import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { DataService } from '../../services/data.service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Evento } from 'src/app/classes/evento';
import { AngularFirestore } from '@angular/fire/compat/firestore';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-expo-turismo',
  templateUrl: './expo-turismo.component.html',
  styleUrls: ['./expo-turismo.component.scss']
})
export class ExpoTurismoComponent implements OnInit {


  // eventos = [
  //    {
  //     carousel: [
  //       'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/expoturismo%2FferiaTurismoPeru%2FferiaTurismoPeru1.jpg?alt=media&token=b8135402-a13f-4a8a-9ca8-1ba2465d0747',
  //       'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/expoturismo%2FferiaTurismoPeru%2FferiaTurismoPeru2.jpg?alt=media&token=9c4a1306-b04f-4ecd-aec9-781e4d3127d4',
  //       'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/expoturismo%2FferiaTurismoPeru%2FferiaTurismoPeru3.jpg?alt=media&token=12d9d760-8998-4577-b4c2-0f9013c69b6e'
  //     ],
  //     nombre: 'Feria Turismo Perú',
  //     subtitulo: 'Impulsar el crecimiento y fortalecimiento del turismo interno a través de la profesionalización y formalización de la oferta turística nivel nacional.',
  //     descripciones: [
  //       'Plataforma ideal para la comercialización y venta de paquetes turísticos, alojamiento, boletos aéreos y terrestres, transporte fluvial y lacustre, entre otros servicios turísticos a tarifas promocionales, contando con descuentos especiales para el público en general.',
  //       'Ofrece a las empresas expositoras la oportunidad de generar lazos comerciales, acercamiento con futuros contactos potenciales, incremento de cartera de clientes, entre otros.'
  //     ],
  //     textMoreInfo: 'Conoce más de este evento:',
  //     textButton: 'feriaturismoperu.com.pe',
  //     urlButton: 'https://mailchi.mp/canaturperu.org/feriaturismoperu2022'
  //   },
  //   {
  //     carousel: [
  //       'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/expoturismo%2FperuTravelMart%2F01ptm.jpg?alt=media&token=9ad69c78-b588-4910-98d7-515bf610a190',
  //       'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/expoturismo%2FperuTravelMart%2F02ptm.jpg?alt=media&token=8d04dd61-dc1d-4e29-b5f1-2ae77e6ed5c7',
  //       'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/expoturismo%2FperuTravelMart%2F03ptm.jpg?alt=media&token=9a47168e-bcb1-4903-ac64-8ff7ddd05bde',
  //       'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/expoturismo%2FperuTravelMart%2F04ptm.jpg?alt=media&token=1961ffe4-5810-4121-82ea-0dbc9aacfeef'
  //     ],
  //     nombre: 'Perú Travel Mart',
  //     subtitulo: 'Rueda de Negocios Internacional B2B más importante del país',
  //     descripciones: [
  //       'Dirigida a altos ejecutivos internacionales con poder de decisión; mediante una plataforma que permite la comercialización y la promoción de diversos productos y servicios turísticos que ofrece el Perú, al mercado internacional, a través de citas pre programadas con Gerentes y altos ejecutivos de Hoteles, Tour Operadores, Aerolíneas, Cruceros, Museos y Transporte del Perú',
  //       'El evento considera también sesiones de encuentros de corta duración (Speed Networking), permitiendo afianzar negocios, además de ampliar la red de contactos con empresas relacionadas al rubro.'
  //     ],
  //     textMoreInfo: 'Conoce más de este evento:',
  //     textButton: 'perutravelmart.com.pe/es',
  //     urlButton: 'https://www.perutravelmart.com.pe/es'
  //   },
  // ];

  eventos: Evento[] = [];
  carousel: any = [];
  evento: any;
  newEventos = [];

  config: SwiperOptions = {
    spaceBetween: 20,
    pagination: { clickable: true },
  };

  constructor(
    private ds: DataService,
    private fs: FirebaseService,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {

    // this.fs.getEventos().subscribe( res => {
    //   this.eventos = res;
    //   console.log(this.eventos);
      
    //   this.eventos.map( (evento: Evento) => {
    //     this.afs.collection(`eventos/${evento.id}/images`).valueChanges().subscribe( data => {
    //       this.carousel = data;
    //       this.evento = {
    //         ...evento,
    //         images: data
    //       }
    //       this.newEventos.push(this.evento)
    //     } )
    //   });
      
    // });


    this.fs.getEventos().subscribe( res => {
      this.eventos = res;
      console.log(this.eventos);
      
    });


  }

}
