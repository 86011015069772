import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { BannerFtp } from 'src/app/classes/banner-ftp';
import { FirebaseService } from 'src/app/services/firebase.service';
import { DataService } from '../../services/data.service';
import { ModalInicioComponent } from '../modal-inicio/modal-inicio.component';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {


  seccionesHome;
  noticias;
  slides;
  slidesMarketplace;
  slidesBicentenario = [
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/galeriaBicentenario%2F1.jpg?alt=media&token=6a97ae70-0ca3-43ef-aee9-40ae71cba8a7',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/galeriaBicentenario%2F2.jpg?alt=media&token=b1e42da0-3e8f-4770-ada6-49594b592f40',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/galeriaBicentenario%2F3.jpg?alt=media&token=7b798e3a-614b-451d-9d36-3994c6ca1085'
  ]
  logosHelvetas;

  // config: SwiperOptions = {
  //   pagination: { el: '.swiper-pagination', clickable: true },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev'
  //   },
  //   spaceBetween: 0,
  //   observer: true,
  //   autoplay: {
  //     delay: 6000,
  //     disableOnInteraction: false,
  //   }
  // };

  config: SwiperOptions = {
    spaceBetween: 20,
    pagination: { clickable: true },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    }
  };


  config2: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0,
    // observer: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    }
  };

  bannersFtp: BannerFtp[] = [];

  constructor(
    private fs: FirebaseService,
    private ds: DataService,
    private router: Router,
    private dialog: MatDialog
  ) {
    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) { element.scrollIntoView(true); }
        }
      }
    });
  }

  ngOnInit(): void {
    this.seccionesHome = this.ds.seccionesHome;
    this.noticias = this.fs.getNoticias();
    this.slides = this.fs.getNoticiasDestacadas();
    this.slidesMarketplace = this.ds.slidesMarketplace;
    this.logosHelvetas = this.ds.logosHelvetas;

    this.fs.getBannersFtp().subscribe( res => {
      this.bannersFtp = res;
      console.log(this.bannersFtp);
    })

    // this.fs.getNoticiasDestacadas().subscribe( data => {
    //   this.slides = data;
    // });
    // this.slides = this.ds.slides;

    // this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalInicioComponent, {
      panelClass: 'modalConferencia'
    });
  }

}
