











<div class="bgBannerXVIcnt vh-100">

  <div class="container d-flex flex-column h-100">

    <!-- <div class="d-flex justify-content-between py-4 py-md-5">
      <img class="logoHead"
        src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoCanaturHibrido.svg?alt=media&token=7660df44-4328-4a0f-9533-e806bd2369d8"
        alt="">
      <img class="logoHead"
        src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoMincetur.png?alt=media&token=6300208c-1c55-4cdf-b3a1-83fe75f1cfe9"
        alt="">
    </div> -->



    <!-- <div class="bg-white rounded-3 d-flex flex-column justify-content-center align-items-center flex-md-row gap-3 gap-md-5 mt-3 shadow p-2 p-md-3">
      <div *ngFor="let marca of marcasHeader">
        <img [src]="marca.image" [width]="marca.width" height="auto">
      </div>
    </div> -->

    <div class="col-md-6 mx-auto">
      <div class="bg-white rounded-3 mt-3 shadow px-3 py-2 px-md-4 py-md-3 d-flex justify-content-center align-items-center gap-2 gap-md-4">
        <div [style.width.%]="marca.width" *ngFor="let marca of marcasHeader">
          <img [src]="marca.image" width="100%" height="auto">
        </div>
      </div>
    </div>

   
    



    <div class=" d-flex justify-content-center align-items-center h-100">
      <div class="col-md-8 text-center">
        <p class="text-white display-1 fw-bold">XVI Congreso Nacional de Turismo</p>
        <p class="text-white fs-3">Lima, 4 y 5 de diciembre de 2023</p>
        <a class="btn btn-success" href="https://docs.google.com/forms/d/e/1FAIpQLSfMEx1nkuWG20ZFvCOgVNYlX9yKW_68IdMWCIbOdmqa3MGH_A/viewform?usp=pp_url" target="_blank">INSCRIPCIONES</a>
      </div>
    </div>

  </div>

</div>







<!-- <div class="backDescription">

  <div class="backBlur">

    <div class="container px-4 px-md-0 py-5 py-md-7">

      <div class="row gy-4 gx-md-6 align-items-center">

        <div class="col-md-6">

          <div class="ratio ratio-1x1">
            <img class="object-fit-cover"
              src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FturistaLlama.jpg?alt=media&token=dbcd7231-3a3b-4b33-8277-55eabc4c902a"
              alt="">
          </div>

        </div>

        <div class="col-md-6">
          <div>
            <div class="mb-4 mb-md-5 text-white">
              <p class="display-4 fw-normal">Recuperación del turismo hacia un futuro sostenible</p>
              <p class="lead">El Congreso Nacional de Turismo 2024 es un espacio de interacción que permite de manera descentralizada
                el trabajo coordinado entre el sector público y privado para la reactivación del sector turismo en el
                corto y mediano plazo.</p>
            </div>
            <div class="d-grid d-md-flex gap-2 gap-md-3">
              <button class="btn btn-dark">INSCRIPCIONES</button>
              <a class="btn btn-outline-dark" routerLink='.' fragment="programa" >VER PROGRAMA</a>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

</div> -->










<div class="backDescription">

  <div class="position-relative">

    <div class="container px-4 px-md-0 py-5 py-md-7">

      <div class="row gy-4 gx-md-6 align-items-center">

        <div class="col-md-6">

          <div class="ratio ratio-1x1">
            <img class="object-fit-cover"
              src="https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FturistaLlama.jpg?alt=media&token=dbcd7231-3a3b-4b33-8277-55eabc4c902a"
              alt="">
          </div>

        </div>

        <div class="col-md-6">
          <div>
            <div class="mb-4 mb-md-5">
              <p class="display-4 fw-normal">Recuperación del turismo hacia un futuro sostenible</p>
              <p class="h4 fw-normal lh-base">El <span class="text-uppercase fw-bolder">Congreso Nacional de Turismo 2023</span> es un espacio de interacción que permite de manera descentralizada
                el trabajo coordinado entre el sector público y privado para la reactivación del sector turismo en el
                corto y mediano plazo.</p>
            </div>
            <div class="d-grid d-md-flex gap-2 gap-md-3">
              <a class="btn btn-dark" href="https://docs.google.com/forms/d/e/1FAIpQLSfMEx1nkuWG20ZFvCOgVNYlX9yKW_68IdMWCIbOdmqa3MGH_A/viewform?usp=pp_url" target="_blank">INSCRIPCIONES</a>
              <!-- <button class="btn btn-outline-dark">VER PROGRAMA</button> -->
              <a class="btn btn-outline-dark" routerLink='.' fragment="programa" >VER PROGRAMA</a>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>



</div>










<!-- <div class="container py-5 py-md-7" id="ponencia">

  <div class="row gx-md-7">

    <div class="col-md-4">
      <p class="h1">Ponencia</p>
      <p class="lead">Visualiza el contenido de nuestra conferencia y demás actividades relacionadas.</p>
      <a class="btn btn-outline-primary" href="">Ver programa</a>
    </div>

    <div class="col-md-8">

      <div class="row gy-4 gy-md-5 gx-md-4 ">

        <div class="col-md-6" *ngFor="let video of videos">

          <div class="ratio ratio-16x9 mb-1">
            <iframe class="rounded" [src]="video.url | sanitizeUrl" allowfullscreen></iframe>
          </div>

          <div class="d-flex align-items-center gap-2">

            <div>
              <i-tabler class="text-primary" name="brand-youtube" style="width: 42px; height: 42px; stroke-width: 1;"></i-tabler>
            </div>

            <p class="text-truncate mb-0">{{video.nombre}}</p>

          </div>

        </div>

      </div>

    </div>

  </div>

</div> -->












<div class="backEjesTematicos" id="ejesTematicos">

  <div class="position-relative">

  <div class="container py-5 py-md-7">

    <div class="col-md-10 mx-auto">

      <div class="text-white text-center mb-5 mb-md-6">
        <p class="display-4 text-success">Ejes temáticos</p>
        <p class="lead">El congreso estará enmarcado en las celebraciones por el Día Mundial del Turismo y se reflexionará cómo a través de la innovación y el uso de la tecnología en la gestión de destinos y emprendimientos del sector, se puede promover el desarrollo de un turismo sostenible, el cual minimiza las repercusiones ambientales y genere un beneficio social, económico y ambiental para las comunidades anfitrionas.</p>
      </div>


      <div class="row justify-content-center g-3 g-md-6">

        <div class="col-md-4" *ngFor="let ejeTematico of ejesTematicos">

          <div class="ratio ratio-1x1 mb-2">
            <img class="object-fit-cover border border-white border-4" [src]="ejeTematico.url">
          </div>
          
          <p class="h3 mb-0 text-success text-center">{{ejeTematico.nombre}}</p>

        </div>

      </div>

    </div>

  </div>

</div>

</div>











<div class="backPrograma" id="programa">

  <div class="position-relative">

    <div class="container py-5 py-md-7">

      <p class="display-4 text-center">Programa</p>

      <!-- <div class="row gx-md-6 gy-5 gy-md-7">

        <div class="col-md-12" *ngFor="let dia of programa">

          <div class="row gx-md-6 gy-4">

            <div class="col-md-4">

              <div class="sticky-top pt-2">

                <div class="d-flex align-items-center gap-2 p-2 p-md-3 bg-dark text-white rounded-4 shadow">
                  <div>
                    <i-tabler class="text-success" name="calendar" style="width: 72px; height: 72px; stroke-width: 0.75;"></i-tabler>
                  </div>
                  <p class="h4 lh-sm mb-0">{{dia.dia}}</p>
                </div>

              </div>

            </div>

            <div class="col-md-8">

              <div class="d-flex flex-column gap-3">

                <div class="d-flex flex-column" *ngFor="let bloque of dia.bloques" style="gap: 2px;">
    
                  <p class="lead text-white mb-1">{{bloque.nombre}}</p>

                  <div class="rounded-3 bg-white p-2 p-md-3">

                    <div class="" *ngFor="let evento of bloque.eventos; last as last">

                      <div class="d-flex gap-3 px-3 py-2" [ngClass]=" evento.break ? 'text-uppercase text-success bg-success bg-opacity-25' : ''  " >
  
                        <div class="d-flex gap-1 gap-md-2 flex-md-shrink-0">
  
                          <div>
                            <i-tabler class="" name="clock" style="width: 18px; height: 18px; stroke-width: 1.5;"></i-tabler>
                          </div>
            
                          <div style="padding-top: 3px;">
                            <p class="lh-sm mb-0">{{evento.horario}}</p>
                          </div>
    
                        </div>
                      
          
                        <div class="" style="padding-top: 3px;">
                          <p class="mb-1 fw-normal lh-sm" >{{evento.titulo}}</p>
                          <p class="extraSmall text-body-secondary mb-0 lh-sm">{{evento.ponente}}</p>
                        </div>
  
                      </div>
  
                      <mat-divider *ngIf="!last"></mat-divider>
        
                    </div>

                  </div>

                  <div class="bg-success rounded text-center border border-success p-2 mt-3" *ngIf="bloque.break">
                    <p class="fs-5 text-white mb-0 text-uppercase" style="letter-spacing: 0.5rem;">{{bloque.break?.nombre}}</p>
                  </div>

                  <div class="border rounded text-center border border-white p-2 p-md-3 mt-3" *ngIf="bloque.otro">
                    <p class="h5 text-white">{{bloque.otro?.nombre}}</p>
                    <p class="text-white opacity-75 mb-0">{{bloque.otro?.detalle}}</p>
                  </div>
      
                </div>
    
              </div>

            </div>

          </div>


        </div>

      </div> -->






      <div class="mb-4 mb-md-6" *ngFor="let dia of programa">

        <p class="h1 mb-4 mb-md-5 text-center">{{dia.dia}}</p>

        <div class="row justify-content-center gy-4 gy-md-5">

          <div class="col-12" *ngFor="let bloque of dia.bloques">

            <div class="col-md-8 mx-auto mb-4 mb-md-5">
              <p class="h4 text-center">{{bloque.nombre}}</p>
            </div>

            <div class="row gy-4 gy-md-4 gx-md-6 justify-content-center">

              <div class="col-md-3" *ngFor="let evento of bloque.eventos" >

                <div class="text-center">

                  <img class="bg-dark rounded-circle mb-2" [src]="evento.expositor?.photo" width="80%">
                  
                  <div class="text-center">
                    <p class="small mb-0 text-uppercase lh-sm">{{evento.expositor?.nombre}}</p>
                    <p class="small text-body-secondary lh-sm mb-0">{{evento.expositor?.cargo}}</p>
                    <mat-divider class="my-1"></mat-divider>
                    <!-- <p class="lh-sm">{{evento.titulo | slice:0:54}}{{evento.titulo.length > 54 ? '...' : ''}}</p> -->
                    <p class="lh-sm">{{evento.titulo}}</p>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>



      



    </div>

  </div>

</div>





























<div class="backAuspiciadores" id="auspiciadores">

  <div class="backBlurDark">

    <div class="container d-flex justify-content-center py-5 py-md-7">

      <div class="col-md-8">

        <div class="text-center">

          <p class="display-4 text-success mb-4 mb-md-5">Auspiciadores</p>

          <div class="d-flex justify-content-center flex-wrap  gap-3">
  
            <div class="" *ngFor="let auspiciador of auspiciadores">

              <div class="ratio ratio-4x3" style="width: 145px;">
                <img class="object-fit-cover rounded-3" [src]="auspiciador.url">
              </div>
              
              <p class="small text-white mb-0 opacity-50">{{auspiciador.nombre}}</p>
  
            </div>
  
          </div>

        </div>

      </div>

    </div>

  </div>

</div>










<div class="bg-light pt-4 pt-md-6 pb-2 pb-md-3" id="footer">
  
  <div class="container">

    <div class="d-flex flex-column align-items-center flex-md-row gap-5 justify-content-between mb-5 mb-md-6">

      <div class="d-flex flex-column align-items-center flex-md-row gap-3 gap-md-5">

        <div *ngFor="let marca of marcasFooter">
          <img [src]="marca.image"  [width]="marca.width" height="auto">
        </div>

      </div>

      <div class="">

        <!-- <p class="lead">Redes sociales</p> -->

        <div class="d-flex gap-2 gap-md-3">

          <a class="link-success" [href]="red.url" target="_blank" *ngFor="let red of redesSociales">
            <i-tabler [name]="red.icon" style="width: 36px; height: 36px; stroke-width: 1.5;"></i-tabler>
          </a>

        </div>

      </div>

    </div>

    <mat-divider class="my-3 my-md-3"></mat-divider>

    <div class="text-center">
      <p class="extraSmall text-body-tertiary mb-0">Desarrollado por <a class="link-body-emphasis" href="https://www.linkedin.com/in/pastorzevallos" target="_blank">Pastor Zevallos</a> </p>
    </div>

  </div>

</div>