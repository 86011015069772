


<div class="container">

  <app-header-section [seccion]="seccion"></app-header-section>


  <div class="row justify-content-between">

    <div class="col-md-6">

      <p class="lead">Información</p>

      <!-- <app-upload-images [data]="data"></app-upload-images> -->

      <form [formGroup]="formEmpresa" (submit)="onSubmit()" novalidate class="row">
<!-- 
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Categoría</mat-label>
        <mat-select formControlName="categoria">
          <mat-option *ngFor="let categoria of categoriasEmpresas" [value]="categoria.nombre">{{categoria.nombre}}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <div class="d-flex gap-2">

        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Categoría</mat-label>
          <mat-select formControlName="categoria">
            <mat-option *ngFor="let categoria of categoriasEmpresas" [value]="categoria.nombre">{{categoria.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <button type="button" class="btn btn-outline-primary" style="height: 59px;" (click)="openModalAgregarCategoria()">
            <i-tabler name="plus"></i-tabler>
          </button>
        </div>

       


      </div>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Nombre</mat-label>
          <input matInput class="text" formControlName="nombre">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Dirección</mat-label>
          <input matInput class="text" formControlName="direccion">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-6">
          <mat-label>Web</mat-label>
          <input matInput class="text" formControlName="web">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-6">
          <mat-label>Teléfono</mat-label>
          <input matInput class="text" formControlName="telefono">
        </mat-form-field>
  
        <div class="mt-4 mt-md-5">
          <button class="btn btn-primary" style="width: 122px;" type="submit">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">AGREGAR</span>
          </button>
        </div>
      </form>
    </div>
  

    <div class="col-md-4">
      <app-upload-file [data]="data"></app-upload-file>
    </div>


  </div>


  <!-- <pre>{{formCamaraRegional.value | json}}</pre> -->

</div>













