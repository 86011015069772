import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Directivo } from 'src/app/classes/directivo';
import { QueHacemos } from 'src/app/classes/que-hacemos';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

  // funciones = [
  //   {
  //     titulo: 'Desarrollo económico y social',
  //     descripcion: 'La Cámara Nacional de Turismo del Perú cumple sus fines contribuyendo al desarrollo económico y social del país, fomentando el turismo interno y receptivo, absolviendo las consultas y emite opiniones.',
  //     image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/nosotros%2FdesarrolloEconomico.png?alt=media&token=0f6e6b54-a345-4aa0-ac83-86c1076dd56f',
  //   },
  //   {
  //     titulo: 'Organización de eventos',
  //     descripcion: 'Asimismo, organiza congresos, seminarios y otros eventos donde se analizan los distintos temas relacionados con nuestra actividad económica. También participa en eventos vinculados al turismo, tanto dentro como fuera del país.',
  //     image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/nosotros%2Forganizaci%C3%B3nEventos.png?alt=media&token=20d93297-f696-4801-9730-89a84e41b5a2',
  //   },
  //   {
  //     titulo: 'Data Empresarial',
  //     descripcion: 'CANATUR ayuda a sus asociados proporcionándoles estadísticas confiables y actualizadas que les permita tomar decisiones empresariales eficaces y brindar su asesoría cuando es solicitada por un asociado.',
  //     image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/nosotros%2FdataEmpresarial.png?alt=media&token=1f8e1363-aaa9-41c9-8758-ffb7a4d52121',
  //   },
  // ];

  queHacemos: QueHacemos[] = [];
  juntaDirectiva: Directivo[] = [];

  planesAccion = [
    'Contribuir al desarrollo económico y social del país.',
    'Fomentar el turismo interno y receptivo.',
    'Absolver las consultas y emitir los informes que el Gobierno tenga a bien solicitar, cumplir los encargos que fuesen encomendados y brindar amplia colaboración, así como asesoramiento para la acertada resolución de los asuntos de interés general dentro de las actividades turísticas del Perú.',
    'Mantener la más estrecha cooperación y colaboración con las organizaciones representativas de las actividades económicas de la Nación para la realización de los fines comunes.',
    'Brindar amplia colaboración y asesoramiento al gobierno para que las leyes y disposiciones administrativas tiendan a favorecer el desarrollo del turismo en general y gestionar la expedición de las normas legales que le fueran necesarias con tal objeto.',
    'Ejercer ante las autoridades y entidades particulares la presentación general del sector turístico privado tanto en el país como en el extranjero.',
    'Facilitar a sus asociados todas las informaciones que pudiesen solicitar y que se relacionen con las actividades económicas y financieras del sector turismo, así como también con las disposiciones legales sobre la actividad en el Perú.',
    'Propiciar la formación, capacitación y perfeccionamiento profesional del personal que presta servicios y sea requerido para el mejoramiento del servicio que se presta al turista.',
    'Brindar a sus asociados un local social para tratar los asuntos relacionados con la actividad del turismo.',
    'Formar y mantener un registro general de las empresas y personas vinculadas al sector turismo.',
    'Fomentar y mantener debidamente una biblioteca que contenga estudios, folletos, revistas y en general todos los documentos y publicaciones que pudiesen reunirse en relación con la actividad del turismo tanto en el Perú como en otros países.',
    'Editar en forma periódica una publicación que servirá para transmitir a sus asociados todas aquellas informaciones que puedan ser de interés general.',
    'Designar comisiones que presenten determinadas ramas especializadas del turismo para que cooperen a la realización de los fines de la Cámara.',
    'Procurar que el ejercicio de las actividades de los asociados se desenvuelvan dentro de un marco ético de buena fe, compañerismo y corrección.',
    'Representar al sector empresarial privado vinculado a las actividades turísticas en organismos que agrupan a entidades gremiales del sector empresarial privado.',
    'Promover la realización de estudios, investigaciones, cursos y debates que le favorezca al desarrollo y promoción de la actividad.',
    'Promover las certificaciones internacionales para el cumplimiento de los protocolos sanitarios en los destinos y servicios turísticos de nuestro país.'
  ];

  // juntaDirectiva = [
  //   {
  //     nombre: 'Carlos Fernando Canales Anchorena',
  //     cargo: 'PRESIDENTE',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'José Enrique Koechlin Von Stein',
  //     cargo: '1ER VICEPRESIDENTE',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Mario Daoud Mustafá Aguinaga',
  //     cargo: '2DO VICEPRESIDENTE',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Javier Carlos Zavala Costa',
  //     cargo: 'DIRECTOR TESORERO',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Alberto Federico Alegría Negrón',
  //     cargo: 'DIRECTOR EJECUTIVO',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Javier Carlos Zavala Costa',
  //     cargo: 'DIRECTOR TESORERO',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'María Gabriela Fiorini Travi',
  //     cargo: 'Directora',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   // {
  //   //   nombre: 'Pierre Jean Francois Berthier',
  //   //   cargo: 'DIRECTOR',
  //   //   image: 'assets/images/imageFakerBox.png',
  //   // },
  //   {
  //     nombre: 'Liliana Picasso Salinas ',
  //     cargo: 'Directora',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Maritza Montero',
  //     cargo: 'Directora',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Carlos Loayza Camarena',
  //     cargo: 'Gerencia General',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Erica Ruiz Cárdenas',
  //     cargo: 'Gerencia de Eventos',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  //   {
  //     nombre: 'Hilda Figueroa Robles',
  //     cargo: 'Asistente de Presidencia',
  //     image: 'assets/images/imageFakerBox.png',
  //   },
  // ];

  periodo: any;

  constructor(
    private afs: AngularFirestore
  ) { }


  ngOnInit(): void {

    this.afs.collection('queHacemos', ref => ref
    .where('publicado', '==', true)
    .orderBy('order', 'asc')
    ).valueChanges().subscribe( data => {
      this.queHacemos = data;
    });

    this.afs.collection('juntaDirectiva').doc('zU4clnyjs8I4N6Pu1B7b').collection('directivos', ref => ref
    .where('publicado', '==', true)
    .orderBy('order', 'asc')
    ).valueChanges().subscribe( data => {
      this.juntaDirectiva = data;
    });

    // get document Período
    this.afs.collection('juntaDirectiva').doc('zU4clnyjs8I4N6Pu1B7b').valueChanges().subscribe( (res: any) => {
      this.periodo = res;
    });

  }

}
