import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helvetas-admin',
  templateUrl: './helvetas-admin.component.html',
  styleUrls: ['./helvetas-admin.component.scss']
})
export class HelvetasAdminComponent implements OnInit {
  

  links = [
    {
      nombre: 'Noticias',
      url: 'noticias'
    },
    {
      nombre: 'Documentos',
      url: 'documentos'
    },
    {
      nombre: 'Banners',
      url: 'banners'
    },
    {
      nombre: 'Videos',
      url: 'videos'
    },

  ];

  
  constructor() { }

  ngOnInit(): void {
  }

}
