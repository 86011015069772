import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotros-admin',
  templateUrl: './nosotros-admin.component.html',
  styleUrls: ['./nosotros-admin.component.scss']
})
export class NosotrosAdminComponent implements OnInit {

  links = [
    {
      nombre: '¿Qué hacemos?',
      url: 'queHacemos'
    },
    {
      nombre: 'Junta Directiva',
      url: 'juntaDirectiva'
    }

  ];

  constructor() { }

  ngOnInit(): void {
  }

}
