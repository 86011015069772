import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { IdValidatorService } from 'src/app/services/id-validator.service';
import { NoticiaHel } from 'src/app/classes/noticia-hel';

@Component({
  selector: 'app-editar-noticia-hel',
  templateUrl: './editar-noticia-hel.component.html',
  styleUrls: ['./editar-noticia-hel.component.scss']
})
export class EditarNoticiaHelComponent implements OnInit {

  formNoticia!: UntypedFormGroup;
  loading = false;
  noticiaId: string;
  noticia: NoticiaHel;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  today = new Date();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  
  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {
      this.noticiaId = res.id;

      this.afs.doc(`noticiasHel/${this.noticiaId}`).valueChanges().subscribe( (data: NoticiaHel) => {

        this.noticia = data;

        this.formNoticia = this.fb.group({
          titulo: [this.noticia.titulo, Validators.required],
          // id: [this.noticia.id, [Validators.required], [this.idValidator]],
          subtitulo: [this.noticia.subtitulo, Validators.required],
          fecha: [this.noticia.fecha.toDate(), Validators.required],
          body: [this.noticia.body, Validators.required],
          image: ['', [FileValidator.maxContentSize(this.maxSize)]],
          // imageName: [''],
          // publicado: [this.noticia.publicado],
          // fechaCreacion: [this.noticia.fechaCreacion],
          fechaEdicion: [Timestamp.now()]
        });

        this.formNoticia.get('titulo').valueChanges.subscribe( res => {
          const tituloSinSaltoLinea = res.replace(/\n/g, ' ');
          // const noticiaHelPre = res.replace(/ /g, '-').replace(/\n/g, '-');
          // this.noticiaId = noticiaHelPre.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
          // this.formNoticia.get('id').setValue(this.noticiaId);
          this.formNoticia.get('titulo').patchValue(tituloSinSaltoLinea, {emitEvent: false});
        });
    
        this.formNoticia.get('subtitulo').valueChanges.subscribe( res => {
          const subtituloSinSaltosLinea = res.replace(/\n/g, '-');
          this.formNoticia.get('subtitulo').patchValue(subtituloSinSaltosLinea, {emitEvent: false});
        });

        // this.formNoticia.get('body').valueChanges.subscribe( res => {
        //   const bodySinSaltoLinea = res.replace(/\n/g, ' ');
        //   this.formNoticia.get('body').patchValue(bodySinSaltoLinea, {emitEvent: false});
        // });



      });

      

    });

   


  }

  onSubmit() {
    if (this.formNoticia.valid) {
      this.loading = true;
      if (this.formNoticia.get('image')?.value === '') {
        this.actualizarNoticia();
      } else {
        this.uploadImageAndUpdateNoticia();
      }
    } else {
      this.validateAllFormFields(this.formNoticia);
    }
  }

  actualizarNoticia() {

    // this.afs.doc(`noticiasHel/${this.noticiaId}`).update(this.formNoticia.value)
    // .then(() => {
    //   this.router.navigate(['/admin/helvetas/noticias']);
    //   console.log('Noticia Helvetas actualizada');
    // });

    const noticia = this.formNoticia.value;
    ['image', 'imageName'].forEach(e => delete noticia[e]);
    this.afs.doc(`noticiasHel/${this.noticiaId}`).update(this.formNoticia.value)
    .then(() => {
      this.router.navigate(['/admin/helvetas/noticias']);
      console.log('Noticia Helvetas actualizada');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadImageAndUpdateNoticia() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `imagesNoticiasHel/${this.noticiaId}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    
    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`noticiasHel/${this.noticiaId}`).update({
          ...this.formNoticia.value,
          image: url,
          // imageName: `${this.noticiaId}.${nombreImage[1]}`
        });
        this.router.navigate(['/admin/helvetas/noticias']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  errorImagen() {
    return this.formNoticia.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

  errorBody() {
    return this.formNoticia.controls.body.touched && this.formNoticia.controls.body.hasError('required') ? 'Incluye una descripción de la noticia' : '';
  }

}
