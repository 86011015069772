import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { FirebaseService } from '../../../services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-crear-empresa-asociada',
  templateUrl: './crear-empresa-asociada.component.html',
  styleUrls: ['./crear-empresa-asociada.component.scss']
})
export class CrearEmpresaAsociadaComponent implements OnInit {

  formEmpresaAsociada: UntypedFormGroup;
  @ViewChild('f') form;
  message = 'Empresa Creada';
  action = 'CERRAR';

  categorias = [
    'Agencia de viajes',
    'Casinos',
    'Hoteles',
    'Institutos y Universidades',
    'Proveedores',
    'Restaurantes',
    'Transporte',
    'Trenes'
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.formEmpresaAsociada = this.fb.group({
      categoria: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: [''],
      telefonos: this.fb.array([
        // this.fb.control('')
      ]),
      web: [''],
      image: [''],
      fechaCreacion: Timestamp.now()
    });
  }

  agregarTelefono() {
    // tslint:disable-next-line:no-string-literal
    (this.formEmpresaAsociada.controls['telefonos'] as UntypedFormArray).push(
         this.fb.control('')
      );
  }

  eliminarTelefono(index: number): void {
    (this.formEmpresaAsociada.controls.telefonos as UntypedFormArray).removeAt(index);
  }

  onSubmit() {
    if (this.formEmpresaAsociada.valid) {
      this.crearEmpresaAsociada();
    } else {
      this.validateAllFormFields(this.formEmpresaAsociada);
    }
  }

  crearEmpresaAsociada() {
    this.fs.addEmpresaAsociada(this.formEmpresaAsociada.value)
    .then(() => {
      console.log(this.formEmpresaAsociada.value);
      this.form.resetForm({
        nombre: '',
        direccion: '',
        telefonos: [],
        web: '',
        image: '',
        fechaCreacion: Timestamp.now(),
      });
      this.snackBar.open(this.message, this.action, {
        duration: 1000,
      });
    }, error => console.error('error submitting form:', error));
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
