<div class="d-none d-md-block">
    <div class="d-flex align-items-center">
        <div class="border rounded-circle bg-light">
            <img class="imageEmpresaAsociada" [src]="empresa.image">
        </div>
        <div class="ms-0 ms-md-3">
            <p class="mb-0 h5">{{empresa.nombre}}</p>
            <p class="text-muted mb-0">{{empresa.direccion}}</p>
            <a [href]="empresa.web" target="blank">{{empresa.web}}</a>
            <div class="" *ngIf="empresa.telefonos.length !== 0">
                <a class="me-2" *ngFor="let telefono of empresa.telefonos" [href]="'tel:' + telefono" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone me-1" width="20"
                        height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#E77917" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path
                            d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                    </svg>{{telefono}}
                </a>
            </div>
            <!-- <p class="small text-muted mb-0">{{empresa.id}}</p> -->
        </div>
    </div>
</div>


<div class="d-block d-md-none">
    <div class="d-flex align-items-top">
        <div class="me-2">
            <img class="border rounded-circle imageEmpresaAsociada" [src]="empresa.image">
        </div>

        <div class="ms-0 ms-md-3 small">
            <p class="mb-0" style="font-weight: 600;">{{empresa.nombre}}</p>
            <p class="text-muted mb-0">{{empresa.direccion}}</p>
            <a [href]="empresa.web" target="blank">Ir al sitio web</a>
            <div>
                <a class="me-2" *ngFor="let telefono of empresa.telefonos" [href]="'tel:' + telefono"
                    target="blank">{{telefono}}</a>
            </div>
            <!-- <p class="small text-muted mb-0">{{empresa.id}}</p> -->
        </div>
    </div>
</div>