import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);



@Component({
  selector: 'app-xvcnt',
  templateUrl: './xvcnt.component.html',
  styleUrls: ['./xvcnt.component.scss']
})
export class XvcntComponent implements OnInit {

  carousel = [
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/galeriaBicentenario%2Fdd.jpg?alt=media&token=666e95b2-dbec-45cc-a986-e355a00c081b',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/cnt%2FimagesCnt_0002_01.jpg?alt=media&token=29ce4b3f-d9d2-4ae4-8280-a294adec314a',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/cnt%2FimagesCnt_0001_02.jpg?alt=media&token=317601c3-4d19-4843-890b-5c3613efb6a4',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/cnt%2FimagesCnt_0000_03.jpg?alt=media&token=cc1e2fe2-4a18-4929-8f64-b0ec6f129d0e',
  ];

  reactivaciones = [
    {
      nombre: 'Innovación',
      descripciones: [
        'Desafíos, riesgos y oportunidades de la tecnología como protagonista de la recuperación del turismo'
      ]
    },
    {
      nombre: 'Conectividad',
      descripciones: [
        'Acciones realizadas por la operación aeroportuaria para contribuir con la reactivación del turismo.',
        'Recuperación en el corto y mediano plazo, proyecciones y oportunidades turismo nacional e internacional.'
      ]
    },
    {
      nombre: 'Hotelería',
      descripciones: [
        'Experiencias, conocimientos y aprendizajes en la reactivación del sector hotelero.'
      ]
    },
    {
      nombre: 'Tour Operadores',
      descripciones: [
        'Aprendizaje y propuestas para su recuperación'
      ]
    },
    {
      nombre: 'IATA Travel Pass',
      descripciones: [
        'Pasaporte digital sanitario para viajes, su implementación y beneficios para reactivar el turismo latinoamericano.'
      ]
    },
    {
      nombre: 'Reactivación de los destinos turísticos',
      descripciones: [
        'Planes y proyecciones del turismo post pandemia. experiencia México y República Dominicana.'
      ]
    },
    {
      nombre: 'Turismo Gastronómico',
      descripciones: [
        'Retos y oportunidades post pandemia'
      ]
    },
    {
      nombre: 'El Milagro de la cocina peruana',
      descripciones: [
        'Historia de unión e identidad, importancia de la gastronomía en el sector turismo.'
      ]
    },
    {
      nombre: 'Activación del Sistema Nacional de Reservas',
      descripciones: [
        'De Sitios Arqueológicos y Museos del Perú.'
      ]
    },
    {
      nombre: 'Plan de reactivación del Turismo Interno y Receptivo',
      descripciones: [
        'Casos: Argentina, Brasil, Colombia, Perú.'
      ]
    },
    {
      nombre: 'Turismo Receptivo Peruano',
      descripciones: [
        'Retos y oportunidades para su reactivación.'
      ]
    },
    {
      nombre: 'Sector Privado: Acciones de Promoción para la reactivación del Turismo Peruano',
      descripciones: [
        'Exposición "Machu Picchu and the Golden Empires of Peru.'
      ]
    },
    {
      nombre: 'Misiones Diplomáticas del Perú en el exterior',
      descripciones: [
        'Su rol en favor del turismo.'
      ]
    },
    {
      nombre: 'Iniciativas y acciones',
      descripciones: [
        'Por la reactivación.'
      ]
    },
    {
      nombre: 'El viajero del Futuro',
      descripciones: [
        'COVID 19 desde su inicio hasta la actualidad.'
      ]
    },
    {
      nombre: 'El Rol del profesional turístico post pandemia',
      descripciones: [
        'Innovar, reiventar, resilencia.'
      ]
    },
    {
      nombre: 'Reiniciar, adaptarse y prosperar',
      descripciones: [
        'El camino de la recuperación para los destinos.'
      ]
    },
    {
      nombre: 'Tursimo Regenerativo y consciente: Sostenibilidad para la reactivación del Turismo',
      descripciones: [
        'Un turismo diferente para la reactivación del sector turístico.',
        'Turismo regenerativo propuesta de valor y cambio en una nueva normalidad.'
      ]
    },
    {
      nombre: 'El valor de la diferenciación',
      descripciones: [
        'Casos de éxito.'
      ]
    },
    {
      nombre: 'Proyecciones económicas',
      descripciones: [
        'Para el turismo.'
      ]
    },
    {
      nombre: 'Arqueología, Historia y Turismo, una fórmula de éxito',
      descripciones: [
        'Cómo aporta el turismo en favor de la recuperación de los espacios arqueológicos.'
      ]
    },
    {
      nombre: 'Estabilidad jurídica',
      descripciones: [
        'Para las empresas turísticas.'
      ]
    },
    {
      nombre: 'Exportación de servicios',
      descripciones: [
        'Perspectivas para el futuro Post Covid.'
      ]
    },
    {
      nombre: 'Plan y estrategia de apoyo a los concesionarios de aeropuertos LAP, ADP y AAP',
      descripciones: [
        'Reactivar los flujos de pasajeros y número de operaciones aéreas a niveles pre pandemia.'
      ]
    },
    {
      nombre: 'Educación turística',
      descripciones: [
        'Base de identidad y Desarrollo del Perú.'
      ]
    },
  ];

  config: SwiperOptions = {
    spaceBetween: 20,
    pagination: { clickable: true },
  };

  constructor() { }

  ngOnInit(): void {
  }

}
