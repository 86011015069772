import { Component, OnInit } from '@angular/core';
import { Empresa } from 'src/app/classes/empresa';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {


  empresas: Empresa[] = [];

  agenciasViajes: Empresa[] = [];
  casinos: Empresa[] = [];
  hoteles: Empresa[] = [];
  institutosUniversidades: Empresa[] = [];
  proveedores: Empresa[] = [];
  restaurantes: Empresa[] = [];
  transportes: Empresa[] = [];
  trenes: Empresa[] = [];

  itemsGroup = [];
  itemsGroupOrder = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {

    this.fs.getEmpresas().subscribe( (data: Empresa[]) => {

      this.empresas = data;
      console.log(this.empresas);

      this.agenciasViajes = this.empresas.filter( fil => fil.categoria === 'Agencias de viajes');
      this.casinos = this.empresas.filter( fil => fil.categoria === 'Casinos');
      this.hoteles = this.empresas.filter( fil => fil.categoria === 'Hoteles');
      this.institutosUniversidades = this.empresas.filter( fil => fil.categoria === 'Institutos y Universidades');
      this.proveedores = this.empresas.filter( fil => fil.categoria === 'Proveedores');
      this.restaurantes = this.empresas.filter( fil => fil.categoria === 'Restaurantes');
      this.transportes = this.empresas.filter( fil => fil.categoria === 'Transportes');
      this.trenes = this.empresas.filter( fil => fil.categoria === 'Trenes');

      this.itemsGroup = this.empresas.reduce((prev, { categoria, ...items }) => {
        const id = prev.findIndex((item) => item.categoria === categoria);
        id >= 0
          ? prev[id].items.push(items)
          : prev.push({categoria, items: [items]});
        return prev;
      }, []);


      this.itemsGroupOrder = this.itemsGroup.sort( (a, b) => a.categoria < b.categoria ? -1 : a.categoria > b.categoria? 1 : 0 )

      // console.log(this.itemsGroupOrder);
      
      
    });

  }

}
