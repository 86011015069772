import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Noticia } from 'src/app/classes/noticia';
import { NoticiaHel } from 'src/app/classes/noticia-hel';
import { QueHacemos } from 'src/app/classes/que-hacemos';
import { FirebaseService } from 'src/app/services/firebase.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EliminarDirectivoComponent } from '../eliminar-directivo/eliminar-directivo.component';
import { Directivo } from 'src/app/classes/directivo';
import { PeriodoJuntaDirectivaComponent } from '../periodo-junta-directiva/periodo-junta-directiva.component';

@Component({
  selector: 'app-lista-directivos',
  templateUrl: './lista-directivos.component.html',
  styleUrls: ['./lista-directivos.component.scss']
})
export class ListaDirectivosComponent implements OnInit {

  loading!: boolean;
  displayedColumns = [ 'order', 'imagen', 'nombre', 'cargo', 'publicado', 'editar', 'mas' ];
  directivosData = new MatTableDataSource();
  value: any;

  periodo: any = '';

  opciones = [
    {
      nombre: 'Eliminar',
      icon: 'trash',
      function: (directivo: Directivo) => this.eliminarItem(directivo)
    }
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.directivosData.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private fs: FirebaseService,
    private matDialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {

    this.afs.collection('juntaDirectiva').doc('zU4clnyjs8I4N6Pu1B7b').collection('directivos', ref => ref
    .orderBy('order', 'asc')
    ).valueChanges().subscribe((data: QueHacemos[]) => {
      this.directivosData.data = data;
      this.loading = false;
    });


    // get document Período
    this.afs.collection('juntaDirectiva').doc('zU4clnyjs8I4N6Pu1B7b').valueChanges().subscribe( (res: any) => {
      this.periodo = res;
    });



    this.directivosData.paginator = this.paginator;
    this.directivosData.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.directivosData.data, event.previousIndex, event.currentIndex);
    this.directivosData.data.map( (element: any, index: number )=> {
      this.afs.collection('juntaDirectiva').doc('zU4clnyjs8I4N6Pu1B7b').collection('directivos').doc(element.id).update({
        order: index + 1
      });
    });
  }

  clearFilters() {
    this.directivosData.filter = '';
    this.value = '';
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.directivosData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  // actualizarPublicado(key, obj, e) {
  //   this.fs.updatePublicadoGremio(key, e);
  // }

  
  actualizarPublicado(idItem: string, change: MatSlideToggleChange) {
    this.afs.collection('juntaDirectiva/zU4clnyjs8I4N6Pu1B7b/directivos').doc(idItem).update({
      publicado: change.checked
    });
  }

  trackByPublicado(item: any) {
    return item.publicado;
  }

  editarItem(item: Directivo) {
    this.router.navigate([`admin/nosotros/juntaDirectiva/editarDirectivo/${item.id}`]);
  }

  eliminarItem(item: Directivo) {
    this.matDialog.open(EliminarDirectivoComponent, {
      panelClass: 'dialogSmall',
      data: item
    });
  }

  openEditPeriodo() {
    this.matDialog.open(PeriodoJuntaDirectivaComponent, {
      panelClass: 'dialogSmall',
      data: this.periodo
    })
  }

}
