<!-- <div class="bgSeccionHome rounded border border-primary position-relative h-100" [style.backgroundImage]="'url('+seccion.image+')'">
    <div class="bgGradientSeccion rounded p-3 p-md-4 w-100 h-100 d-flex">
        <div class="">
            <p class="h4 font-weight-bold">{{seccion.nombre}}</p>
            <p class="">{{seccion.descripcion}}</p>
            <a class="btn btn-primary stretched-link" *ngIf="seccion.tipoUrl ==='interno'" [routerLink]="seccion.url" [fragment]="seccion.fragment">{{seccion.nombreBoton}}</a>
            <a class="btn btn-primary stretched-link" [href]="seccion.url" target="blank" *ngIf="seccion.tipoUrl ==='externo'">{{seccion.nombreBoton}}</a>
        </div>
    </div>
</div> -->

<!-- <div class="bgSeccionHome rounded border border-2 border-primary position-relative h-100">
  <div class="rounded p-4 p-md-5 w-100 h-100 d-flex">
    <div class="">
      <p class="h4 fw-bold">{{seccion.nombre}}</p>
      <p class="">{{seccion.descripcion}}</p>
      <a class="btn btn-primary stretched-link" *ngIf="seccion.tipoUrl ==='interno'" [routerLink]="seccion.url"
        [fragment]="seccion.fragment">{{seccion.nombreBoton}}</a>
      <a class="btn btn-primary stretched-link" [href]="seccion.url" target="blank"
        *ngIf="seccion.tipoUrl ==='externo'">{{seccion.nombreBoton}}</a>
    </div>
  </div>
</div> -->


<div class="bg-white rounded-3 border border-2 border-primary position-relative h-100">
  <div class="p-3 p-md-4 w-100 h-100 d-flex gap-3">
    <div>
      <img [src]="seccion.image" alt="" width="120">
    </div>
    <div class="">
      <p class="h4 fw-bold">{{seccion.nombre}}</p>
      <p class="">{{seccion.descripcion}}</p>
      <a class="btn btn-primary stretched-link" *ngIf="seccion.tipoUrl ==='interno'" [routerLink]="seccion.url" [fragment]="seccion.fragment">{{seccion.nombreBoton}}</a>
      <a class="btn btn-primary stretched-link" [href]="seccion.url" target="blank" *ngIf="seccion.tipoUrl ==='externo'">{{seccion.nombreBoton}}</a>
    </div>
  </div>
</div>