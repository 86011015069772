<div class="container">

  <nav class="small mt-3">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
        <li class="breadcrumb-item"><a routerLink="/asociados">Asociados</a></li>
        <li class="breadcrumb-item active text-muted">Cámaras Regionales</li>
    </ol>
</nav>


<div class="py-4 py-md-5">
    <div class="text-center mb-4 mb-md-6">
        <p class="h1">Cámaras Regionales</p>
    </div>
    <div class="grillaCamarasRegionales">
        <div class="border rounded-3 p-3 p-md-4" *ngFor="let camara of camarasRegionales">

            <p class="h5 text-uppercase">{{camara.nombre}}</p>

            <span class="text-muted small">Presidente</span>
            <p>{{camara.presidente}}</p>

            <span class="text-muted small">Contacto</span>
            <div class="d-flex gap-2 flex-column mb-3">
              <div class="d-flex gap-1" *ngFor="let telefono of camara.telefonos">
                <i-tabler name="phone" style="width: 20px;"></i-tabler>
                <a [href]="'tel:' + telefono" >{{telefono}}</a>
              </div>
            </div>

            <span class="text-muted small">Email</span>
            <div class="d-flex gap-2 flex-column">
              <div class="d-flex gap-1" *ngFor="let email of camara.emails">
                <div>
                  <i-tabler name="mail" style="width: 20px;"></i-tabler>
                </div>
                <a [href]="'mailto:' + email" style="word-break: break-all;">{{email}}</a>
              </div>
            </div>
        </div>
    </div>
</div>

</div>

