<div class="bg-light">

	<div class=" d-none d-md-block">
		<div class="container d-flex justify-content-end align-items-center pt-2">
			<a class="small" href="tel:017155983">(511) 715 5983</a>
			<a class="small mx-3" href="mailto:asistente@canaturperu.org">asistente@canaturperu.org</a>
			<a class="btn btn-primary myButtonCircleRedSocial p-0 ms-1" *ngFor="let redSocial of redesSociales"	[href]="redSocial.url" target="blank">
				<i-tabler [name]="redSocial.icon" style="width: 20px; height: 20px; margin-top: 2px;"></i-tabler>
			</a>
			<a class="btn btn-sm btn-outline-primary mx-2" href="https://portaldeturismo.pe/"
				target="blank">portaldeturismo.pe</a>
			<a class="btn btn-sm btn-dark" routerLink="/unete">ÚNETE</a>
		</div>
	</div>

	<div class="container d-flex justify-content-between align-items-center">
		<a class="my-3" routerLink="/">
			<img class="logoCanatur" src="assets/images/logoCanatur.svg" alt="Logo Canatur">
		</a>
		<div class="">
			<div class=" d-none d-md-block">
				<div class="d-flex flex-wrap justify-content-end">


					<!-- <div *ngFor="let link of links; let i = index">
						<a class="text-decoration-none text-dark ms-3" [routerLink]="link.url" [matMenuTriggerFor]="menu">{{link.nombre}}</a>
						
						<mat-menu #menu="matMenu" [ngClass]=" link.submenu ? 'text-primary' : 'd-none' ">	
							<a mat-menu-item [routerLink]="item.url" *ngFor="let item of link.submenu">{{item.nombre}}</a>
						</mat-menu>
					</div> -->

					<a *ngFor="let link of links | slice:0:2" class="text-dark ms-3" [routerLink]="link.url" >{{link.nombre}}</a>
					<div *ngFor="let link of links | slice:2:4">
						<a class="text-dark ms-3" type="button" [matMenuTriggerFor]="menu" >{{link.nombre}}</a>
						<mat-menu #menu="matMenu">	
							<a mat-menu-item [routerLink]="item.url" *ngFor="let item of link.submenu">{{item.nombre}}</a>
						</mat-menu>
					</div>
					<a *ngFor="let link of links | slice:4:8" class="text-dark ms-3" [routerLink]="link.url" >{{link.nombre}}</a>

					<!-- <a class="linkMenuDesktop" routerLink="/nosotros">Nosotros</a>
					<a class="linkMenuDesktop" routerLink="/estatutos">Estatutos</a>
					<a class="linkMenuDesktop" routerLink="/asociados">Asociados</a>
					<a class="linkMenuDesktop" [matMenuTriggerFor]="menu" role="button" >Eventos</a>
					<mat-menu #menu="matMenu">
						<a mat-menu-item routerLink="/eventos/eventos">Eventos</a>
						<a mat-menu-item routerLink="/eventos/xvCongresoNacionalTurismo">Congreso NT</a>
					</mat-menu>
					<a class="linkMenuDesktop" routerLink="/marketplace">Marketplace</a>
					<a class="linkMenuDesktop" routerLink="/alianzas">Alianzas</a>
					<a class="linkMenuDesktop" routerLink="/helvetas">Proyecto SeCompetitivo</a>
					<a class="linkMenuDesktop" routerLink="/noticias">Noticias</a> -->

				</div>
			</div>
			<div class="d-block d-md-none">
				<button mat-icon-button (click)="openModalMenuMobile()"><img src="assets/images/iconMenuMobile.svg"></button>
			</div>
		</div>
	</div>
</div>