import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CamaraRegional } from 'src/app/classes/camara-regional';

@Component({
  selector: 'app-editar-camara-regional',
  templateUrl: './editar-camara-regional.component.html',
  styleUrls: ['./editar-camara-regional.component.scss']
})
export class EditarCamaraRegionalComponent implements OnInit {

  formCamaraRegional: FormGroup;
  disabled = true;
  loading = false;

  seccion = {
    breadCrumb: [
      {
        nombre: 'Asociados',
        url: '/admin/asociados'
      },
      {
        nombre: 'Cámaras Regionales',
        url: '/admin/asociados/camarasRegionales'
      },
    ],
    back: '/admin/asociados/camarasRegionales',
    titulo: 'Editar Cámara Regional',
    urlButton: ''
  };

  camara: CamaraRegional;
  id: string;

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  
  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.params.id;
    console.log(this.id);

    this.afs.doc(`camarasRegionales/${this.id}`).valueChanges().subscribe( (res: CamaraRegional) => {

      this.camara = res;
      // console.log(this.camara);
      
      this.formCamaraRegional = this.fb.group({
        nombre: [this.camara.nombre, Validators.required],
        presidente: [this.camara.presidente, Validators.required],
        telefonos: this.fb.array(this.camara.telefonos),
        emails: this.fb.array(this.camara.emails),
        // publicado: false,
        fechaEdicion: new Date(),
      });
    });
    

  }

  onSubmit() {
    if (this.formCamaraRegional.valid) {
      this.disabled = false;
      this.loading = true;
      this.editarCamaraRegional();
    }
  }

  editarCamaraRegional() {
    this.afs.doc(`camarasRegionales/${this.id}`).update(this.formCamaraRegional.value)
    .then( () => {
      this.router.navigate(['/admin/asociados/camarasRegionales']);
    });
  }

  agregarTelefono() {
    const telefonos = this.formCamaraRegional.get('telefonos') as FormArray;
    telefonos.push(
      this.fb.control('', Validators.required)
    );
  }

  eliminarTelefono(i) {
    const telefonos = this.formCamaraRegional.get('telefonos') as FormArray;
    telefonos.removeAt(i);
  }

  agregarEmail() {
    const emails = this.formCamaraRegional.get('emails') as FormArray;
    emails.push(
      this.fb.control('', Validators.required)
    );
  }

  eliminarEmail(i) {
    const emails = this.formCamaraRegional.get('emails') as FormArray;
    emails.removeAt(i);
  }

}
