import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-periodo-junta-directiva',
  templateUrl: './periodo-junta-directiva.component.html',
  styleUrls: ['./periodo-junta-directiva.component.scss']
})
export class PeriodoJuntaDirectivaComponent implements OnInit {

  loading = false;
  periodo = new FormControl(this.data.periodo)

  constructor(
    private dialogRef: MatDialogRef<PeriodoJuntaDirectivaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    // this.periodo = ['', Validators.required]
  }

  cambiarPeriodo() {
    this.loading = true;
    this.afs.collection('juntaDirectiva').doc('zU4clnyjs8I4N6Pu1B7b').update({
      periodo: this.periodo.value
    }).then( () => {
      this.dialogRef.close();
    });
  }

  cancelar() {
    this.dialogRef.close();
  }

}
