import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { IdValidatorService } from 'src/app/services/id-validator.service';
import { BannerHel } from 'src/app/classes/banner-hel';

@Component({
  selector: 'app-editar-banner',
  templateUrl: './editar-banner.component.html',
  styleUrls: ['./editar-banner.component.scss']
})
export class EditarBannerComponent implements OnInit {

  formBannerHel!: UntypedFormGroup;
  loading = false;
  bannerHelId: string;
  bannerHel: BannerHel;

  selectedFile!: FileList | null;
  nameItem: any;
  uploadPercent!: Observable<number> | unknown;
  downloadURL!: Observable<string> | unknown;
  readonly maxSize = 1048576 * 5;
  actualSize: any;
  today = new Date();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
      
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {
      
      this.bannerHelId = res.id;

      this.afs.collection('bannersHel').doc(this.bannerHelId).valueChanges().subscribe( (res: BannerHel) => {

        this.bannerHel = res;

        this.formBannerHel = this.fb.group({
          titulo: [this.bannerHel.titulo],
          descripcion: [this.bannerHel.descripcion],
          urlButton: [this.bannerHel.urlButton],
          // id: [this.bannerHelId],
          image: ['', [ FileValidator.maxContentSize(this.maxSize)]],
          imageName: [''],
          // publicado: [false],
          fechaEdicion: [Timestamp.now()]
        });


      });

     

    });

  }

  onSubmit() {
    if (this.formBannerHel.valid) {
      this.loading = true;
      if (this.formBannerHel.get('image')?.value === '') {
        this.editarBannerHel();
      } else {
        this.uploadImageAndCreateBannerHel();
      }
    } else {
      this.validateAllFormFields(this.formBannerHel);
    }
  }

  editarBannerHel() {

    const banner = this.formBannerHel.value;
    ['image', 'imageName'].forEach(e => delete banner[e]);

    this.afs.doc(`bannersHel/${this.bannerHelId}`).update(this.formBannerHel.value)
    .then(() => {
      this.router.navigate(['/admin/helvetas/banners']);
      console.log('Banner Hel creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  detectFiles(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }


  uploadImageAndCreateBannerHel() {
    const nombreImage = this.nameItem.split('.');

    const file = this.selectedFile;
    const filePath = `imagesBannersHel/${this.bannerHelId}.${nombreImage[1]}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( url => {

        this.afs.doc(`bannersHel/${this.bannerHelId}`).update({
          ...this.formBannerHel.value,
          image: url,
          // imageName: `${this.bannerHelId}.${nombreImage[1]}`
        });
        this.router.navigate(['/admin/helvetas/banners']);
        console.log(url);

        });
        }
        )).subscribe();
  }

  errorImagen() {
    return this.formBannerHel.controls.image.hasError('maxContentSize') ? 'El peso no debe exceder los 5 MB' : '';
  }

  errorBody() {
    return this.formBannerHel.controls.body.touched && this.formBannerHel.controls.body.hasError('required') ? 'Incluye una descripción de la noticia' : '';
  }

}
