import { Component, OnInit } from '@angular/core';
import { BannerFtp } from 'src/app/classes/banner-ftp';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-banners-ftp',
  templateUrl: './banners-ftp.component.html',
  styleUrls: ['./banners-ftp.component.scss']
})
export class BannersFtpComponent implements OnInit {

  bannersFtp: BannerFtp[] = [];

  constructor(
    private fs: FirebaseService,
  ) { }

  ngOnInit(): void {
    this.fs.getBannersFtp().subscribe( res => {
      this.bannersFtp = res;
      console.log(this.bannersFtp);
    })
  }

}
