<nav class="container small mt-3">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
        <li class="breadcrumb-item"><a routerLink="/asociados">Asociados</a></li>
        <li class="breadcrumb-item active text-muted">Empresas Asociadas</li>
    </ol>
</nav>
<div class="container py-4 py-md-5">
    <p class="h1 mb-5 mb-md-7 text-center">Empresas Asociadas</p>

    <p class="titleGroup">Agencias de Viaje</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasAgenciasViaje" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

    <p class="titleGroup">Casinos</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasCasinos" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

    <p class="titleGroup">Hoteles</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasHoteles" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

    <p class="titleGroup">Institutos y Universidades</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasInstitutosUniversidades" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

    <p class="titleGroup">Proveedores</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasProveedores" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

    <p class="titleGroup">Restaurantes</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasRestaurantes" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

    <p class="titleGroup">Transporte</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasTransporte" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

    <p class="titleGroup">Trenes</p>
    <div class="grillaEmpresasAsociadas">
        <app-card-empresa-asociada *ngFor="let empresa of empresasAsociadasTrenes" [empresa]="empresa"></app-card-empresa-asociada>
    </div>

</div>