import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eliminar-boletin',
  templateUrl: './eliminar-boletin.component.html',
  styleUrls: ['./eliminar-boletin.component.scss']
})
export class EliminarBoletinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
