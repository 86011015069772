<div class="container">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/nosotros">Nosotros</a></li>
      <!-- <li class="breadcrumb-item"><a routerLink="/admin/asociados/gremios">Gremios</a></li> -->
    </ol>
  </nav>


  <div class="d-flex justify-content-between align-items-center mb-2 mb-md-3 gap-2">

    <div class="d-flex align-items-center">
      <button mat-icon-button routerLink="/admin/nosotros">
        <i-tabler name="arrow-left" class="text-primary"></i-tabler>
      </button>
      <p class="h4 mb-0">Junta directiva</p>
    </div>

    <div class="d-flex align-items-center gap-1" *ngIf="periodo">
      <p class="mb-0">{{periodo.periodo}}</p>
      <button mat-icon-button (click)="openEditPeriodo()">
        <i-tabler name="pencil" class="text-primary"></i-tabler>
      </button>
    </div>
   
  </div>


  <div class="d-flex gap-2 gap-md-3">
    <mat-form-field appearance="outline" class="w-100">
      <input matInput (keyup)="applyFilter($event)" [(ngModel)]="value" placeholder="Buscar">
      <mat-icon *ngIf="!value" matSuffix class="text-muted">search</mat-icon>
      <button mat-button *ngIf="value" matSuffix mat-icon-button (click)="clearFilters()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="flex-shrink-0">
      <a class="btn btn-primary d-inline-flex align-items-center" style="margin-top: 4px; height: 59.5px" routerLink="/admin/nosotros/juntaDirectiva/crearDirectivo">
        <div class="d-flex">
          <i-tabler name="plus"></i-tabler>
          <span class="ms-1 d-none d-md-inline-block">Agregar directivo</span>
        </div>
      </a>
    </div>
  </div>



  <div class="table-responsive" cdkDropList (cdkDropListDropped)="drop($event)">

    <table mat-table [dataSource]="directivosData" matSort [trackBy]="trackByPublicado">

      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Orden</th>
        <td mat-cell *matCellDef="let item; let i = index">{{i + 1}}</td>
      </ng-container>

      <ng-container matColumnDef="imagen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</th>
        <td mat-cell *matCellDef="let item"> <img class="imageNoticiaAdmin border my-1 rounded"
            [src]="item.image || 'assets/images/imageFakerCamara.png' "></td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
        <td mat-cell *matCellDef="let item"> {{item.nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="cargo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cargo</th>
        <td mat-cell *matCellDef="let item"> {{item.cargo}} </td>
      </ng-container>
      
      <ng-container matColumnDef="categoria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría</th>
        <td mat-cell *matCellDef="let item"> {{item.categoria}} </td>
      </ng-container>  

      <ng-container matColumnDef="publicado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Publicado</th>
        <td mat-cell *matCellDef="let item">
          <mat-slide-toggle color="primary" #toggle [checked]="item.publicado"
          (change)="actualizarPublicado(item.id, $event)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="editar">
        <th mat-header-cell *matHeaderCellDef>Editar</th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="editarItem(item)">
            <i-tabler name="pencil"></i-tabler>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="eliminar">
        <th mat-header-cell *matHeaderCellDef>Eliminar</th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="eliminarItem(item)">
            <i-tabler name="trash"></i-tabler>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="mas">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" class="text-end">

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let option of opciones" (click)="option.function(item)">
              <i-tabler [name]="option.icon"></i-tabler>
              <span class="ms-2">{{option.nombre}}</span>
            </button>
          </mat-menu>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></tr>
    </table>
  </div>


  <mat-paginator [pageSize]="50" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
  </mat-paginator>

</div>