import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-leer-noticia',
  templateUrl: './leer-noticia.component.html',
  styleUrls: ['./leer-noticia.component.scss']
})
export class LeerNoticiaComponent implements OnInit {

  idNoticia;
  noticia;
  loader = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(res => {
      this.idNoticia = res.id;
      this.afs.doc('noticias/' + this.idNoticia).valueChanges().subscribe( data => {
        this.noticia = data;
        this.loader = false;
      });
    });
  }

}
