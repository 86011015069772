import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-vxicnt',
  templateUrl: './vxicnt.component.html',
  styleUrls: ['./vxicnt.component.scss']
})
export class VxicntComponent implements OnInit {

  marcasHeader = [

    {
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosCanatur%2FlogoCanaturColor.svg?alt=media&token=c208c4cb-7274-435c-9a28-23c45047ad9',
      width: '100'
    },

    {
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoMincetur.svg?alt=media&token=1ca823a8-8ce0-4a5c-8764-551e0d7b78f3',
      width: '100'
    },
  
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoPunchePeru.svg?alt=media&token=0f1f6fee-4a2d-40de-be6f-d6cd5791740f',
      width: '60'
    },
    // {
    //   image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoBicentenario.svg?alt=media&token=f4fd8f6e-7ab8-486b-9b0a-f21e5854f81d',
    //   width: '180'
    // },

  ];

  videos = [

    {
      nombre: 'Joe Koechlin, Presidente de #CANATUR en Conversatorio de la Universidad Ricardo Palma',
      url: 'https://www.youtube.com/embed/TZn--BImi5A?si=op5ovwj4bArIOprH'
    },
    {
      nombre: 'Participa como expositor - Feria Turismo Perú 2022',
      url: 'https://www.youtube.com/embed/lzhXDQYuCSk?si=DqzquMslFZUWBWPK'
    },
    {
      nombre: 'Perú Travel Mart 2022',
      url: 'https://www.youtube.com/embed/zOY2utgh8hE?si=CHGBmlESfF4EabBq'
    },
    {
      nombre: 'Entrevista a Carlos Loayza, Gerente General de CANATUR',
      url: 'https://www.youtube.com/embed/XxZx-cdk57g?si=Qldx8CXHb4z5CoHu'
    },

  ];


  programa = [
    {
      dia: 'Lunes 4 de Diciembre 2023',
      bloques: [

        {
          nombre: 'Acto Inaugural del Evento',
          eventos: [
            // {
            //   horario: '8:00 - 8:25',
            //   titulo: 'Recepción participantes presencial',
            //   ponente: ''
            // },
            {
              horario: '8:25 - 8:30',
              titulo: 'Palabras a cargo del Presidente de la comisión de organización del Congreso Nacional de Turismo - CANATUR',
              ponente: 'Tito Alegría Negrón - Presidente CNT',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Tito Alegría',
                cargo: 'Presidente CNT'
              }
            },
            {
              horario: '8:30 - 8:35',
              titulo: 'Palabras a cargo del Presidente de la Comisión de Comercio Exterior y Turismo',
              ponente: 'Hamlet Echeverría Rodríguez - Presidente de la Comisión de Comercio Exterior y Turismo',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Hamlet Echeverría Rodríguez',
                cargo: 'Presidente de la Comisión de Comercio Exterior y Turismo'
              }
            },
            {
              horario: '8:35 - 8:40',
              titulo: 'Palabras de Inauguración a cargo del Ministro de Comercio Exterior y Turismo',
              ponente: 'Juan Carlos Mathews - Ministro MINCETUR',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Juan Carlos Mathews',
                cargo: 'Ministro MINCETUR'
              }
            },
          ],
   
        },


        {
          nombre: 'Bloque 1 - DESARROLLO TURÍSTICO : PROYECCIONES, INDICADORES Y ESTRATEGIAS',
          eventos: [
            {
              horario: '8:40 - 8:45',
              titulo: 'Introducción y presentación',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Jose Koechlin Von Stein',
                cargo: 'Presidente CANATUR'
              }
            },
            {
              horario: '8:45 - 9:10',
              titulo: 'Evolución del Turismo a nivel mundial en los próximos años',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Sr. Antonio Santos',
                cargo: 'Presidente del Tourism and Society Think Tank'
              }
            },
            {
              horario: '9:10 - 9:35',
              titulo: 'Indicadores de Competitividad y sostenibilidad en Turismo',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Gustavo Santos',
                cargo: 'Director Regional para las Américas de la Organización Mundial del Turismo (OMT)'
              }
            },
            {
              horario: '9:35 - 10:00',
              titulo: 'Estrategias para impulsar el desarrollo, el empleo y el bienestar en América Latina y El Caribe',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Oscar Rueda García',
                cargo: 'Dirección de Turismo CAF'
              }
            },
            {
              horario: '10:00 - 10:25',
              titulo: 'Proyección del Sector Turismo en el Perú: Desarrollo económico y competitividad',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Juan Carlos Mathews',
                cargo: 'Ministro MINCETUR'
              }
            },
            {
              horario: '10:25 - 10:50',
              titulo: 'Rol del estado a favor del Desarrollo Turístico',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Hamlet Echeverría Rodríguez',
                cargo: 'Presidente de la Comisión de Comercio Exterior y Turismo'
              }
            },
            // {
            //   horario: '10:50 - 10:55',
            //   titulo: 'Cierre del bloque a cargo de MINCETUR',
            //   ponente: '',
            //   image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.jpg?alt=media&token=18027a42-5337-4e4f-97ac-5046ee939c4a'
            // },
            // {
            //   horario: '10:55 - 11:25',
            //   titulo: 'Coffee Break',
            //   ponente: '',
            //   break: true
            // },
          ],
        },


        {
          nombre: 'BLOQUE 2: INNOVACIÓN TURÍSTICA : VISIÓN GLOBAL Y REGIONAL',
          eventos: [
            {
              horario: '11:25 - 11:30',
              titulo: 'Introducción y presentación',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserFemale.png?alt=media&token=e0c50294-10a1-4c4f-a126-265d24758467',
                nombre: 'Claudia Burga Casanova',
                cargo: 'Directrora de la Dirección de Innovación de la Oferta Turística'
              }
            },
            {
              horario: '11:30 - 11:55',
              titulo: 'Aportes desde la academia a favor de la innovación turística',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Carlos Villena Lescano',
                cargo: 'Director de la Escuela de Turismo, Hotelería y Gastronomía de la URP'
              }
            },
            {
              horario: '11:55 - 12:20',
              titulo: 'Sistemas de inteligencia turística, el corazón del los DTI',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Gonzalo La Rosa',
                cargo: 'Secretario técnico de la Red Iberoamericana de Destinos Inteligentes - DTI'
              }
            },
            {
              horario: '12:20 - 12:45',
              titulo: 'Destinos turísticos inteligentes y la red iberoamericana de DTI',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserFemale.png?alt=media&token=e0c50294-10a1-4c4f-a126-265d24758467',
                nombre: 'Ledys López Zapata',
                cargo: 'Sub secretaria de turismo de Medellin – Representante Red Iberoamericana de DTI'
              }
            },
            // {
            //   horario: '12:45 - 12:50',
            //   titulo: 'Cierre del bloque',
            //   ponente: ''
            // },
            // {
            //   horario: '12:50 - 14:05',
            //   titulo: 'Almuerzo',
            //   ponente: '',
            //   break: true
            // },
          ],
        },


        {
          nombre: 'BLOQUE 3: EMPRENDIMIENTO : TENDENCIAS Y OPORTUNIDADES',
          eventos: [
            {
              horario: '14:05 - 14:10',
              titulo: 'Introducción y presentación',
              ponente: 'Francisco Basili - Presidente en APTAE',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Francisco Basili',
                cargo: 'Presidente en APTAE'
              }
            },
            {
              horario: '14:10 - 14:35',
              titulo: 'Inversión para la conservación: Inversiones y emprendimientos turísticos en áreas naturales protegidas',
              ponente: 'Juan Carlos Heaton Alfaro - Responsable Unidad de turismo - SERNANP',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Juan Carlos Heaton Alfaro',
                cargo: 'Responsable Unidad de turismo - SERNANP'
              }
            },
            {
              horario: '14:35 -15:00',
              titulo: 'Turismo comunitario, experiencias en conservación',
              ponente: 'Adan Fabian - Presidente de la. Asociación de Aguajales y Renacales del Alto Mayo Rio Avisado - Tingana',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Adan Fabian',
                cargo: 'Presidente de la. Asociación de Aguajales y Renacales del Alto Mayo Rio Avisado - Tingana'
              }
            },
            {
              horario: '15:00 - 15:40',
              titulo: 'Panel: Turismo Emprende como herramienta para la reactivación de la micro y pequeñas empresas del sector turismo y su contribución al empoderamiento de la mujer',
              ponente: 'Paola Torres - Mujer emprendedora Ica - Janeth Luna Dávila - Mujer emprendedora San Martín - Lamas',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserFemale.png?alt=media&token=e0c50294-10a1-4c4f-a126-265d24758467',
                nombre: 'Paola Torres',
                cargo: 'Mujer emprendedora Ica - Janeth Luna Dávila - Mujer emprendedora San Martín - Lamas'
              }
            },
            {
              horario: '15:40 - 16:05',
              titulo: 'Mecanismos de fomento a la inversión privada para proyectos turísticos sostenibles',
              ponente: 'Daniel Soria - Ex Gerente de las empresas Corporacion Hotelera Lima SAC, que se acogieron al RERA y ejecutaron proyectos hoteleros bajo este régimen',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Daniel Soria',
                cargo: 'Ex Gerente de las empresas Corporacion Hotelera Lima SAC, que se acogieron al RERA y ejecutaron proyectos hoteleros bajo este régimen'
              }
            },
            // {
            //   horario: '16:05 -16:15',
            //   titulo: 'Cierre del bloque',
            //   ponente: 'Tito Alegría - Presidente CNT'
            // },
            // {
            //   horario: '16:15 - 16:45',
            //   titulo: 'Coffee break',
            //   ponente: '',
            //   break: true
            // },
          ],
        },



        {
          nombre: 'BLOQUE 4: INFRAESTRUCTURA: ANÁLISIS Y PROYECCIÓN',
          eventos: [
            {
              horario: '16:45- 16:50',
              titulo: 'Introducción y presentación',
              ponente: 'Antonio Olórtegui - APEA',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Antonio Olórtegui',
                cargo: 'APEA'
              }
            },
            {
              horario: '16:50 -17:15',
              titulo: 'Desarrollo e inversión en infraestructura aeroportuaria en favor del desarrollo turístico',
              ponente: 'Juan Jose Salmon Balestra - CEO de Lima Airport Partners',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Juan Jose Salmon Balestra',
                cargo: 'CEO de Lima Airport Partners'
              }
            },
            {
              horario: '17:15 - 17:40',
              titulo: 'Promoción de proyectos de inversión a favor del turismo',
              ponente: 'Dr. Carlos Carrizales - Abogado PPU',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Dr. Carlos Carrizales',
                cargo: 'Abogado PPU'
              }
            },
            {
              horario: '17:40 - 18:05',
              titulo: 'La inversión en infraestructura turística y su contribución en el desarrollo del País',
              ponente: 'Arturo Garcia Rosa - President & CEO SAHIC Group',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'Arturo Garcia Rosa',
                cargo: 'President & CEO SAHIC Group'
              }
            },
            {
              horario: '18:05 - 18:25',
              titulo: 'MINCETUR LAB',
              ponente: 'María del Pilar Sanchez y Victor Freundt Melendez Especialistas de la Dirección de Innovación de la Oferta Turística Interacción con Mincetur Lab y demostración de los logros que tienen los distintos laboratorios FabLabs. Considerar la interacción para el final del evento ( realidad virtual e impresión en 3D).',
              expositor: {
                photo: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FuserMale.png?alt=media&token=c9b6db23-3601-42fe-8f96-0078ac2d5de9',
                nombre: 'María del Pilar Sanchez y Victor Freundt Melendez',
                cargo: 'Especialistas de la Dirección de Innovación de la Oferta Turística Interacción con Mincetur Lab y demostración de los logros que tienen los distintos laboratorios FabLabs'
              }
            },
            // {
            //   horario: '18:25 -18:35',
            //   titulo: 'Cierre del bloque',
            //   ponente: 'Tito Alegría - Presidente CNT',
            //   expositor: {
            //     photo: '',
            //     nombre: '',
            //     cargo: ''
            //   }
            // },
          ],

        },



      ]
    },













    // {
    //   dia: 'Martes 5 de Diciembre 2023',
    //   bloques: [

      
    //     {
    //       nombre: 'BIENVIENDA 2DO. DÍA',
    //       eventos: [
    //         {
    //           horario: '8:00 - 8:30',
    //           titulo: 'Recepción participantes',
    //           ponente: ''
    //         },
    //         {
    //           horario: '8:30- 8:35',
    //           titulo: 'Palabras de bienvenida a cargo del Presidente de la comisión',
    //           ponente: 'Tito Alegría - Presidente CNT'
    //         },
    //       ],
    //     },




    //     {
    //       nombre: 'BLOQUE 5: CALIDAD TURÍSTICA : RETOS Y APORTES',
    //       eventos: [
    //         {
    //           horario: '8:35 - 8:40',
    //           titulo: 'Introducción y presentación',
    //           ponente: 'Graciela Seminario Marón - Directora Nacional de CENFOTUR'
    //         },
    //         {
    //           horario: '8:40 - 9:05',
    //           titulo: 'Mejora de la competitividad del capital humano a través de la evaluación y certificación de competencias laborales, en alianza con APEC - Alianza del Pacífico',
    //           ponente: 'Estéfany Adriazola Fernández - Sub Directora de Evaluación de Competencias Laborales de CENFOTUR'
    //         },
    //         {
    //           horario: '9:05 - 9:30',
    //           titulo: 'Programa CALTUR - Casos de éxito',
    //           ponente: 'Jaquelin Ramírez - Beneficiarios del PROGRAMA CALTUR'
    //         },
    //         {
    //           horario: '9:30 - 9:55',
    //           titulo: 'Aportes al posicionamiento de la gastronomía peruana desde la academia',
    //           ponente: 'Sra. Sandra Zubieta Zamudio - Decana de la Universidad San Ignacio de Loyola'
    //         },
    //         {
    //           horario: '9:55 - 10:05',
    //           titulo: 'Cierre del bloque',
    //           ponente: 'Tito Alegría - Presidente CNT'
    //         },
    //         {
    //           horario: '10:20 - 10:50',
    //           titulo: 'Coffee break',
    //           ponente: '',
    //           break: true
    //         },
    //       ],
    //     },




    //     {
    //       nombre: 'BLOQUE 6 : GESTIÓN DE DESTINOS: EXPERIENCIA NACIONAL Y LATINOAMERICANA',
    //       eventos: [
    //         {
    //           horario: '10:05 - 10:10',
    //           titulo: 'Introducción y presentación',
    //           ponente: 'Zoila Cavero Reap - Directora General de Estrateiga Turística - DGET'
    //         },
    //         {
    //           horario: '10:10 - 10:45',
    //           titulo: 'Experiencia de gestión de pueblos turísticos: Una mirada desde latinoamérica',
    //           ponente: 'Alejandro Reyes - Director general de Planeación y Desarrollo Turístico Secretaría de México'
    //         },
    //         {
    //           horario: '11:40 - 12:10',
    //           titulo: 'El valor de la accesibilidad en la gestión de destinos turísticos',
    //           ponente: 'Rosa María García - Coordinadora de Turismo Social - MINCETUR'
    //         },
    //         {
    //           horario: '12:10 - 12:40',
    //           titulo: 'El futuro de la industria de reuniones: Una visión hacia nuevas oportunidades de negocios en las regiones del País',
    //           ponente: 'Denisse Mac Cubbin - Buro de Lima'
    //         },
    //         {
    //           horario: '12:40 - 13:10',
    //           titulo: 'Caso de éxito: Gestión de destino en el Centro Histórico de Lima, a partir de la recuperación de su patrimonio histórico cultural',
    //           ponente: 'Arq. Luis Martín Bogdanovich - Gerente del Programa para la Recuperación del Centro Histórico de Lima - PROLIMA'
    //         },
    //         {
    //           horario: '13:10 - 13:20',
    //           titulo: 'Cierre del bloque',
    //           ponente: 'Tito Alegría - Presidente CNT'
    //         },
    //         {
    //           horario: '13:20 - 14:50',
    //           titulo: 'Almuerzo',
    //           ponente: '',
    //           break: true
    //         },
    //       ],
    //     },




    //     {
    //       nombre: 'BLOQUE 7: SOSTENIBILIDAD : TENDENCIAS Y OPORTUNIDADES',
    //       eventos: [
    //         {
    //           horario: '14:50 - 14:55',
    //           titulo: 'Introducción y presentación',
    //           ponente: 'Enrique Quiñones - Presidente de APOTUR'
    //         },
    //         {
    //           horario: '14:55 - 15:25',
    //           titulo: 'Turismo Regenerativo: Más allá de la sostenibilidad',
    //           ponente: 'Vanesa Ccahua Gutiérrez - Perú - Coodinadora Perú de la Iniciativa Global de Turismo Regenerativo'
    //         },
    //         {
    //           horario: '15:25 - 15:55',
    //           titulo: 'Economía circular y el turismo sostenible',
    //           ponente: 'Naara Campos - Especialista de la DAAT - MINCETUR'
    //         },
    //         {
    //           horario: '15:55 - 16:40',
    //           titulo: 'Panel: Experiencias y buenas prácticas de sostenibilidad en turismo',
    //           ponente: 'Lucía Castro Wayqecha - Estación biológica / Viktor Konstantin Amado Palomino Franco - Lomas de Paraíso / Eduardo de la Cadena Mori - Ulcumano Ecolodge'
    //         },
    //         {
    //           horario: '16:40 - 17:10',
    //           titulo: 'Coffee Break',
    //           ponente: '',
    //           break: true
    //         },
    //         {
    //           horario: '17:10 - 17:40',
    //           titulo: 'Promoción de destinos sostenibles:  una visión hacia nuevas oportunidades de desarrollo en la región',
    //           ponente: 'Pilar Lozano - Directora Perú y Bolivia /Agregaduría Comercial de la Embajada de Colombia PROCOLOMBIA'
    //         },
    //         {
    //           horario: '17:40 - 18:10',
    //           titulo: 'Destinos certificados en turismo sostenible en Latam. Experiencia reconocimiento y certificación Green Destinations Story Awards',
    //           ponente: 'Paola Cairampoma - Representante Green Destinations en Perú'
    //         },
    //         {
    //           horario: '18:10 -18:20',
    //           titulo: 'Cierre del bloque a cargo de MINCETUR',
    //           ponente: ''
    //         },
    //       ],

    //     },




    //     {
    //       nombre: 'Acto de Clausura del Evento',
    //       eventos: [
    //         {
    //           horario: '18:20 - 18:35',
    //           titulo: 'Conclusiones finales del evento',
    //           ponente: 'José Koechlin - Presidente CANATUR'
    //         },
    //         {
    //           horario: '18:35 - 18:45',
    //           titulo: 'Palabras de Clausura',
    //           ponente: 'Juan Carlos Mathews Ministro de Comercio Exterior y Turismo'
    //         },
    //         {
    //           horario: '18:45 - 18:55',
    //           titulo: 'Palabras de Clausura',
    //           ponente: 'Dina Boluarte - Presidente de la República'
    //         },
    //       ],

    //     },



    //   ]
    // },








    
  ];





  ejesTematicos = [

    {
      nombre: 'Desarrollo Turístico',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FejesTematicos%2FtextilesAlpaca.jpg?alt=media&token=9e1405be-b312-42e9-b440-067600b5292e'
    },
    {
      nombre: 'Innovación Turística',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FejesTematicos%2FhombreNinio.jpg?alt=media&token=29857336-6e5f-4b7a-80ba-97b53066e1f6'
    },
    {
      nombre: 'Emprendimiento',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FejesTematicos%2Fceviche.jpg?alt=media&token=a8c84d2f-0ca6-4ce6-b20a-677df532e296'
    },
    {
      nombre: 'Infraestructura Sostenible',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FejesTematicos%2FhombrePuente.jpg?alt=media&token=fb7cf4f2-b4f5-4204-9566-e8006459e46a'
    },
    {
      nombre: 'Calidad Turística',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FejesTematicos%2FtrenMachuPicchu.jpg?alt=media&token=b1943495-9758-43eb-852a-a290a3d382de'
    },
    {
      nombre: 'Gestión de Destinos',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FejesTematicos%2Ftotora.jpg?alt=media&token=cbf86589-bd33-4379-95a6-1fa250e6ce6f'
    },
    {
      nombre: 'Sostenibilidad',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FejesTematicos%2FfotoCusco.jpg?alt=media&token=43d0392a-d4f9-4d24-910f-41abe3a6820b'
    },

  ]








  auspiciadores = [

    {
      nombre: 'Marca 1',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 2',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 3',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 4',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 5',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 6',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 7',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 8',
      url: '/assets/images/imageFaker.jpg'
    },
    {
      nombre: 'Marca 9',
      url: '/assets/images/imageFaker.jpg'
    },

  ];

  redesSociales = [];

  marcasFooter = [

    {
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoMincetur.svg?alt=media&token=1ca823a8-8ce0-4a5c-8764-551e0d7b78f3',
      width: '200'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/logosCanatur%2FlogoCanaturColor.svg?alt=media&token=c208c4cb-7274-435c-9a28-23c45047ad9',
      width: '180'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoPunchePeru.svg?alt=media&token=0f1f6fee-4a2d-40de-be6f-d6cd5791740f',
      width: '140'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FlogoBicentenario.svg?alt=media&token=f4fd8f6e-7ab8-486b-9b0a-f21e5854f81d',
      width: '180'
    },

  ];


  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {

    this.redesSociales = this.ds.redesSociales;
  }

}
