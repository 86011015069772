<div class="container" *ngIf="formDirectivo">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/nosotros">Nosotros</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/nosotros/juntaDirectiva">Junta Directiva</a></li>
    </ol>
  </nav>

  <div class="d-flex align-items-center mb-3 gap-2">
    <button mat-icon-button routerLink="/admin/nosotros/juntaDirectiva">
      <i-tabler name="arrow-left" class="text-primary"></i-tabler>
    </button>
    <p class="h4 mb-0">Agregar directivo</p>
  </div>


  <div class="row justify-content-between">


    <div class="col-md-6">
      <form [formGroup]="formDirectivo" (submit)="onSubmit()" novalidate class="row">

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Nombre</mat-label>
          <input matInput type="text" formControlName="nombre">
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Cargo</mat-label>
          <input matInput type="text" formControlName="cargo">
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Foto</mat-label>
          <ngx-mat-file-input #removableInput formControlName="image" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
          <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
          <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
          <mat-hint>Recomendado: Imagen cuadrada</mat-hint>
          <mat-hint align="end">Máximo: 5 MB</mat-hint>
        </mat-form-field>

        <div class="d-grid mt-4 mt-md-5">
          <button class="btn btn-primary" style="width: 180px;">
            <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
              <mat-spinner class="" [diameter]="24"></mat-spinner>
            </span>
            <span *ngIf="!loading">Agregar directivo</span>
          </button>
        </div>
      </form>
    </div>



    <!-- <div class="col-md-5">
      <pre>{{formDirectivo.value | json}}</pre>
    </div> -->

    

  </div>

</div>