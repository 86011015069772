<div class="p-1 p-md-3">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <p class="h4 mt-2 mt-md-0 mb-3 mb-md-4">Crear categoría</p>


  <form [formGroup]="formCategoria" (submit)="onSubmit()" novalidate class="row">

    <mat-form-field appearance="fill" class="col-12">
      <mat-label>Nombre</mat-label>
      <input matInput class="text" formControlName="nombre">
    </mat-form-field>


    <div class="d-grid">
      <button class="btn btn-primary">
        <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
          <mat-spinner class="" [diameter]="24"></mat-spinner>
        </span>
        <span *ngIf="!loading">Crear categoría</span>
      </button>
    </div>
  </form>

</div>