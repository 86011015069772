import { Component, OnInit } from '@angular/core';
import { NoticiaHel } from '../../classes/noticia-hel';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-helvetas',
  templateUrl: './helvetas.component.html',
  styleUrls: ['./helvetas.component.scss']
})
export class HelvetasComponent implements OnInit {

  logosHelvetas = [
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FlogoSeCompetitivo.png?alt=media&token=aea864ce-0c3b-4dd2-ad66-d4a32b3722e5',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FlogoSeco.png?alt=media&token=fc0c3942-9b6f-4fda-b731-f59e4f7c1153',
    'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FlogoHelvetas.png?alt=media&token=4df5f7ea-3a92-4322-8fc1-e868f749222b'
  ];

  notasPrensa = [
    {
      titulo: 'San Martín es la 4ta región con más beneficiarios del programa Turismo Emprende',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotasPrensa%2FsanMartinCuartaRegion.pdf?alt=media&token=b553424c-4932-461e-9fe0-6cd79d6ba285',
    },
    {
      titulo: 'San Martín se ubica en el 3er lugar con mayores especies de aves del Perú durante el Global Big Day',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotasPrensa%2FsanMartinUbicaTercerLugar.pdf?alt=media&token=554c1cd7-6ce5-4bda-9ad7-18d6b0ed0070',
    },
    {
      titulo: 'Top 100 de Destinos Sostenibles: Alto Mayo y Tarapoto en carrera internacional',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotasPrensa%2Ftop100DestinosSostenibles.pdf?alt=media&token=cd01a18d-2cc5-4226-b2b1-70732b2fdc44',
    },
    {
      titulo: 'Cámara Nacional de Turismo continúa articulando esfuerzos con las regiones',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotasPrensa%2FconvenioCanaturDircetur.pdf?alt=media&token=c7451bad-05a5-4f2e-b672-836c34e28b10',
    },
    {
      titulo: 'Organizan primer "Viaje de Prensa Regional" a San Martín',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotasPrensa%2FviajePrensaRegionalSanMartin.pdf?alt=media&token=0eaa6550-dfb3-4b03-8a22-bdcedd259634',
    },
    {
      titulo: 'Periodistas e influencers inician promoción de destinos Tarapoto y Alto Mayo',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotasPrensa%2FinfluencerInicianPromocionTarapotoAltoMayo.pdf?alt=media&token=f5d7f863-6b31-4611-83d0-6a37a9856bd3',
    },
    {
      titulo: 'Parque Nacional Cordillera Azul: Convenio busca incentivar turismo de naturaleza',
      url: 'https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FnotasPrensa%2FparqueNacionalCordilleraAzul.pdf?alt=media&token=9f50306c-f682-46e8-8eed-ee567cb18762',
    },
  ];

  noticiasHel: NoticiaHel[] = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getNoticiasHel().subscribe( res => {
      this.noticiasHel = res;
    });
  }

}
